import React from 'react';
import { SetStateAction } from 'react';

import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { INavMenuItem, NavItem } from '@layout/layout-content/side-nav-bar-item';
import { ConfigStore } from '@stores/config-store';
import { UserStore } from '@stores/user-store';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { UitkList } from 'uitk-react-list';
import { UitkText } from 'uitk-react-text';

export interface ISideNavBarProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  config?: ConfigStore;
  user?: UserStore;
}

const SideNavBar: React.FC<ISideNavBarProps> = observer((props: ISideNavBarProps) => {
  const { isMobile } = useLayoutHelper();
  const { setNavOpen, navOpen } = props;
  const { user, config } = useStores();

  const copyrightYear = DateTime.now().year;

  function closeNavIfMobile() {
    if (isMobile) {
      setNavOpen(false);
    }
  }

  function getNavList(): Array<INavMenuItem> {
    const menu: Array<INavMenuItem> = [];

    if (user?.partnerType === 'FLIGHT_SPONSORED_LISTING') {
      menu.push({
        titleKey: 'Reports',
        href: '/reporting',
        relatedPages: new RegExp('null'),
        icon: '',
      });
    }
    if (['dev', 'test', 'lab', 'stage'].includes(config.appConfig?.deployedEnv)) {
      if (user?.partnerType === 'FLIGHT_SPONSORED_LISTING') {
        menu.push({
          titleKey: 'Accounts',
          href: '/select-account',
          relatedPages: new RegExp('null'),
          icon: '',
        });
        menu.push({
          titleKey: 'Billing Center',
          href: '/billing-center',
          relatedPages: new RegExp('null'),
          icon: '',
        });
        menu.push({
          titleKey: 'User Management',
          href: '/users',
          relatedPages: new RegExp('null'),
          icon: '',
        });
        menu.push({
          titleKey: 'Onboarding',
          href: '/onboarding',
          relatedPages: new RegExp('null'),
          icon: '',
        });
      }
      menu.push({
        titleKey: 'Campaigns',
        href: '/campaigns',
        relatedPages: new RegExp('null'),
        icon: user.partnerType === 'FLIGHT_SPONSORED_LISTING' ? '' : 'work',
      });
    }

    if (
      ['dev', 'test', 'lab', 'stage'].includes(config.appConfig?.deployedEnv) &&
      user?.partnerType === 'LODGING_SPONSORED_LISTING'
    ) {
      menu.push({
        titleKey: 'Reports',
        href: '/reporting',
        relatedPages: new RegExp('null'),
        icon: 'data_trends',
      });
      menu.push({
        titleKey: 'Invoices',
        href: '/billing-center',
        relatedPages: new RegExp('null'),
        icon: 'payments',
      });
      menu.push({
        titleKey: 'Help and Suppport',
        href: '',
        relatedPages: new RegExp('null'),
        icon: 'help',
      });
    }

    if (user?.partnerType === 'FLIGHT_SPONSORED_LISTING') {
      menu.push({
        titleKey: 'Logout',
        href: '/logout',
        relatedPages: new RegExp('null'),
        icon: '',
      });
    }

    return menu;
  }

  let sideNavBarClass = isMobile ? `side-nav-bar side-nav-bar-mobile` : `side-nav-bar`;
  if (navOpen) {
    sideNavBarClass += ' side-nav-open';
  }
  const navList = getNavList();

  return (
    <div data-testid="side-nav" className={sideNavBarClass}>
      <nav id="navigation-menu-items" className="navigation-menu-items">
        <UitkList bullet="no-bullet" data-control="accordion">
          {navList.map((item: INavMenuItem, index: number) => (
            <NavItem key={index} item={item} index={index} closeNavIfMobile={closeNavIfMobile} />
          ))}
        </UitkList>
        <footer id="side-nav-bar-footer" className="side-nav-bar-footer">
          <UitkText size={200} spacing="six">
            {/* <LocalizedText message="sideNavBar.copyrightText" data={[copyrightYear]} /> */}
            &#169; {copyrightYear}, Expedia, Inc. All rights reserved.
            <br />
            <br />
            <a href="https://advertising.expedia.com/privacy-statement/">
              {/* <LocalizedText message="sideNavBar.privacyStatementLinkText" /> */}
              Privacy Statement
            </a>{' '}
            &bull;{' '}
            <a href="https://advertising.expedia.com/cookie-statement/">
              {/* <LocalizedText message="sideNavBar.cookieStatementLinkText" /> */}
              Cookies Usage
            </a>
          </UitkText>
        </footer>
      </nav>
    </div>
  );
});

export { SideNavBar };
