import { gql } from '@apollo/client';

export const CAMPAIGN_MUTATION = gql`
  mutation ($productContext: ProductContext!, $campaignRequestInput: CampaignRequestInput!) {
    publishCampaign(productContext: $productContext, campaignRequestInput: $campaignRequestInput) {
      errorMessage
      successMessageHeading
      successMessageDescription
    }
  }
`;

export const UPDATE_STATUS_MUTATION = gql`
  mutation UpdateCampaignStatusMutation(
    $campaignId: String!
    $campaignIsPaused: Boolean
    $campaignName: String
    $adGroupId: String
    $adGroupIsPaused: Boolean
    $adGroupName: String
    $adId: String
    $adIsPaused: Boolean
    $adName: String
  ) {
    updateCampaignStatus(
      campaignId: $campaignId
      campaignIsPaused: $campaignIsPaused
      campaignName: $campaignName
      adGroupId: $adGroupId
      adGroupIsPaused: $adGroupIsPaused
      adGroupName: $adGroupName
      adId: $adId
      adIsPaused: $adIsPaused
      adName: $adName
    ) {
      ad {
        adId
        adType
        adName
        contentId
        isPaused
      }
      adGroup {
        adGroupId
        adGroupName
        budgetId
        campaignId
        isPaused
      }
      campaign {
        campaignId
        campaignName
        isPaused
      }
    }
  }
`;

export const APPLY_OPPORTUNITY_MUTATION = gql`
  mutation ApplyOpportunityMutation($applyOpportunityReq: ApplyOpportunityInput!) {
    applyOpportunity(applyOpportunityReq: $applyOpportunityReq) {
      successResponse {
        icon {
          token
          description
          id
        }
        campaignDisplayName
        heading
        description
        acknowledgeButton {
          primary
        }
      }
      errorResponse {
        errorBanner {
          heading
          message
          theme
          icon {
            token
            description
            id
          }
        }
      }
    }
  }
`;

export const DISMISS_OPPORTUNITY_MUTATION = gql`
  mutation DismissOpportunityMutation($dismissOpportunityReq: DismissOpportunityInput!) {
    dismissOpportunity(dismissOpportunityReq: $dismissOpportunityReq) {
      successMessage
      errorMessage
    }
  }
`;
