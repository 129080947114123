import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AnalyticsPayload: { input: any; output: any; }
  Locale: { input: any; output: any; }
  URL: { input: any; output: any; }
  UUID: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
}

/** Ad Copy Input */
export interface AdCopyInput {
  /** Ad content for a Flight ad */
  fslContent?: InputMaybe<FlightCreativeInput>;
  /** Ad content has been updated */
  isUpdated: Scalars['Boolean']['input'];
  /** Ad content languages */
  languages: Array<Scalars['String']['input']>;
  /** Ad content for a Lodging ad */
  lslContent?: InputMaybe<LodgingCreativeInput>;
}

/** Ad Response */
export interface AdCreationResponse {
  __typename: 'AdCreationResponse';
  /** Ad Id */
  adId?: Maybe<Scalars['UUID']['output']>;
  /** Ad Name */
  adName?: Maybe<Scalars['String']['output']>;
  /** Ad type */
  adType?: Maybe<AdType>;
  /** Content Id */
  contentId?: Maybe<Scalars['UUID']['output']>;
  /** Ad status */
  isPaused?: Maybe<Scalars['Boolean']['output']>;
}

/** Ad Group Response */
export interface AdGroupCreationResponse {
  __typename: 'AdGroupCreationResponse';
  /** Ad Group Id */
  adGroupId?: Maybe<Scalars['UUID']['output']>;
  /** Ad Group Name */
  adGroupName?: Maybe<Scalars['String']['output']>;
  /** Budget Id */
  budgetId?: Maybe<Scalars['UUID']['output']>;
  /** Campaign Id */
  campaignId?: Maybe<Scalars['UUID']['output']>;
  /** Ad Group status */
  isPaused?: Maybe<Scalars['Boolean']['output']>;
  /** Pricing */
  pricing?: Maybe<PricingResponse>;
}

/** Campaign wizard ad group input */
export interface AdGroupInput {
  /** Ad group ads */
  ads: Array<AdInput>;
  hydrated: Scalars['Boolean']['input'];
  /** Ad group id.  Includes temp if it is a new ad group */
  id: Scalars['String']['input'];
  /** Ad group paused */
  isPaused: Scalars['Boolean']['input'];
  /** Ad group locations to target */
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Ad group name */
  name: Scalars['String']['input'];
}

/** Ad group location targeting card */
export interface AdGroupLocationCard {
  __typename: 'AdGroupLocationCard';
  /** Ad Group Creation Location Targeting Expando List */
  locationExpandos: Array<LocationExpando>;
  /** Validation Message */
  validationMessage?: Maybe<UiBanner>;
}

/** CAP Ad group option info */
export interface AdGroupOption {
  __typename: 'AdGroupOption';
  /** Ad group id */
  adGroupId: Scalars['String']['output'];
  /** Ad group name */
  adGroupName: Scalars['String']['output'];
  /** Campaign id */
  campaignId: Scalars['String']['output'];
  /** Ad group paused status */
  isPaused: Scalars['Boolean']['output'];
}

/** AdGroup overview table action cell */
export interface AdGroupOverviewStatusCell {
  __typename: 'AdGroupOverviewStatusCell';
  /** ad group id */
  adGroupId?: Maybe<Scalars['String']['output']>;
  /** ad group name */
  adGroupName: Scalars['String']['output'];
  /** campaign id */
  campaignId: Scalars['String']['output'];
  /** status cell */
  switch: EgdsStandardSwitch;
}

/** Ad Group Request Inputs */
export interface AdGroupRequestInput {
  /** Ad Group Id */
  adGroupId?: InputMaybe<Scalars['UUID']['input']>;
  /** Ad Group Name */
  adGroupName: Scalars['String']['input'];
  /** Ad Group Targeting */
  adGroupTargeting: AdGroupTargetingInput;
  /** Campaign Ad list */
  adList: Array<AdRequestInput>;
  /** Ad Group status */
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  /** Test */
  isUpdated: Scalars['Boolean']['input'];
}

/** Ad group review card */
export interface AdGroupReviewCard {
  __typename: 'AdGroupReviewCard';
  /** Review card ad review content */
  ads: Array<AdReviewContent>;
  /** Review Card edit button */
  editButton: UiSecondaryButton;
  /** Review card editing item id */
  editItemId: Scalars['String']['output'];
  /** Review card line items */
  items: Array<ReviewCardLineItem>;
}

/** Ad Group Targeting Inputs */
export interface AdGroupTargetingInput {
  /** Targeting locations */
  locationList: Array<TargetingInput>;
  /** Locations updated */
  locationsUpdated: Scalars['Boolean']['input'];
  /** Targeting property id */
  productId: TargetingInput;
}

/** Campaign wizard ad input */
export interface AdInput {
  /** Content image can be overridden */
  canOverrideImage: Scalars['Boolean']['input'];
  /** Ad content */
  content: ContentInput;
  hydrated: Scalars['Boolean']['input'];
  /** Ad id.  Includes temp if it is a new ad */
  id: Scalars['String']['input'];
  /** Ad paused */
  isPaused: Scalars['Boolean']['input'];
  /** Ad name */
  name: Scalars['String']['input'];
}

/** Ad overview table action cell */
export interface AdOverviewStatusCell {
  __typename: 'AdOverviewStatusCell';
  /** ad group id */
  adGroupId?: Maybe<Scalars['String']['output']>;
  /** ad id */
  adId?: Maybe<Scalars['String']['output']>;
  /** ad name */
  adName: Scalars['String']['output'];
  /** campaign id */
  campaignId: Scalars['String']['output'];
  /** status cell */
  switch: EgdsStandardSwitch;
}

/** Preview of ad including screen selections and creative content */
export interface AdPreview {
  __typename: 'AdPreview';
  adPreviewContent?: Maybe<AdPreviewContent>;
  /** Radio button group for desktop and mobile view */
  radioButtonGroup: EgdsBasicRadioGroup;
}

/** Ad Request Input */
export interface AdRequestInput {
  /** Ad creative */
  adCopy: AdCopyInput;
  /** Ad Id */
  adId?: InputMaybe<Scalars['UUID']['input']>;
  /** Ad Name */
  adName: Scalars['String']['input'];
  /** Ad status */
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ad has been updated */
  isUpdated: Scalars['Boolean']['input'];
}

/** Ad review content */
export interface AdReviewContent {
  __typename: 'AdReviewContent';
  /** Ad preview */
  adPreview?: Maybe<AdPreview>;
  /** Review Card edit button */
  editButton: UiSecondaryButton;
  /** Review card editing item id */
  editItemId: Scalars['String']['output'];
  /** Review card line items */
  items: Array<ReviewCardLineItem>;
  /** Ad review card title */
  title: Scalars['String']['output'];
}

export enum AdType {
  FLIGHT_SPONSORED_LISTING = 'FLIGHT_SPONSORED_LISTING',
  LODGING_BRAND_RESULT_LISTING = 'LODGING_BRAND_RESULT_LISTING',
  LODGING_SPONSORED_LISTING = 'LODGING_SPONSORED_LISTING',
  UNKNOWN = 'UNKNOWN'
}

/** The popover used to display additional information */
export interface AdditionalInformationPopover {
  __typename: 'AdditionalInformationPopover';
  analytics?: Maybe<ClientSideAnalytics>;
  closeLabel?: Maybe<Scalars['String']['output']>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `enrichedSecondaries` instead. */
  secondaries: Array<Scalars['String']['output']>;
}

/** Popover section for a dialog */
export interface AdditionalInformationPopoverDialogSection extends EgdsDialog {
  __typename: 'AdditionalInformationPopoverDialogSection';
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer?: Maybe<EgdsDialogFooter>;
  subSections: Array<PricePresentationSubSection>;
  title?: Maybe<Scalars['String']['output']>;
}

/** Popover section for complex grid type of content */
export interface AdditionalInformationPopoverGridSection {
  __typename: 'AdditionalInformationPopoverGridSection';
  subSections: Array<PricePresentationSubSection>;
}

/** Popover section for lists of content */
export interface AdditionalInformationPopoverListSection {
  __typename: 'AdditionalInformationPopoverListSection';
  content: EgdsList;
}

/** Container interface for wrapping different UI element types for popovers */
export type AdditionalInformationPopoverSection = AdditionalInformationPopoverDialogSection | AdditionalInformationPopoverGridSection | AdditionalInformationPopoverListSection | AdditionalInformationPopoverTextSection;

/** Popover section for simple text content */
export interface AdditionalInformationPopoverTextSection {
  __typename: 'AdditionalInformationPopoverTextSection';
  text: EgdsText;
}

/** Ad group typeahead */
export interface AdsPortalAdGroupFocusTypeahead extends CampaignOverviewFocusTypeahead {
  __typename: 'AdsPortalAdGroupFocusTypeahead';
  /** Ad group data to populate typeahead */
  adGroupOptions?: Maybe<Array<AdGroupOption>>;
  /** Campaign data to populate typeahead */
  campaignOptions?: Maybe<Array<CampaignOption>>;
  /** Typeahead heading */
  heading: Scalars['String']['output'];
  /** Typeahead render type */
  renderType: EgdsTypeaheadRenderType;
  /** Typeahead with input field */
  searchTypeahead: EgdsTypeaheadInputField;
  /** Typeahead subheading */
  subheading: Scalars['String']['output'];
  /** Typeahead trigger menu */
  triggerMenu: TypeaheadTriggerMenu;
  /** options for typeahead select */
  typeaheadSelect?: Maybe<EgdsBasicSelect>;
}

/** Ad group overview table */
export interface AdsPortalAdGroupTableResponse extends CampaignOverviewTableResponse {
  __typename: 'AdsPortalAdGroupTableResponse';
  /** Campaign overview table header */
  columns: Array<OverviewTableColumn>;
  /** Create label */
  createLabel: Scalars['String']['output'];
  /** Edit label */
  editLabel: Scalars['String']['output'];
  /** Campaign overview heading */
  heading?: Maybe<Scalars['String']['output']>;
  /** Last sorting parameters */
  lastSort: CampaignOverviewTableSorting;
  /** Icon for no table data */
  noRowsIcon: Icon;
  /** Placeholder for no table data */
  noRowsPlaceholder: Scalars['String']['output'];
  /** Campaign overview table totals row */
  rows: Array<CampaignOverviewDataRows>;
  /** Campaign overview table striped property */
  striped: Scalars['String']['output'];
  /** Campaign overview table title */
  title: Scalars['String']['output'];
}

/** Ad overview table */
export interface AdsPortalAdTableResponse extends CampaignOverviewTableResponse {
  __typename: 'AdsPortalAdTableResponse';
  /** Campaign overview table header */
  columns: Array<OverviewTableColumn>;
  /** Create label */
  createLabel: Scalars['String']['output'];
  /** Edit label */
  editLabel: Scalars['String']['output'];
  /** Campaign overview heading */
  heading?: Maybe<Scalars['String']['output']>;
  /** Last sorting parameters */
  lastSort: CampaignOverviewTableSorting;
  /** Icon for no table data */
  noRowsIcon: Icon;
  /** Placeholder for no table data */
  noRowsPlaceholder: Scalars['String']['output'];
  /** Campaign overview table totals row */
  rows: Array<CampaignOverviewDataRows>;
  /** Campaign overview table striped property */
  striped: Scalars['String']['output'];
  /** Campaign overview table title */
  title: Scalars['String']['output'];
}

/** Campaign typeahead */
export interface AdsPortalCampaignFocusTypeahead extends CampaignOverviewFocusTypeahead {
  __typename: 'AdsPortalCampaignFocusTypeahead';
  /** Ad group data to populate typeahead */
  adGroupOptions?: Maybe<Array<AdGroupOption>>;
  /** Campaign data to populate typeahead */
  campaignOptions?: Maybe<Array<CampaignOption>>;
  /** Typeahead heading */
  heading: Scalars['String']['output'];
  /** Typeahead render type */
  renderType: EgdsTypeaheadRenderType;
  /** Typeahead with input field */
  searchTypeahead: EgdsTypeaheadInputField;
  /** Typeahead subheading */
  subheading: Scalars['String']['output'];
  /** Typeahead trigger menu */
  triggerMenu: TypeaheadTriggerMenu;
  /** options for typeahead select */
  typeaheadSelect?: Maybe<EgdsBasicSelect>;
}

/** Campaign overview table */
export interface AdsPortalCampaignTableResponse extends CampaignOverviewTableResponse {
  __typename: 'AdsPortalCampaignTableResponse';
  /** Campaign overview table header */
  columns: Array<OverviewTableColumn>;
  /** Create label */
  createLabel: Scalars['String']['output'];
  /** Edit label */
  editLabel: Scalars['String']['output'];
  /** Dialog to extend campaign */
  extendCampaignDialog?: Maybe<ExtendCampaignDialog>;
  /** Campaign overview heading */
  heading?: Maybe<Scalars['String']['output']>;
  /** Last sorting parameters */
  lastSort: CampaignOverviewTableSorting;
  /** Icon for no table data */
  noRowsIcon: Icon;
  /** Placeholder for no table data */
  noRowsPlaceholder: Scalars['String']['output'];
  /** Campaign overview table totals row */
  rows: Array<CampaignOverviewDataRows>;
  /** Campaign overview table striped property */
  striped: Scalars['String']['output'];
  /** Campaign overview subtitle */
  subtitle: Scalars['String']['output'];
  /** Campaign overview table title */
  title: Scalars['String']['output'];
}

export interface AdvertiserMultiSelectOptions {
  __typename: 'AdvertiserMultiSelectOptions';
  /** Section options */
  options?: Maybe<Array<ReportSelectionOptions>>;
  /** Section name multi select options */
  section?: Maybe<Scalars['String']['output']>;
}

/** Opportunities section */
export interface AdvertiserOpportunities {
  __typename: 'AdvertiserOpportunities';
  /** Opportunities list */
  opportunities: Array<AdvertiserOpportunity>;
}

/** Opportunity */
export interface AdvertiserOpportunity {
  __typename: 'AdvertiserOpportunity';
  opportunityType?: Maybe<Opportunity>;
}

export interface AdvertiserPortalOnboarding {
  __typename: 'AdvertiserPortalOnboarding';
  /** Onboarding Side Bar */
  onboardingSideBar: AdvertiserPortalOnboardingSideBar;
  /** Ads Portal Onboarding Step */
  onboardingStep: AdvertiserPortalOnboardingStep;
}


export interface AdvertiserPortalOnboardingOnboardingStepArgs {
  onboardStep: OnboardStep;
  onboardingInput: OnboardingInput;
  productContext: ProductContext;
}

/** Onboarding Wizard Sidebar */
export interface AdvertiserPortalOnboardingSideBar {
  __typename: 'AdvertiserPortalOnboardingSideBar';
  /** List of onboarding steps */
  onboardingSections: Array<OnboardingSection>;
}

/** Onboarding Form Page */
export interface AdvertiserPortalOnboardingStep {
  __typename: 'AdvertiserPortalOnboardingStep';
  /** Onboarding back button */
  backButton?: Maybe<UiSecondaryButton>;
  /** Onboarding continue button */
  continueButton: UiPrimaryButton;
  /** Onboarding Page content */
  pageContent: Array<OnboardingCard>;
  /** Onboarding Page heading */
  pageHeading?: Maybe<Scalars['String']['output']>;
  /** Onboarding Page text */
  pageText?: Maybe<Scalars['String']['output']>;
}

export interface AdvertiserPortalProduct {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

/** Product Selection Card */
export interface AdvertiserPortalProductSelectionCard {
  __typename: 'AdvertiserPortalProductSelectionCard';
  /** Add products subtitle */
  addProductsSubtitle?: Maybe<Scalars['String']['output']>;
  /** Associated products subtitle */
  associatedProductsSubtitle?: Maybe<Scalars['String']['output']>;
  content?: Maybe<AdvertiserPortalProductSelectionContent>;
  /** Description of the card */
  description?: Maybe<Scalars['String']['output']>;
  /** List of the associated products */
  products?: Maybe<Array<AdvertiserPortalProduct>>;
  /** Title of the card */
  title?: Maybe<Scalars['String']['output']>;
}

export type AdvertiserPortalProductSelectionContent = AdvertiserPortalPropertySelectionCard;

/** Property data */
export interface AdvertiserPortalProperty extends AdvertiserPortalProduct {
  __typename: 'AdvertiserPortalProperty';
  /** Property id */
  id: Scalars['String']['output'];
  /** Property name */
  name: Scalars['String']['output'];
}

/** Property Selection Card */
export interface AdvertiserPortalPropertySelectionCard {
  __typename: 'AdvertiserPortalPropertySelectionCard';
  /** Add Properties TypeAhead */
  addPropertiesTypeahead?: Maybe<EgdsTypeaheadInputField>;
  /** Delete Icon */
  deleteIcon?: Maybe<Icon>;
  /** Property Locale */
  locale?: Maybe<Scalars['String']['output']>;
  /** Property Icon */
  propertyIcon?: Maybe<Icon>;
}

/** Shared UI Card Content */
export interface AdvertiserPortalSharedUiCard {
  __typename: 'AdvertiserPortalSharedUICard';
  /** Shared UI Card Mode */
  mode?: Maybe<SharedUiCardMode>;
  /** Editing object resource id */
  selectedResourceId?: Maybe<Scalars['String']['output']>;
  /** Shared UI Card Type */
  type?: Maybe<SharedUiCardType>;
}

/** Advertiser reporting table badge cell */
export interface AdvertiserReportingBadgeCell {
  __typename: 'AdvertiserReportingBadgeCell';
  /** badge cell */
  badge?: Maybe<EgdsStandardBadge>;
}

/** Different cell types for table */
export type AdvertiserReportingCellInfo = AdvertiserReportingBadgeCell | AdvertiserReportingNameCell | AdvertiserReportingNumberCell | AdvertiserReportingTextCell;

export enum AdvertiserReportingContext {
  FLIGHTS = 'FLIGHTS',
  LODGING_SPONSORED_LISTINGS = 'LODGING_SPONSORED_LISTINGS'
}

/** Advertiser Reporting Criteria Selection Form */
export interface AdvertiserReportingForm {
  __typename: 'AdvertiserReportingForm';
  /** Currency input */
  currencyInputField?: Maybe<EgdsInputField>;
  /** Download button */
  downloadButton: DownloadButtonWithDialog;
  /** Reporting filters */
  filterOptions: ReportingOptionFilters;
  /** Expando for filters applied */
  filtersAppliedToggle: EgdsExpandoPeek;
  /** Reporting form header */
  header: AdvertiserReportingFormHeader;
  /** Loading text */
  loadingText?: Maybe<EgdsStylizedText>;
  /** Refresh button */
  refreshButton: UiPrimaryButton;
}

/** Reporting Form Header */
export interface AdvertiserReportingFormHeader {
  __typename: 'AdvertiserReportingFormHeader';
  /** Primary section title */
  primaryText: Scalars['String']['output'];
  /** Secondary section subtitle */
  secondaryText?: Maybe<Scalars['String']['output']>;
}

export enum AdvertiserReportingGroupBy {
  AD = 'AD',
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN = 'CAMPAIGN',
  COUNTRY_CODE = 'COUNTRY_CODE',
  PERIOD = 'PERIOD'
}

export enum AdvertiserReportingLevel {
  AD = 'AD',
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN = 'CAMPAIGN',
  CAMPAIGN_AND_AD_GROUP = 'CAMPAIGN_AND_AD_GROUP'
}

/** Advertiser reporting table text cell */
export interface AdvertiserReportingNameCell {
  __typename: 'AdvertiserReportingNameCell';
  /** cell alignment */
  align?: Maybe<Scalars['String']['output']>;
  /** type of border for cell */
  border?: Maybe<Scalars['String']['output']>;
  /** cell column span */
  colSpan?: Maybe<Scalars['Int']['output']>;
  /** icon for cell */
  icon?: Maybe<Icon>;
  /** padding for cell */
  padding?: Maybe<Scalars['String']['output']>;
  /** cell striped property */
  striped?: Maybe<Scalars['Boolean']['output']>;
  /** text cell */
  text?: Maybe<EgdsStylizedText>;
}

/** Advertiser reporting table number cell */
export interface AdvertiserReportingNumberCell {
  __typename: 'AdvertiserReportingNumberCell';
  /** amount int cell */
  amount?: Maybe<Scalars['Int']['output']>;
  /** amount in double cell */
  amountDouble?: Maybe<Scalars['Float']['output']>;
  /** formatted amount */
  formattedAmount?: Maybe<Scalars['String']['output']>;
  /** weight of text in cell */
  weight?: Maybe<TextWeight>;
}

export interface AdvertiserReportingOptionsInput {
  adGroupIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  adIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  campaignIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  countryCodePOS?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate: DateInput;
  groupBy?: InputMaybe<Array<AdvertiserReportingGroupBy>>;
  period?: InputMaybe<Scalars['String']['input']>;
  reportLevel?: InputMaybe<AdvertiserReportingLevel>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  startDate: DateInput;
}

/** Advertiser reporting table cell content */
export interface AdvertiserReportingTableCellContent {
  __typename: 'AdvertiserReportingTableCellContent';
  /** Color of cell background */
  backgroundColor?: Maybe<Scalars['String']['output']>;
  content?: Maybe<AdvertiserReportingCellInfo>;
}

export interface AdvertiserReportingTableCurrency {
  __typename: 'AdvertiserReportingTableCurrency';
  /** Reporting table currency code */
  currencyCode: Scalars['String']['output'];
  /** Reporting table currency icon */
  icon: Scalars['String']['output'];
  /** Reporting table currency text */
  primaryText: Scalars['String']['output'];
}

/** Advertiser reporting table header */
export interface AdvertiserReportingTableHeaders {
  __typename: 'AdvertiserReportingTableHeaders';
  /** type of border for cell */
  border?: Maybe<Scalars['String']['output']>;
  /** Sorting icon */
  sortIcon?: Maybe<Icon>;
  /** Whether column is sortable */
  sortable?: Maybe<Scalars['Boolean']['output']>;
  /** Table column header title */
  text?: Maybe<EgdsStylizedText>;
  /** Width of header column */
  width?: Maybe<Scalars['String']['output']>;
}

export interface AdvertiserReportingTableNoResults {
  __typename: 'AdvertiserReportingTableNoResults';
  /** No results icon */
  noResultsIcon?: Maybe<Icon>;
  /** No results header */
  primaryText?: Maybe<EgdsStylizedText>;
  /** No results secondary text */
  secondaryText?: Maybe<EgdsStylizedText>;
}

/** Advertiser reporting table */
export interface AdvertiserReportingTableResponse {
  __typename: 'AdvertiserReportingTableResponse';
  /** Reporting table campaign options */
  adGroupMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  /** Reporting table campaign options */
  adMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  /** Reporting table campaign region map */
  campaignInfoMap?: Maybe<Array<AdvertiserTableMapping>>;
  /** Reporting table campaign options */
  campaignMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  /** Reporting table campaign options */
  campaignOptions?: Maybe<Array<ReportSelectionOptions>>;
  /** Reporting table currency section */
  currency: AdvertiserReportingTableCurrency;
  /** Reporting table header */
  headers: Array<AdvertiserReportingTableHeaders>;
  /** Last sorting parameters */
  lastSort: AdvertiserReportingTableSorting;
  /** No results text */
  noResultsSection?: Maybe<AdvertiserReportingTableNoResults>;
  /** Reporting table region options */
  regionMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  /** Reporting table region options */
  regionOptions?: Maybe<Array<ReportSelectionOptions>>;
  /** Reporting table data */
  reportingData?: Maybe<Array<CampaignReportingData>>;
  /** Reporting table striped property */
  striped: Scalars['String']['output'];
  /** Reporting table totals row */
  totalsRow?: Maybe<Array<AdvertiserReportingTotalCell>>;
}

export interface AdvertiserReportingTableSorting {
  __typename: 'AdvertiserReportingTableSorting';
  /** last sort by parameter used */
  sortBy?: Maybe<Scalars['String']['output']>;
  /** last sort direction parameter used */
  sortDirection?: Maybe<Scalars['String']['output']>;
}

/** Advertiser reporting table text cell */
export interface AdvertiserReportingTextCell {
  __typename: 'AdvertiserReportingTextCell';
  /** text cell */
  text?: Maybe<EgdsStylizedText>;
}

export interface AdvertiserReportingTotalCell {
  __typename: 'AdvertiserReportingTotalCell';
  /** Color of cell background */
  backgroundColor?: Maybe<Scalars['String']['output']>;
  content?: Maybe<AdvertiserReportingCellInfo>;
}

export interface AdvertiserTableMapping {
  __typename: 'AdvertiserTableMapping';
  /** Campaign end date */
  endDate?: Maybe<Scalars['String']['output']>;
  /** Campaign id */
  id?: Maybe<Scalars['String']['output']>;
  /** Campaign region list */
  list?: Maybe<Array<Scalars['String']['output']>>;
  /** Campaign start date */
  startDate?: Maybe<Scalars['String']['output']>;
}

/** Specifies the behavior of ALTER, which is used to run Abacus experiments on localization messages. */
export enum AlterMode {
  /** Localization messages will be replaced with their namespace and loc key. */
  DEBUG = 'DEBUG',
  /** None of the ALTER experiments will be used when resolving localization messages. */
  NONE = 'NONE',
  /** Both released and draft ALTER experiments will be used when resolving localization messages. */
  PREVIEW = 'PREVIEW',
  /** This is the default. Only released ALTER experiments will be used when resolving localization messages. */
  RELEASED = 'RELEASED'
}

/** Apply Opportunity Error Response */
export interface ApplyOpportunityErrorResponse {
  __typename: 'ApplyOpportunityErrorResponse';
  /** Apply Opportunity error banner */
  errorBanner: UiBanner;
}

/** Input for applyOpportunity mutation */
export interface ApplyOpportunityInput {
  /** Operations for apply opportunities */
  operations: Array<ApplyOpportunityOperationInput>;
}

/** Operation for applyOpportunity mutation */
export interface ApplyOpportunityOperationInput {
  /** Campaign id */
  campaignId: Scalars['String']['input'];
  /** Campaign name */
  campaignName: Scalars['String']['input'];
  /** Recommendation id */
  recommendationId: Scalars['String']['input'];
  /** Updated bidding strategy */
  updatedBiddingStrategy?: InputMaybe<Scalars['String']['input']>;
  /** Updated budget */
  updatedBudget?: InputMaybe<Scalars['Float']['input']>;
}

/** Apply Opportunity Response */
export interface ApplyOpportunityResponse {
  __typename: 'ApplyOpportunityResponse';
  /** Error message */
  errorResponse?: Maybe<ApplyOpportunityErrorResponse>;
  /** Success message */
  successResponse?: Maybe<ApplyOpportunitySuccessResponse>;
}

/** Apply Opportunity Success Response */
export interface ApplyOpportunitySuccessResponse {
  __typename: 'ApplyOpportunitySuccessResponse';
  /** Apply Opportunity success card acknowledge button */
  acknowledgeButton: UiSecondaryButton;
  /** Apply Opportunity success card campaign display name */
  campaignDisplayName: Scalars['String']['output'];
  /** Apply Opportunity success card description */
  description: Scalars['String']['output'];
  /** Apply Opportunity success card heading */
  heading: Scalars['String']['output'];
  /** Apply Opportunity success icon */
  icon: Icon;
}

/** Possible aspect ratios of EGDS images */
export enum AspectRatio {
  /** Aspect ratio 4:1 */
  FOUR_ONE = 'FOUR_ONE',
  /** Aspect ratio 4:3 */
  FOUR_THREE = 'FOUR_THREE',
  /** Aspect ratio 1:1 */
  ONE_ONE = 'ONE_ONE',
  /** Aspect ratio 16:9 */
  SIXTEEN_NINE = 'SIXTEEN_NINE',
  /** Aspect ratio 3:4 */
  THREE_FOUR = 'THREE_FOUR',
  /** Aspect ratio 3:2 */
  THREE_TWO = 'THREE_TWO',
  /** Aspect ratio 21:9 */
  TWENTY_ONE_NINE = 'TWENTY_ONE_NINE'
}

export enum AuthenticationState {
  ANONYMOUS = 'ANONYMOUS',
  AUTHENTICATED = 'AUTHENTICATED',
  IDENTIFIED = 'IDENTIFIED'
}

export enum BannerTag {
  AD = 'AD',
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN = 'CAMPAIGN'
}

export enum BannerType {
  ERROR = 'ERROR',
  INFO = 'INFO'
}

/** Bid Amount Response */
export interface BidAmountResponse {
  __typename: 'BidAmountResponse';
  /** Amount */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Currency code */
  currencyCode?: Maybe<Scalars['String']['output']>;
  /** Decimal places */
  decimalPlaces?: Maybe<Scalars['Int']['output']>;
}

/** Bidding Strategy */
export interface BiddingStrategy {
  __typename: 'BiddingStrategy';
  /** bidding strategy goal */
  goal?: Maybe<Scalars['String']['output']>;
  /** bidding strategy label */
  label: Scalars['String']['output'];
}

/** Type used during a product search to specify a given boolean value like the fact customer would prefer to search for direct flights only. */
export interface BooleanValue {
  __typename: 'BooleanValue';
  id: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

/** Budget */
export interface Budget {
  __typename: 'Budget';
  /** budget formatted value */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** budget label */
  label?: Maybe<Scalars['String']['output']>;
  /** budget value */
  value?: Maybe<Scalars['Float']['output']>;
}

/** Budget Impact Metric */
export interface BudgetImpactMetric {
  __typename: 'BudgetImpactMetric';
  /** Budget impact metric label */
  label: Scalars['String']['output'];
  /** Budget impact metric amount */
  value: Scalars['String']['output'];
}

/** Budget Opportunity */
export interface BudgetOpportunity {
  __typename: 'BudgetOpportunity';
  /** Budget opportunity campaign name */
  campaignDisplayName: Scalars['String']['output'];
  /** Budget opportunity description */
  description: Scalars['String']['output'];
  /** Budget opportunity dialog */
  dialog: BudgetOpportunityDialog;
  /** Budget opportunity dismissal button */
  dismissalButton: UiTertiaryButton;
  /** Opportunity dismissal dialog */
  dismissalDialog: OpportunityDismissalDialog;
  /** Budget opportunity heading */
  heading: Scalars['String']['output'];
  /** Budget opportunity icon */
  icon: Icon;
  /** Budget opportunity dialog button */
  openDialogButton: UiSecondaryButton;
}

/** Budget opportunity dialog */
export interface BudgetOpportunityDialog {
  __typename: 'BudgetOpportunityDialog';
  /** Budget opportunity dialog content body texts */
  bodyTexts: Array<Scalars['String']['output']>;
  /** Budget opportunity dialog toolbar title */
  campaignBudgetRecommendationCards: Array<CampaignBudgetRecommendationCard>;
  /** Budget opportunity dialog cancel button */
  cancelButton: UiSecondaryButton;
  /** Budget opportunity dialog content heading */
  heading: Scalars['String']['output'];
  /** Budget opportunity dialog submit button */
  submitButton: UiPrimaryButton;
  /** Budget opportunity dialog toolbar title */
  toolbarTitle: Scalars['String']['output'];
}

export interface Campaign {
  __typename: 'Campaign';
  campaignId: Scalars['UUID']['output'];
  goal?: Maybe<Scalars['String']['output']>;
  isPaused: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

/** Campaign Creation Budget Card Content */
export interface CampaignBudgetCard {
  __typename: 'CampaignBudgetCard';
  /** text input badge */
  inputBadge?: Maybe<EgdsStandardBadge>;
  /** suggestion text */
  suggestionText?: Maybe<Scalars['String']['output']>;
  /** text input box */
  textInputBox: EgdsInputField;
}

/** Campaign Budget Recommendation Card */
export interface CampaignBudgetRecommendationCard {
  __typename: 'CampaignBudgetRecommendationCard';
  /** Campaign budget recommendation card input validation messages */
  budgetInputValidationMessages: Array<Scalars['String']['output']>;
  /** Campaign budget recommendation card campaign id */
  campaignId: Scalars['String']['output'];
  /** Campaign budget recommendation card campaign name */
  campaignName?: Maybe<Scalars['String']['output']>;
  /** Campaign budget recommendation card selected checkmark */
  checkBox?: Maybe<EgdsBasicCheckBox>;
  /** Campaign budget recommendation card current budget */
  currentBudget: Budget;
  /** Campaign budget recommendation card description */
  description: Scalars['String']['output'];
  /** Campaign budget recommendation card impact metric */
  impactMetric: BudgetImpactMetric;
  /** Campaign budget recommendation card input */
  input: EgdsInputField;
  /** Campaign budget recommendation card input validation pattern */
  inputValidationPattern: Scalars['String']['output'];
  /** Campaign budget recommendation card recommendation id */
  recommendationId: Scalars['String']['output'];
  /** Campaign budget recommendation card recommended budget */
  recommendedBudget: Budget;
  /** Campaign budget recommendation card title */
  title: Scalars['String']['output'];
}

/** Different card types for campaign wizard page */
export type CampaignCardContent = AdGroupLocationCard | AdGroupReviewCard | CampaignBudgetCard | CampaignGoalCard | CampaignProductCard | CampaignReviewCard | CampaignScheduleCard | CreativeCard | NameCard;

/** Different cell types for table */
export type CampaignCellInfo = AdGroupOverviewStatusCell | AdOverviewStatusCell | CampaignOverviewActionCell | CampaignOverviewDeliveryStatusCell | CampaignOverviewLocationTargetingCell | CampaignOverviewNumberCell | CampaignOverviewStatusCell | CampaignOverviewTextCell;

/** Campaign Response */
export interface CampaignCreationResponse {
  __typename: 'CampaignCreationResponse';
  /** Campaign Id */
  campaignId?: Maybe<Scalars['UUID']['output']>;
  /** Campaign name */
  campaignName?: Maybe<Scalars['String']['output']>;
  /** Campaign status */
  isPaused?: Maybe<Scalars['Boolean']['output']>;
}

/** Campaign Goal Card Content */
export interface CampaignGoalCard {
  __typename: 'CampaignGoalCard';
  /** Goal Options List */
  goalOptionList?: Maybe<Array<CampaignGoalType>>;
  /** Market goal types */
  marketGoal: MarketGoalCard;
}

/** Campaign Goal Card Toggle */
export interface CampaignGoalType {
  __typename: 'CampaignGoalType';
  /** goal badge */
  goalBadge?: Maybe<EgdsStandardBadge>;
  /** goal disabled */
  goalDisabled: Scalars['Boolean']['output'];
  /** goal icon */
  goalIcon?: Maybe<Icon>;
  /** goal id */
  goalId?: Maybe<Scalars['String']['output']>;
  /** goal label text */
  goalLabelText?: Maybe<Scalars['String']['output']>;
  /** goal state */
  goalState?: Maybe<Scalars['Boolean']['output']>;
  /** goal sub label text */
  goalSubLabelText?: Maybe<Scalars['String']['output']>;
}

/** Campaign wizard campaign input */
export interface CampaignInput {
  /** Campaign ad groups */
  adGroups: Array<AdGroupInput>;
  /** Campaign budget */
  budget?: InputMaybe<Scalars['Float']['input']>;
  /** Campaign end date */
  endDate?: InputMaybe<Scalars['String']['input']>;
  /** Campaign goal */
  goal?: InputMaybe<MarketGoal>;
  hydrated: Scalars['Boolean']['input'];
  /** Campaign id.  Includes temp if it is a new campaign */
  id: Scalars['String']['input'];
  /** Campaign paused */
  isPaused: Scalars['Boolean']['input'];
  /** Campaign name */
  name: Scalars['String']['input'];
  /** Product id */
  productId: Scalars['String']['input'];
  /** Product name */
  productName: Scalars['String']['input'];
  /** Campaign start date */
  startDate: Scalars['String']['input'];
}

/** Campaign Optimized Bidding Recommendation Card */
export interface CampaignOptimizedBiddingRecommendationCard {
  __typename: 'CampaignOptimizedBiddingRecommendationCard';
  /** Campaign optimized bidding recommendation card campaign id */
  campaignId: Scalars['String']['output'];
  /** Campaign optimized bidding recommendation card campaign name */
  campaignName?: Maybe<Scalars['String']['output']>;
  /** Campaign optimized bidding recommendation card selected checkmark */
  checkBox?: Maybe<EgdsBasicCheckBox>;
  /** Campaign optimized bidding recommendation card current bidding strategy */
  currentBiddingStrategy: BiddingStrategy;
  /** Campaign optimized bidding recommendation card description */
  description: Scalars['String']['output'];
  /** Campaign optimized bidding recommendation card market goal description */
  marketGoalData: Array<MarketGoalData>;
  /** Campaign optimized bidding recommendation card recommendation id */
  recommendationId: Scalars['String']['output'];
  /** Campaign optimized bidding recommendation card recommended bidding strategy */
  recommendedBiddingStrategy: BiddingStrategy;
  /** Campaign optimized bidding recommendation card select */
  select: EgdsBasicSelect;
  /** Campaign optimized bidding recommendation card title */
  title: Scalars['String']['output'];
}

/** CAP Campaign option info */
export interface CampaignOption {
  __typename: 'CampaignOption';
  /** Campaign id */
  campaignId: Scalars['String']['output'];
  /** Campaign name */
  campaignName: Scalars['String']['output'];
  /** Campaign paused status */
  isPaused: Scalars['Boolean']['output'];
}

/** Campaign overview table action cell */
export interface CampaignOverviewActionCell {
  __typename: 'CampaignOverviewActionCell';
  /** ad group id */
  adGroupId?: Maybe<Scalars['String']['output']>;
  /** ad id */
  adId?: Maybe<Scalars['String']['output']>;
  /** campaign id */
  campaignId: Scalars['String']['output'];
  /** action cell */
  iconAction: Icon;
  /** add menu icon */
  iconAdd: Icon;
  /** edit menu icon */
  iconEdit: Icon;
}

/** Border for table */
export interface CampaignOverviewBorder {
  __typename: 'CampaignOverviewBorder';
  /** horizontal border for cell */
  horizontalBorder?: Maybe<Scalars['Boolean']['output']>;
  /** vertical border for cell */
  verticalBorder?: Maybe<Scalars['Boolean']['output']>;
}

/** Campaign data in table rows */
export interface CampaignOverviewDataRows {
  __typename: 'CampaignOverviewDataRows';
  /** Table row content */
  row: Array<CampaignOverviewTableCellContent>;
}

/** Campaign overview table delivery status cell */
export interface CampaignOverviewDeliveryStatusCell {
  __typename: 'CampaignOverviewDeliveryStatusCell';
  /** id of campaign, ad group or ad */
  id: Scalars['String']['output'];
  /** More info about status */
  moreInfo?: Maybe<EgdsMoreInfoTrigger>;
}

export interface CampaignOverviewFocusTypeahead {
  /** Ad group data to populate typeahead */
  adGroupOptions?: Maybe<Array<AdGroupOption>>;
  /** Campaign data to populate typeahead */
  campaignOptions?: Maybe<Array<CampaignOption>>;
  /** Typeahead heading */
  heading: Scalars['String']['output'];
  /** Typeahead render type */
  renderType: EgdsTypeaheadRenderType;
  /** Typeahead with input field */
  searchTypeahead: EgdsTypeaheadInputField;
  /** Typeahead subheading */
  subheading: Scalars['String']['output'];
  /** Typeahead trigger menu */
  triggerMenu: TypeaheadTriggerMenu;
  /** options for typeahead select */
  typeaheadSelect?: Maybe<EgdsBasicSelect>;
}

/** Campaign overview table text cell */
export interface CampaignOverviewLocationTargetingCell {
  __typename: 'CampaignOverviewLocationTargetingCell';
  /** Close icon */
  closeIcon: Icon;
  /** Info icon */
  infoIcon: Icon;
  /** Continent to country count list */
  regionCountryCountList: Scalars['String']['output'];
  /** Total locations */
  total: Scalars['String']['output'];
}

/** Campaign overview table number cell */
export interface CampaignOverviewNumberCell {
  __typename: 'CampaignOverviewNumberCell';
  /** amount int cell */
  amount?: Maybe<Scalars['Float']['output']>;
  /** formatting for the number cell */
  format?: Maybe<Scalars['String']['output']>;
}

/** Campaign overview table action cell */
export interface CampaignOverviewStatusCell {
  __typename: 'CampaignOverviewStatusCell';
  /** campaign id */
  campaignId: Scalars['String']['output'];
  /** campaign name */
  campaignName: Scalars['String']['output'];
  /** campaign end date */
  endDate: Scalars['String']['output'];
  /** status cell */
  switch: EgdsStandardSwitch;
}

/** Campaign table cell content */
export interface CampaignOverviewTableCellContent {
  __typename: 'CampaignOverviewTableCellContent';
  /** border for cells */
  border?: Maybe<CampaignOverviewBorder>;
  content?: Maybe<CampaignCellInfo>;
}

export interface CampaignOverviewTableResponse {
  /** Campaign overview table header */
  columns: Array<OverviewTableColumn>;
  /** Create label */
  createLabel: Scalars['String']['output'];
  /** Edit label */
  editLabel: Scalars['String']['output'];
  /** Campaign overview heading */
  heading?: Maybe<Scalars['String']['output']>;
  /** Last sorting parameters */
  lastSort: CampaignOverviewTableSorting;
  /** Icon for no table data */
  noRowsIcon: Icon;
  /** Placeholder for no table data */
  noRowsPlaceholder: Scalars['String']['output'];
  /** Campaign overview table totals row */
  rows: Array<CampaignOverviewDataRows>;
  /** Campaign overview table striped property */
  striped: Scalars['String']['output'];
  /** Campaign overview table title */
  title: Scalars['String']['output'];
}

export interface CampaignOverviewTableSorting {
  __typename: 'CampaignOverviewTableSorting';
  sortBy?: Maybe<Scalars['String']['output']>;
  sortDirection?: Maybe<Scalars['String']['output']>;
}

export interface CampaignOverviewTableSortingInput {
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
}

/** Campaign overview table text cell */
export interface CampaignOverviewTextCell {
  __typename: 'CampaignOverviewTextCell';
  /** text cell */
  text: Scalars['String']['output'];
}

/** Campaign Product Card Content */
export interface CampaignProductCard {
  __typename: 'CampaignProductCard';
  content: CampaignProductSelect;
}

export type CampaignProductSelect = CampaignPropertyCard | CampaignReadOnlyProductCard | CampaignRouteCard;

/** Campaign Property Card Content */
export interface CampaignPropertyCard {
  __typename: 'CampaignPropertyCard';
  /** Missing property link */
  missingPropertyLink: EgdsInlineLink;
  /** Missing property text */
  missingPropertyText: Scalars['String']['output'];
  /** Property Select Typeahead */
  propertySelectTypeahead: EgdsTypeaheadInputField;
  /** Property Select Typeahead Options */
  propertySelectTypeaheadOptions: Array<EgdsBasicOption>;
}

/** Campaign Publish Dialog */
export interface CampaignPublishDialog {
  __typename: 'CampaignPublishDialog';
  /** Publish Dialog buttons */
  dialogButtons: Array<CampaignWizardNavigationButton>;
  /** Publish Dialog subtext */
  dialogSubHeading?: Maybe<Scalars['String']['output']>;
  /** Publish Dialog title */
  dialogTitle?: Maybe<Scalars['String']['output']>;
}

/** Campaign Publish Response */
export interface CampaignPublishResponse {
  __typename: 'CampaignPublishResponse';
  /** Error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Success message description */
  successMessageDescription?: Maybe<Scalars['String']['output']>;
  /** Success message */
  successMessageHeading?: Maybe<Scalars['String']['output']>;
}

/** Campaign Read Only Property Card Content */
export interface CampaignReadOnlyProductCard {
  __typename: 'CampaignReadOnlyProductCard';
  /** Description */
  icon: Icon;
  /** Product name */
  productName: Scalars['String']['output'];
  /** Product name title */
  productNameTitle: Scalars['String']['output'];
}

/** Campaign reporting data in table rows */
export interface CampaignReportingData {
  __typename: 'CampaignReportingData';
  /** Table row content */
  row?: Maybe<Array<AdvertiserReportingTableCellContent>>;
}

/** Campaign Request Inputs */
export interface CampaignRequestInput {
  /** Campaign ad group list */
  adGroupList: Array<AdGroupRequestInput>;
  /** Budget amount */
  budgetAmount: Scalars['Float']['input'];
  /** Campaign Id */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Campaign name */
  campaignName: Scalars['String']['input'];
  /** Campaign Targeting List */
  campaignTargeting: CampaignTargetingInput;
  /** Campaign Update status */
  isCampaignUpdated: Scalars['Boolean']['input'];
  /** Campaign status */
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  /** Market goal */
  marketGoal: MarketGoal;
}

/** Campaign review card */
export interface CampaignReviewCard {
  __typename: 'CampaignReviewCard';
  /** Review Card edit button */
  editButton: UiSecondaryButton;
  /** Review card editing item id */
  editItemId: Scalars['String']['output'];
  /** Review card line items */
  items: Array<ReviewCardLineItem>;
}

/** Campaign Routes Card Content */
export interface CampaignRouteCard {
  __typename: 'CampaignRouteCard';
  /** route destination select */
  routeDestinationSelect?: Maybe<EgdsSelect>;
  /** route origin select */
  routeOriginSelect?: Maybe<EgdsSelect>;
}

/** Campaign Creation Schedule Card Content */
export interface CampaignScheduleCard {
  __typename: 'CampaignScheduleCard';
  /** clear button label */
  datePickerClearButtonLabel?: Maybe<Scalars['String']['output']>;
  /** submit button label */
  datePickerSubmitButtonLabel?: Maybe<Scalars['String']['output']>;
  /** date input box */
  endDate: EgdsInputField;
  /** check box label */
  endDateCheckboxLabel?: Maybe<Scalars['String']['output']>;
  /** end date check state */
  endDateCheckboxState: Scalars['Boolean']['output'];
  /** date input box */
  startDate: EgdsInputField;
}

/** Campaign Targeting Inputs */
export interface CampaignTargetingInput {
  /** End Date */
  endDate?: InputMaybe<TargetingInput>;
  /** Start Date */
  startDate: TargetingInput;
}

/** Campaign creation select options */
export interface CampaignWizardCard {
  __typename: 'CampaignWizardCard';
  cardContent: CampaignCardContent;
  /** Campaign Creation Card heading */
  cardHeading: Scalars['String']['output'];
  /** Campaign Creation Card subtext */
  cardSubHeading?: Maybe<Scalars['String']['output']>;
  /** Learn more link */
  learnMoreLink?: Maybe<LearnMoreLink>;
}

/** Controls for navigating through the wizard */
export interface CampaignWizardNavigation {
  __typename: 'CampaignWizardNavigation';
  /** Control navigation buttons */
  navigationButtons: Array<CampaignWizardNavigationButton>;
  /** Campaign publish dialog */
  publishDialog: CampaignPublishDialog;
}

/** Campaign Wizard Action Buttons */
export interface CampaignWizardNavigationButton {
  __typename: 'CampaignWizardNavigationButton';
  /** action button */
  action: PortalButton;
  /** action button type */
  actionType: Scalars['String']['output'];
}

/** Campaign Wizard Sidebar */
export interface CampaignWizardSideBar {
  __typename: 'CampaignWizardSideBar';
  /** Action areas */
  actionAreas: Array<SideBarActionArea>;
  /** Campaign currently being acted on */
  campaign: SideBarCampaign;
}

/** Campaign Configuration Step */
export interface CampaignWizardStep {
  __typename: 'CampaignWizardStep';
  /** Campaign Configuration Step error banners */
  banners: Array<PortalBanner>;
  /** Buttons on step outside of card content */
  stepButtons?: Maybe<Array<PortalButton>>;
  /** Campaign Configuration Step content */
  stepCards?: Maybe<Array<CampaignWizardCard>>;
  /** Campaign Configuration Step heading */
  stepHeading: Scalars['String']['output'];
}

/**
 *
 *     Represents a template and what type of plurality it represents.
 *
 */
export interface CardinalTemplate {
  __typename: 'CardinalTemplate';
  category: PluralCategory;
  template: Scalars['String']['output'];
}

/** Describe which application is invoking bex api */
export interface ClientInfoInput {
  /** Name of the application */
  name: Scalars['String']['input'];
  /** Version of the application eg:18.10 or 5fbc9958 */
  version?: InputMaybe<Scalars['String']['input']>;
}

/** Used to capture client side analytics event like clicks. See https://pages.github.expedia.biz/Brand-Expedia/bernie/docs/analytics-click-events/ */
export interface ClientSideAnalytics {
  __typename: 'ClientSideAnalytics';
  eventType?: Maybe<EgReferrerEventType>;
  linkName: Scalars['String']['output'];
  referrerId: Scalars['String']['output'];
  uisPrimeMessages: Array<EgUisMicroMessage>;
  urls?: Maybe<Array<Scalars['URL']['output']>>;
}

/** Used to capture client side analytics event for tracking impression of a UI component */
export interface ClientSideImpressionEventAnalytics {
  __typename: 'ClientSideImpressionEventAnalytics';
  /** Represents event name */
  event: Scalars['String']['output'];
  /** Represents link name for referrer event */
  linkName?: Maybe<Scalars['String']['output']>;
  /** Represents id for referrer event */
  referrerId: Scalars['String']['output'];
  /** Represents uis prime messages */
  uisPrimeMessages: Array<EgUisMicroMessage>;
  /**
   * Represents url of the component to be tracked
   * @deprecated Please use the URLs list field
   */
  url?: Maybe<Scalars['URL']['output']>;
  /** A list of URLs to be requested for the impression event */
  urls?: Maybe<Array<Scalars['URL']['output']>>;
}

/**
 *
 *     Represents the dynamic part of the composite text.  Keys are used to insert the localized fragment into
 *     the EGDSCompositeLocalizedText's template.
 *
 */
export interface CompositeLocalizedTextModel {
  __typename: 'CompositeLocalizedTextModel';
  key: Scalars['String']['output'];
  /** @deprecated Use models instead */
  localizedFragment: EgdsLocalizedText;
  models: EgdsTemplateModels;
}

/** UI Theme to be applied to a `UISectionContainer` or a `TripsSectionContainer`. */
export enum ContainerTheme {
  DEFAULT = 'DEFAULT',
  IMPORTANT = 'IMPORTANT'
}

/** Ad content input */
export interface ContentInput {
  /** Ad type */
  ad_type: Scalars['String']['input'];
  /** Ad creative for FSL */
  creativeFSL?: InputMaybe<FslCreativeInput>;
  /** Ad creative LSL */
  creativeLSL?: InputMaybe<LslCreativeInput>;
  /** Content locale */
  locale: Scalars['String']['input'];
}

/** The input BEX context used for identifying users, sites and other platform functions. See the platform-expsite docs for more info: https://docweb.prod.expedia.com/expsite */
export interface ContextInput {
  /** Currency as Alphabetic ISO 4217 code e.g. 'USD'. Use if default currency for Locale needs to be overwritten. */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Debugging context, such as Abacus overrides */
  debugContext?: InputMaybe<DebugContextInput>;
  /** Client/user Device information */
  device: DeviceInput;
  /** The Expedia Affiliate Partner ID */
  eapid?: InputMaybe<Scalars['Int']['input']>;
  /** The context locale, e.g. en_US */
  locale: Scalars['Locale']['input'];
  /** User preference for tracking which is only required in POSs that require tracking opt in */
  privacyTrackingState?: InputMaybe<PrivacyTrackingState>;
  /** The context Site ID, e.g. (70201 for Orbitz) */
  siteId: Scalars['Int']['input'];
  /** The legacy TravelProductID */
  tpid?: InputMaybe<Scalars['Int']['input']>;
}

/** Creative Card Content */
export interface CreativeCard {
  __typename: 'CreativeCard';
  content: CreativeCardContent;
}

export type CreativeCardContent = FlightCreativeCard | LodgingCreativeCard;

/** Creative Dialog */
export interface CreativeDialog {
  __typename: 'CreativeDialog';
  /** Ad preview in creative dialog */
  adPreview: AdPreview;
  /** Creative Dialog button */
  dialogButton: UiSecondaryButton;
  /** Creative Dialog subtext */
  dialogSubHeading?: Maybe<Scalars['String']['output']>;
  /** Creative Dialog title */
  dialogTitle?: Maybe<Scalars['String']['output']>;
}

/** Creative Picture Card */
export interface CreativePictureCard {
  __typename: 'CreativePictureCard';
  /** Creative Picture Card content */
  cardContent: CreativeTextArea;
  /** Creative Picture Card subtext */
  cardSubHeading: Scalars['String']['output'];
  /** Creative Picture Card title */
  cardTitle: Scalars['String']['output'];
  /** Image auto selection */
  imageAutoSelectionPrompt: ImageAutoSelectPrompt;
  /** Image select button */
  imageSelectButton: EgdsOverlayButton;
  /** Creative Picture */
  pictureCard: EgdsImageCard;
}

/** Creative Picture Card Content for Property Headline and Description */
export interface CreativeTextArea {
  __typename: 'CreativeTextArea';
  /** Creative Picture Card description */
  description: EgdsInputField;
  /** Creative Picture Card description instruction */
  descriptionInstruction: Scalars['String']['output'];
  /** Creative Picture Card headline */
  headline: EgdsInputField;
  /** Creative Picture Card headline instruction */
  headlineInstruction: Scalars['String']['output'];
}

/** Data point for performance overview chart */
export interface DataPoint {
  __typename: 'DataPoint';
  /** X axis value */
  xAxis?: Maybe<Scalars['String']['output']>;
  /** Y axis value */
  yAxis?: Maybe<MetricFormat>;
}

/** A common date object */
export interface Date {
  __typename: 'Date';
  /** The day of the month, e.g. `1` for the 1st */
  day: Scalars['Int']['output'];
  /**
   * Format with a pattern. 'd MMM uuuu' will format 2011-12-03 as '3 Dec 2011'
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  formatted: Scalars['String']['output'];
  /**
   * Format with a pattern. 'd MMM uuuu' will format 2011-12-03 as '3 Dec 2011', This is specifically for the cases when GraphQlContext does not get injected after sdk upgrade
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  formattedDate: Scalars['String']['output'];
  /**
   * Get a localized full date string i.e. en='Tue, Oct 13' de='Di. 13 Oct
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  fullDateFormat?: Maybe<Scalars['String']['output']>;
  /**
   * Get a simple string in ISO local date format '2011-12-03'
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  isoFormat: Scalars['String']['output'];
  /**
   * Get a localized long date string i.e. en='Feb 26, 2020' de='26. Feb. 2020
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  longDateFormat?: Maybe<Scalars['String']['output']>;
  /**
   * Get a localized medium date string i.e. en='Feb 26' de='26. Feb.
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  mediumDateFormat?: Maybe<Scalars['String']['output']>;
  /** The numeric month, e.g. `1` for January */
  month: Scalars['Int']['output'];
  /**
   * Get a localized short date string i.e. en='2/26/2020' de='26.02.2020
   * @deprecated Migrate to using platform formatters and return a localized string
   */
  shortDateFormat?: Maybe<Scalars['String']['output']>;
  /** The four-digit year e.g. `1999` */
  year: Scalars['Int']['output'];
}


/** A common date object */
export interface DateFormattedArgs {
  format: Scalars['String']['input'];
}


/** A common date object */
export interface DateFormattedDateArgs {
  format: Scalars['String']['input'];
}

/** A common date object */
export interface DateInput {
  /** The day of the month, e.g. `1` for the 1st */
  day: Scalars['Int']['input'];
  /** The numeric month, e.g. `1` for January */
  month: Scalars['Int']['input'];
  /** The four-digit year e.g. `1999` */
  year: Scalars['Int']['input'];
}

/** Type used during a product search to specify a given date. Usually this date would not be a core search criteria like a departure day for a flight. */
export interface DateValue {
  __typename: 'DateValue';
  id: Scalars['String']['output'];
  value: Date;
}

/** Context used for debugging purposes, such as overriding Abacus experiments */
export interface DebugContextInput {
  /** Abacus exposure overrides to be applied */
  abacusOverrides: Array<ExposureInput>;
  /** ALTER mode */
  alterMode?: InputMaybe<AlterMode>;
}

/** Device information */
export interface DeviceInput {
  type: DeviceType;
}

/** Valid device types */
export enum DeviceType {
  APP_PHONE = 'APP_PHONE',
  APP_TABLET = 'APP_TABLET',
  CHAT_APP = 'CHAT_APP',
  DESKTOP = 'DESKTOP',
  MOBILE_PHONE = 'MOBILE_PHONE',
  MOBILE_TABLET = 'MOBILE_TABLET',
  VOICE_APP = 'VOICE_APP'
}

/** Dialog block items */
export interface DialogBlock {
  __typename: 'DialogBlock';
  /** Download type image */
  image: Image;
  /** Download type text */
  text?: Maybe<Scalars['String']['output']>;
}

/** Dimensions for a presentation object i.e. image or icon */
export interface DimensionsInput {
  density: Scalars['Float']['input'];
  height: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
}

/** Input for dismissOpportunity mutation */
export interface DismissOpportunityInput {
  /** Dismissal reason */
  dismissalReason: Scalars['String']['input'];
  /** Recommendation ids */
  recommendationIds: Array<Scalars['String']['input']>;
}

/** Dismiss Opportunity Response */
export interface DismissOpportunityResponse {
  __typename: 'DismissOpportunityResponse';
  /** Error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Success message */
  successMessage?: Maybe<Scalars['String']['output']>;
}

/** Dialog with download trigger images */
export interface DownloadButtonWithDialog {
  __typename: 'DownloadButtonWithDialog';
  /** Download dialog */
  dialog: EgdsFullScreenDialog;
  /** Dialog block items */
  dialogButtons: Array<DialogBlock>;
  /** Button title */
  title?: Maybe<Scalars['String']['output']>;
  /** Button */
  triggerButton: UiPrimaryButton;
}

/**
 *
 *     Representation for the duration span element.
 *
 */
export interface DurationSpan {
  /** Close button on the duration span dialog element. */
  closeButton: EgdsButton;
  /** Done button on the duration span dialog element. */
  doneButton: EgdsButton;
  /** Title for the duration span dialog element. Example: Cruise length, Trip Duration. */
  title: Scalars['String']['output'];
}

/**
 *
 *     Input validation for the duration span element.
 *
 */
export interface DurationSpanInputValidation extends EgdsInputValidation {
  __typename: 'DurationSpanInputValidation';
  /**
   *
   *         Error message to display to the user whenever the validation fails.
   *
   */
  errorMessage: Scalars['String']['output'];
  /** Component that shows an specific error when changes are tried to be saved. */
  errorSummary?: Maybe<EgdsErrorSummary>;
  /** Specifies the type of validation. */
  type: DurationSpanValidationType;
}

/**
 *
 *     Implementation for the duration span field selection element.
 *
 */
export interface DurationSpanSelector extends DurationSpan, EgdsElement {
  __typename: 'DurationSpanSelector';
  /** Close button on the duration span dialog element. */
  closeButton: EgdsButton;
  /** Done button on the duration span dialog element. */
  doneButton: EgdsButton;
  /** Duration span dialog element unique identifier. */
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Input field for the maximum span limit. */
  maxDurationSpanLimit: EgdsNumberInputField;
  /** Input field for the minimum span limit. */
  minDurationSpanLimit: EgdsNumberInputField;
  /** Title for the duration span dialog element. Example: Cruise length, Trip Duration. */
  title: Scalars['String']['output'];
  /**
   *
   *     Validations with corresponding error messages to display to the user
   *     if the inputs don't fulfill the requirements. The order of the validations
   *     dictate the order in which the error messages are displayed.
   *
   */
  validations?: Maybe<Array<EgdsInputValidation>>;
}

/** Different types of duration span input validations. */
export enum DurationSpanValidationType {
  /** Used to validate that a maximum input value is greater than a minimum input value. */
  MAXIMUM_GREATER_THAN_MINIMUM = 'MAXIMUM_GREATER_THAN_MINIMUM',
  /** Used to validate that the user input is less than or equal to some specified value. Example: Maximum 10 nights. */
  MAXIMUM_VALUE = 'MAXIMUM_VALUE',
  /** Used to validate that a minimum input value is less than a maximum input value. */
  MINIMUM_LESS_THAN_MAXIMUM = 'MINIMUM_LESS_THAN_MAXIMUM',
  /** Used to validate that the user input is greater than or equal to some specified value. Example: Minimum 2 nights. */
  MINIMUM_VALUE = 'MINIMUM_VALUE',
  /** Used to validate that the user input has a value. */
  REQUIRED_VALUE = 'REQUIRED_VALUE'
}

/**
 *
 *     Action dialogs are containers that content can be rendered in. Clients should orchestrate how content
 *     will render within the dialog. Action dialogs specifically contain one or more buttons for users to take action upon.
 *
 *     https://www.figma.com/file/KqOeuNsfHUNAgd4johSxsh/Dialog
 *
 */
export interface EgdsActionDialog extends EgdsDialog {
  __typename: 'EGDSActionDialog';
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer: EgdsDialogFooter;
}

/**
 *
 *     Representation of a badge, implementations could include status, saved, notification, etc.
 *
 *     https://www.figma.com/file/aYQZVH6x5kqAGj8LbLzTVWfX/Badges
 *
 */
export interface EgdsBadge {
  accessibility?: Maybe<Scalars['String']['output']>;
  graphic?: Maybe<UiGraphic>;
  /** Represents display size of badge. Example: 'large', 'small' */
  size?: Maybe<Scalars['String']['output']>;
  /** Text content to be displayed within the badge */
  text?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents a notification badge
 *     https://www.figma.com/file/aYQZVH6x5kqAGj8LbLzTVWfX/Badge?node-id=7738%3A8273
 *
 */
export interface EgdsBadgeNotification extends EgdsBadge, EgdsElement {
  __typename: 'EGDSBadgeNotification';
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Count value to be displayed within the badge */
  count?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  graphic?: Maybe<UiGraphic>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  size?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  /** Represents theme for notification badge. Possible values are 'unread', 'activity', 'action', 'default', 'inverse' */
  theme?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
}

/** Basic CheckBox element */
export interface EgdsBasicCheckBox extends EgdsCheckBox, EgdsElement {
  __typename: 'EGDSBasicCheckBox';
  /** analytics event to fire when checkbox is checked */
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  description?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  label: EgdsText;
  name?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  state: EgdsCheckBoxState;
  /** analytics event to fire when checkbox is unchecked */
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  /** Specifies the value associated with the checkbox if needed */
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents the simplest type of localized string.  There are no plurals or fragments.
 *     Example: `Your trip to {destination}`, where `destination` is provided by the client
 *
 */
export interface EgdsBasicLocalizedText extends EgdsElement, EgdsTemplateModels, EgdsText {
  __typename: 'EGDSBasicLocalizedText';
  /**
   *
   *         Use this field when accessibility text needs to be different from text.
   *         Provide default accessibility value and fallback text
   *
   */
  accessibilityTemplate?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Provide a list of template models. */
  models: Array<TemplateModel>;
  /**
   *
   *         Provide a template string.  Example:  `Your trip from {origin} to {destination}`
   *
   */
  template: Scalars['String']['output'];
  /** Provide a default value */
  text: Scalars['String']['output'];
}

/**
 *
 *    Represents basic implementation of EGDSOption.
 *
 */
export interface EgdsBasicOption extends EgdsElement, EgdsOption {
  __typename: 'EGDSBasicOption';
  changeAnalytics?: Maybe<ClientSideAnalytics>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  selected?: Maybe<Scalars['Boolean']['output']>;
  value: Scalars['String']['output'];
}

/**
 *
 * Represents basic key - value chips or pills
 * Clicking or tapping any part of the pill will toggle between select and deselect.
 *
 */
export interface EgdsBasicPill extends EgdsPill {
  __typename: 'EGDSBasicPill';
  /** Accessibility label for the pill */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** analytics tracking for the deselection of pill */
  deselectAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  /** Icon to be displayed along with the label */
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         type `name` or param name of the pill.
   *         Ex: `paymentType` for a "Free Cancellation" filter pill
   *
   */
  name?: Maybe<Scalars['String']['output']>;
  /** Label to be displayed on the pill */
  primary?: Maybe<Scalars['String']['output']>;
  /** analytics tracking for the selection of pill */
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars['Boolean']['output'];
  /** Label to be displayed on the pill when selected */
  selectedText?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         value of the pill.
   *         Ex: `FREE_CANCELLATION` for a "Free Cancellation" filter pill
   *
   */
  value?: Maybe<Scalars['String']['output']>;
}

/** Basic radio button element */
export interface EgdsBasicRadioButton extends EgdsRadioButton {
  __typename: 'EGDSBasicRadioButton';
  description?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  labelSuffix?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
}

/** Basic radio group element */
export interface EgdsBasicRadioGroup extends EgdsRadioGroup {
  __typename: 'EGDSBasicRadioGroup';
  errorMessage?: Maybe<Scalars['String']['output']>;
  groupName: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars['String']['output']>;
}

export interface EgdsBasicRemovablePill extends EgdsPill {
  __typename: 'EGDSBasicRemovablePill';
  /** Accessibility label for the pill */
  accessibility?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  /** Icon to be displayed along with the label */
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         type `name` or param name of the pill.
   *         Ex: `paymentType` for a "Free Cancellation" filter pill
   *
   */
  name?: Maybe<Scalars['String']['output']>;
  /** Label to be displayed on the pill */
  primary?: Maybe<Scalars['String']['output']>;
  /** denotes removal trigger of pill */
  remove?: Maybe<UiGraphic>;
  /** Voiceover text when pill is removed */
  removeAccessibility?: Maybe<Scalars['String']['output']>;
  /** analytics tracking for the removal of pill */
  removeAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars['Boolean']['output'];
  /**
   *
   *         value of the pill.
   *         Ex: `FREE_CANCELLATION` for a "Free Cancellation" filter pill
   *
   */
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents the standard implementation of EGDSSectionHeading.
 *     This can be used for any Product specific use-cases
 *     A product can be an Activity / Property / Flight / Car etc.
 *
 *     eg it can represent section heading for a list of item.
 *
 */
export interface EgdsBasicSectionHeading extends EgdsSectionHeading {
  __typename: 'EGDSBasicSectionHeading';
  /** Represents the graphic rendered before heading */
  graphic?: Maybe<UiGraphic>;
  heading: EgdsHeading;
  subheading?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *    Represents basic implementation of EGDSSelect.
 *
 */
export interface EgdsBasicSelect extends EgdsElement, EgdsSelect {
  __typename: 'EGDSBasicSelect';
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  label: Scalars['String']['output'];
  options: Array<EgdsOption>;
  readOnly: Scalars['Boolean']['output'];
  /** Informs the user if the field is mandatory. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** Value for the select field */
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *    Represents basic implementation of StepInput.
 *
 */
export interface EgdsBasicStepInput extends EgdsElement, EgdsStepInput {
  __typename: 'EGDSBasicStepInput';
  /** Analytics event to be fired whenever the value for this field decrease */
  decreaseAnalytics?: Maybe<ClientSideAnalytics>;
  decreaseText?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Analytics event to be fired whenever the value for this field increase */
  increaseAnalytics?: Maybe<ClientSideAnalytics>;
  increaseText?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  step: Scalars['Int']['output'];
  subLabel?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
}

/**
 *
 *    Represents basic implementation of EGDSTab.
 *
 */
export interface EgdsBasicTab extends EgdsElement, EgdsTab {
  __typename: 'EGDSBasicTab';
  accessibility?: Maybe<Scalars['String']['output']>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  tabId: Scalars['String']['output'];
}

/**
 *
 *    Represents basic implementation of EGDSTabs.
 *
 */
export interface EgdsBasicTabs extends EgdsElement, EgdsTabs {
  __typename: 'EGDSBasicTabs';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  selectedTabId?: Maybe<Scalars['String']['output']>;
  tabs: Array<EgdsTab>;
}

/**
 *
 *    Represents implementation of traveler for traveler selector.
 *
 */
export interface EgdsBasicTraveler extends EgdsTraveler {
  __typename: 'EGDSBasicTraveler';
  ages?: Maybe<Array<EgdsSelect>>;
  count: EgdsStepInput;
  type?: Maybe<EgdsTravelerType>;
}

/**
 *
 *    Represents implementation of EGDSTravelerSelector which will be used for flight, activity, cruises or brands like Vrbo where room less travelers are required.
 *
 */
export interface EgdsBasicTravelerSelector extends EgdsElement, EgdsTravelerSelector {
  __typename: 'EGDSBasicTravelerSelector';
  closeButton: EgdsButton;
  doneButton: EgdsButton;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Additional information displayed at travelers selector. Example: activity name/s. */
  subTitles?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  /** Represents travelers category for the travelers selector. */
  travelers?: Maybe<EgdsTravelers>;
  /**
   *
   *         Validations with corresponding error messages to display if the input is invalid.
   *         The order in which the validations appears indicates the order of priority of which error message to display.
   *
   */
  validations?: Maybe<Array<EgdsInputValidation>>;
}

/**
 *
 *     Triggers another component (e.g. Menu, Sheet, Full-screen dialog).
 *     Clicking or tapping any part of the pill will trigger the interaction.
 *
 */
export interface EgdsBasicTriggerPill extends EgdsPill {
  __typename: 'EGDSBasicTriggerPill';
  /** Accessibility label for the pill */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** analytics tracking for the deselection of pill */
  deselectAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  /** Icon to be displayed along with the label */
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars['String']['output']>;
  /** Label to be displayed on the pill */
  primary?: Maybe<Scalars['String']['output']>;
  /** analytics tracking for the selection of pill */
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars['Boolean']['output'];
  /** denotes Icon, that triggers to another component */
  trigger?: Maybe<UiGraphic>;
}

/** Bulleted variant of EGDS typography list */
export interface EgdsBulletedList extends EgdsElement, EgdsTextList {
  __typename: 'EGDSBulletedList';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  listItems: Array<EgdsTextListItem>;
  size?: Maybe<EgdsTextListSize>;
}

/**
 *
 * Do not use this interface
 * Check the documentation for more information about why you should not use this.
 * https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
 *
 *     Intended to be implemented by EGDS Button objects (including variations)
 *
 */
export interface EgdsButton {
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  /** Used to capture client side analytics on button click. */
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** Styles established in EGDS design docs. https://www.figma.com/file/bbPtPOgPN4sttR3BzDaTZc/Buttons */
export enum EgdsButtonStyle {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY'
}

/**
 *
 *     Represents different cards which can be used interchangeably.
 *
 */
export type EgdsCard = EgdsImageCard | EgdsImageLinkCard | EgdsMarkCard | EgdsStandardMessagingCard;

/**
 *
 *     Component that support EGDSCards union type as Carousel Item to be implemented inside EGDSCarousel
 *
 */
export interface EgdsCardCarouselItem extends EgdsCarouselItem {
  __typename: 'EGDSCardCarouselItem';
  /** Union for cards so any applicable cards can be used. */
  card: EgdsCard;
  id?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents the variations of a card. Can be used with EGDSCard union members.
 *     https://www.figma.com/file/M3udFrUojCRwrcoAiPnQ3Yaw/Card?node-id=11143%3A833&t=blroVUeSfs4Eqnoj-0
 *
 */
export enum EgdsCardThemes {
  BORDERED = 'BORDERED',
  PADDED = 'PADDED'
}

/**
 *
 *     Represents localized text with cardinality.
 *
 *     Example: `{num} rooms` where `num` is provided by the client.
 *
 *     Note: "Cardinal" text is different than "Ordinal" text in that cardinal number tell "how many" (i.e. "2 rooms"),
 *     whereas ordinal numbers tell in which order (i.e. "2nd room").
 *
 */
export interface EgdsCardinalLocalizedText extends EgdsElement, EgdsTemplateModels, EgdsText {
  __typename: 'EGDSCardinalLocalizedText';
  /**
   *
   *         Use this field when accessibility text needs to be different from text. Provide a list of templates for all supported cardinal categories (0, 1, 2, few, many, other)
   *
   */
  accessibilityTemplates?: Maybe<Array<CardinalTemplate>>;
  /** Use this field when accessibility text needs to be different from text. Provide default accessibility value and fallback text */
  accessibilityText?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Provide a list of template models. */
  models: Array<TemplateModel>;
  /**
   *
   *        Optional secondary templates for different texts than required templates. Example: "{travelerCount} traveler with pets"
   *
   */
  secondaryTemplates?: Maybe<Array<CardinalTemplate>>;
  /**
   *
   *        Provide a list of templates for all supported cardinal categories (0, 1, 2, few, many, other)
   *
   */
  templates: Array<CardinalTemplate>;
  /** Provide default localized string for initial value and fallback text */
  text: Scalars['String']['output'];
}

/**
 *
 *      Representation of a carousel that can support different kind of cards, including a combination of media and text
 *
 */
export interface EgdsCarousel {
  __typename: 'EGDSCarousel';
  /** Accessibility text for the carousel */
  carouselId?: Maybe<Scalars['String']['output']>;
  /** List of CarouselItem that represent different items to display in the carousel */
  items: Array<EgdsCarouselItem>;
  /**
   *
   *     Try to not return EGDSButton interface itself because clients won't need all of its implementations.
   *     https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
   *
   *     Next button on a carousel (can give the flexibility to add text, analytics, accessibility and icons).
   *
   */
  nextButton?: Maybe<EgdsButton>;
  /**
   *
   *     Try to not return EGDSButton interface itself because clients won't need all of its implementations.
   *     https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
   *
   *     Previous button on a carousel (can give the flexibility to add text, analytics, accessibility and icons).
   *
   */
  previousButton?: Maybe<EgdsButton>;
}

/**
 *
 *     Interface that need to be implemented in custom carousel items to be able to use EGDSCarousel
 *
 */
export interface EgdsCarouselItem {
  id?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents a CheckBox element
 *     Ex. https://www.figma.com/file/fvv9g0KUyrhcLSErmT6Cd5/EGID---Registration?node-id=3659%3A51102
 *
 */
export interface EgdsCheckBox {
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  label: EgdsText;
  name?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents collections of checkboxes describing a set of related options.
 *     UITK react:
 *     https://uitk.expedia.biz/expedia/en_US/components/checkbox
 *     Figma:
 *     https://www.figma.com/file/ifJBz5r2kKpXt6fhle0uWE/Checkbox
 *
 */
export interface EgdsCheckBoxGroup {
  __typename: 'EGDSCheckBoxGroup';
  /** Represents checkbox group error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Represents the name for the checkbox group. It will be used as the name for each child component. */
  groupName: Scalars['String']['output'];
  /** Represents the heading label of the checkbox group. */
  label?: Maybe<Scalars['String']['output']>;
  /** Represents options for checkbox group. */
  options: Array<EgdsCheckBox>;
}

/** Defines how checkbox state should be displayed on client. */
export enum EgdsCheckBoxState {
  CHECKED = 'CHECKED',
  INDETERMINATE = 'INDETERMINATE',
  UNCHECKED = 'UNCHECKED'
}

/**
 *
 * A text element or section of text that can be used to structure text content.
 * Examples include paragraphs, ordered/unordered lists, headings, and sections containing other text elements.
 *
 */
export type EgdsComposableTextElement = EgdsBulletedList | EgdsComposableTextElementSectionContainer | EgdsGraphicText | EgdsHeading | EgdsIconText | EgdsInlineLink | EgdsLinkList | EgdsOrderedList | EgdsParagraph | EgdsPlainText | EgdsSpannableText | EgdsStandardLink | EgdsStylizedText | EgdsTextIconList | EgdsTextListSection | EgdsTextSection | EgdsUnorderedList;

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Contains other EGDSComposableTextElements such paragraphs, ordered/unordered lists, headings, and sections containing other text elements.
 *
 */
export interface EgdsComposableTextElementContainer {
  elements: Array<EgdsComposableTextElement>;
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Groups text content and is composed of sections for the heading and body
 *
 */
export interface EgdsComposableTextElementSectionContainer extends EgdsComposableTextElementContainer, EgdsElement {
  __typename: 'EGDSComposableTextElementSectionContainer';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  elements: Array<EgdsComposableTextElement>;
  heading?: Maybe<EgdsSectionHeading>;
}

/**
 *
 *     Represents localized text consisting of multiple localized text fragments.  This is important, as grammar and
 *     syntax is different from language to language and simply concatenating two localized strings together often
 *     doesn't work.
 *     Example: `{rooms}, {travelers}`, where `rooms` is a localized string, such as `{num} rooms` and
 *     `travelers` is a localized string, such as `{num} travelers`
 *
 */
export interface EgdsCompositeLocalizedText extends EgdsElement, EgdsText {
  __typename: 'EGDSCompositeLocalizedText';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Provide a list of composite models. */
  models: Array<CompositeLocalizedTextModel>;
  /**
   *
   *         Provide a template string.  Example:  `{rooms}, {travelers}`
   *
   */
  template: Scalars['String']['output'];
  /** Provide a default value */
  text: Scalars['String']['output'];
}

/**
 *
 *     Provide datepicker configuration to create calendar for date selection.
 *
 */
export interface EgdsDatePicker {
  /**
   *
   *         Text for full-width Floating Button at bottom of date picker.
   *         The button is used for completing user's selection and closing date picker.
   *         Example: "Done"
   *
   */
  buttonText: Scalars['String']['output'];
  /**
   *
   *         Text for clear dates button.
   *         The button is used for clearing user's selection date picker.
   *         Example: "Clear dates"
   *
   */
  clearButtonText?: Maybe<Scalars['String']['output']>;
  /** Used to capture client side analytics when user clicks clear dates button. */
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  /** Provides a value format for the date fields. Example: 'yyyy-MM-dd' */
  dateFormat: Scalars['String']['output'];
  /** Content to display themed text below each date (ie. price levels). */
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  /**
   *
   *         Represents the first date of the week in the datepicker. Example: 'SUN'
   *         More details on UITK:
   *         http://uitk.expedia.biz/expedia/en_US/components/date-picker#datepicker
   *
   */
  firstDayOfWeek: EgdsDayName;
  /** Content to display flexibility modifier options relating to the date selection. (ie. '±3 days') */
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  /** Provides a text value for the footer i.e. legal disclaimer */
  footerText?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         If non-null a start date will be preselected when you open the date picker.
   *         If null no start date will be selected and startDatePlaceholderText will be shown.
   *
   */
  selectedStartDate?: Maybe<Date>;
  /** Boolean to show or hide the clear dates button. */
  showClearDatesButton?: Maybe<Scalars['Boolean']['output']>;
  /** Used to capture client side analytics when user clicks the start date button. */
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  /**
   *
   *         Shown when user has not yet selected a start date.
   *         Example: "Start date"
   *
   */
  startDatePlaceholderText: Scalars['String']['output'];
  /** Used to capture client side analytics when user submits the selected dates. */
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  /**
   *
   *         The first date where a selection is allowed.
   *         For example if you want to prohibit selecting dates in the past you can set this value to today's date.
   *
   */
  validDaysLowerBoundInclusive?: Maybe<Date>;
  /** The last date where a selection is allowed. */
  validDaysUpperBoundInclusive?: Maybe<Date>;
}

/**
 *
 *     Date picker content to display themed text below each date (ie. price levels).
 *
 */
export interface EgdsDatePickerContent {
  __typename: 'EGDSDatePickerContent';
  /** Accessibility text for screen readers. */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Date (ie. 'yyyy-MM-dd') as key value for content object. */
  dateKey: Scalars['String']['output'];
  /** Text content displayed below date. */
  text?: Maybe<Scalars['String']['output']>;
  /** Theme to stylized the text. */
  theme?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Displays flexibility calendar content
 *
 */
export interface EgdsDatePickerFlexibilityCalendarContent {
  __typename: 'EGDSDatePickerFlexibilityCalendarContent';
  /** List of flexible date selection options within three days. */
  flexibleDateOptions: Array<EgdsBasicPill>;
  /** Element unique identifier. Used for identifying selected option within a form. */
  formElementId: Scalars['String']['output'];
  /** Title for the flexibility content. Outlines the usage of the options. */
  title?: Maybe<EgdsPlainText>;
}

/**
 *
 *     "Content to display flexibility modifier options relating to the date selection to within three days."
 *
 */
export interface EgdsDatePickerFlexibilityContent {
  __typename: 'EGDSDatePickerFlexibilityContent';
  /** Used to show flexible content modifier options on the calendar section */
  calendarContent?: Maybe<EgdsDatePickerFlexibilityCalendarContent>;
  /** Element used to change between flexible calendar content (default date selector) and flexible date content (the user doesn't select a specific date, but nights and month range) */
  contentSelector?: Maybe<EgdsTabs>;
  /** Used to show date picker label based on selected flexible content selected */
  flexibilityLabel?: Maybe<EgdsCardinalLocalizedText>;
  /**
   * List of flexible date selection options within three days.
   * @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type
   */
  flexibleDateOptions: Array<EgdsBasicPill>;
  /** Used to show flexible dates content section */
  flexibleDatesContent?: Maybe<EgdsDatePickerFlexibilityDatesContent>;
  /**
   * Element unique identifier. Used for identifying selected option within a form.
   * @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type
   */
  formElementId: Scalars['String']['output'];
  /**
   * Title for the flexibility content. Outlines the usage of the options.
   * @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type
   */
  title?: Maybe<EgdsPlainText>;
}

/**
 *
 *     Displays flexibility date content
 *
 */
export interface EgdsDatePickerFlexibilityDatesContent {
  __typename: 'EGDSDatePickerFlexibilityDatesContent';
  /** Displays months section */
  monthsSelection?: Maybe<EgdsDatePickerFlexibleMonths>;
  /** Displays nights section */
  nightsSelection?: Maybe<EgdsDatePickerFlexibleNights>;
}

/**
 *
 *     Displays flexibility months content
 *
 */
export interface EgdsDatePickerFlexibleMonths {
  __typename: 'EGDSDatePickerFlexibleMonths';
  /** Static text to be shown */
  heading?: Maybe<Scalars['String']['output']>;
  /** Months options to be selected */
  options: EgdsToggleButtonGroup;
  /** Static sub text to be shown */
  subHeading: Scalars['String']['output'];
}

/**
 *
 *     Displays flexibility nights content
 *
 */
export interface EgdsDatePickerFlexibleNights {
  __typename: 'EGDSDatePickerFlexibleNights';
  /** Static text to be shown */
  heading?: Maybe<Scalars['String']['output']>;
  /** Checkbox to be checked if weekends are included or not in the search */
  includeWeekend: EgdsBasicCheckBox;
  /** Nights options to be selected */
  options: Array<EgdsBasicPill>;
}

export interface EgdsDatePickerInputField extends EgdsInputField {
  __typename: 'EGDSDatePickerInputField';
  /** Provides a value format for the date fields */
  dateFormat?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface EgdsDateRangePicker extends EgdsDatePicker {
  __typename: 'EGDSDateRangePicker';
  buttonText: Scalars['String']['output'];
  /**
   *
   *         Used to dynamically update the button text in the client, based on user interaction with the input date picker."
   *
   */
  buttonTextTemplate?: Maybe<EgdsLocalizedText>;
  clearButtonText?: Maybe<Scalars['String']['output']>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars['String']['output'];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  /** Provides format for date range value. Example: '${startDate} - ${endDate}'. */
  dateRangeFormat?: Maybe<EgdsBasicLocalizedText>;
  /** Used to capture client side analytics when user clicks the end date button. */
  endDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  /**
   *
   *         Shown when user has not yet selected an end date.
   *         Example: "End date"
   *
   */
  endDatePlaceholderText: Scalars['String']['output'];
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         If non-null the maximum number of days that a date range selection can contain.
   *         Example use case: A hotel stay can be no longer than <maxNumberOfDaysSelected> days.
   *
   */
  maxNumberOfDaysSelected?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *             Used to capture client side analytics when user clicks next month in calendar.
   *
   */
  nextButtonAnalytics?: Maybe<ClientSideAnalytics>;
  /**
   *
   *             Used to capture client side analytics when user clicks previous month in calendar.
   *
   */
  previousButtonAnalytics?: Maybe<ClientSideAnalytics>;
  /**
   *
   *             If true start and end date can be the same.
   *             If false start and end date must be different.
   *             Example use case: False for hotels when overnight stays required.
   *             Example use case: True for flights that allow departure and arrival date to be the same.
   *
   */
  sameDaySelectionAllowed: Scalars['Boolean']['output'];
  /**
   *
   *         If non-null an end date will be preselected when date picker is opened.
   *         If null no end date will be selected and endDatePlaceholderText will be shown.
   *
   */
  selectedEndDate?: Maybe<Date>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars['Boolean']['output']>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars['String']['output'];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
}

/** Represents short day name. */
export enum EgdsDayName {
  FRI = 'FRI',
  MON = 'MON',
  SAT = 'SAT',
  SUN = 'SUN',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED'
}

/** Represents the type of decorative in the text element. e.g. superscript */
export enum EgdsDecorativeText {
  /** Renders with a background color to highlight the text */
  HIGHLIGHT = 'HIGHLIGHT',
  /** Renders with a line through the text */
  LINE_THROUGH = 'LINE_THROUGH',
  /** Renders as a subscript at the bottom of the text line */
  SUBSCRIPT = 'SUBSCRIPT',
  /** Renders as a superscript at the top of the text line */
  SUPERSCRIPT = 'SUPERSCRIPT',
  /** Renders with a line under the text */
  UNDERLINE = 'UNDERLINE'
}

/**
 *
 *     Do not implement this interface in service. Use one of the concrete types instead.
 *     https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
 *
 *     Dialogs are containers that content can be rendered in. Clients should orchestrate how content
 *     will render within the dialog.
 *
 *     https://www.figma.com/file/KqOeuNsfHUNAgd4johSxsh/Dialog
 *
 */
export interface EgdsDialog {
  closeAnalytics?: Maybe<ClientSideAnalytics>;
}

/**
 *
 *     Try to not return EGDSButton interface itself because a client won't need all of its implementations.
 *     https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
 *
 *     Dialog footers provide one or more buttons for users to take action upon
 *
 */
export interface EgdsDialogFooter {
  buttons: Array<EgdsButton>;
}

/**
 *
 *     Dialog spannable toolbars provide a title for the dialog and optional accessibility text for a dialog's close icon, navigation type and action
 *
 */
export interface EgdsDialogSpannableToolbar {
  __typename: 'EGDSDialogSpannableToolbar';
  actions?: Maybe<Array<EgdsToolbarActionItem>>;
  closeText?: Maybe<Scalars['String']['output']>;
  navigationType?: Maybe<NavigationType>;
  title: Scalars['String']['output'];
}

/**
 *
 *     Dialog toolbars provide a title for the dialog and optional accessibility text for a dialog's close icon
 *
 */
export interface EgdsDialogToolbar {
  __typename: 'EGDSDialogToolbar';
  closeText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

/**
 *
 * Represents an action to dismiss the component
 *
 */
export interface EgdsDismissAction {
  __typename: 'EGDSDismissAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars['String']['output']>;
}

/**
 *
 * DO NOT USE OR IMPLEMENT. You can return a id field from service.
 * https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union/#avoid-overly-generic-types-in-egds-shared-library
 *
 * UI Element that is compliant with EGDS Component designs.
 * It is intended to be implemented by UIComposableElement, a union for shared EGDS Component models,
 * and candidates for UIComposableElement that are initially limited to an individual spinner's scope
 * to establish viability before being shared with other spinners as a member of UIComposableElement
 *
 */
export interface EgdsElement {
  /**
   *
   *     Element unique identifier. Uses include handling diffs for UI updates
   *     and identifying the source of data for user input fields
   *
   */
  egdsElementId?: Maybe<Scalars['String']['output']>;
}

/**
 *
 * DO NOT USE OR IMPLEMENT.
 * https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union/#avoid-overly-generic-types-in-egds-shared-library
 *
 * Implementations may include UI structures, layout templates, or view hierarchies.
 *
 */
export interface EgdsElements {
  elements: Array<EgdsElement>;
}

/** Represents an email input filed element with autofill property */
export interface EgdsEmailInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSEmailInputField';
  /** Autofill hint for the input value */
  autofill?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *      Display summarized view of error when user has submitted the form with error in it.
 *
 */
export interface EgdsErrorSummary extends EgdsElement {
  __typename: 'EGDSErrorSummary';
  /** Information for capturing client side analytics event for error link clicks */
  analytics?: Maybe<ClientSideAnalytics>;
  /** Description of error message below the heading */
  body?: Maybe<EgdsText>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Display link text when there's one error */
  goToErrorLinkText?: Maybe<Scalars['String']['output']>;
  /** Display link text when there's more than one error */
  goToFirstErrorLinkText?: Maybe<Scalars['String']['output']>;
  /** Represents main error message */
  heading: Scalars['String']['output'];
  /** Display text with specified number of errors */
  headingTemplate?: Maybe<EgdsLocalizedText>;
  /** Information for capturing client side impression analytics event for error summary */
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
}

/**
 *
 *     Representation of an item in an EGDS expando
 *
 *     https://www.figma.com/file/IDIEwURk3g9LQmSHdTjj0R/Expandos
 *
 */
export interface EgdsExpando {
  /** Analytics for when the expando is selected to collapse */
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  /** Button label when the expando is collapsed */
  collapsedLabel: Scalars['String']['output'];
  /** Analytics for when the expando is selected to expand */
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  /** Flag to indicate if the component is to be expanded by default */
  expanded: Scalars['Boolean']['output'];
  /** Button label when the expando is expanded */
  expandedLabel: Scalars['String']['output'];
}

/**
 *
 *     Representation of an expando card
 *
 *     https://www.figma.com/file/IDIEwURk3g9LQmSHdTjj0R/Expandos
 *
 */
export interface EgdsExpandoCard extends EgdsExpando {
  __typename: 'EGDSExpandoCard';
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars['String']['output'];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars['Boolean']['output'];
  expandedLabel: Scalars['String']['output'];
  /** The text shown below the title */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** the position of the trigger icon to expand/collapse */
  triggerPosition?: Maybe<ExpandoTriggerPosition>;
}

/**
 *
 *     Representation of an expando link
 *
 *     https://www.figma.com/file/IDIEwURk3g9LQmSHdTjj0R/Expandos
 *
 */
export interface EgdsExpandoLink extends EgdsExpando {
  __typename: 'EGDSExpandoLink';
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  /** Button accessibility when the expando is collapsed */
  collapsedAccessibilityText?: Maybe<Scalars['String']['output']>;
  collapsedLabel: Scalars['String']['output'];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars['Boolean']['output'];
  /** Button accessibility when the expando is expanded */
  expandedAccessibilityText?: Maybe<Scalars['String']['output']>;
  expandedLabel: Scalars['String']['output'];
}

/**
 *
 *     Representation of an item in an expando list
 *
 *     https://www.figma.com/file/IDIEwURk3g9LQmSHdTjj0R/Expandos
 *
 */
export interface EgdsExpandoListItem extends EgdsExpando {
  __typename: 'EGDSExpandoListItem';
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars['String']['output'];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars['Boolean']['output'];
  expandedLabel: Scalars['String']['output'];
  /** the position of the trigger icon to expand/collapse */
  triggerPosition?: Maybe<ExpandoTriggerPosition>;
}

/**
 *
 *     Representation of an expando peek
 *
 *     https://www.figma.com/file/IDIEwURk3g9LQmSHdTjj0R/Expandos
 *
 */
export interface EgdsExpandoPeek extends EgdsExpando {
  __typename: 'EGDSExpandoPeek';
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  /** Button accessibility when the expando is collapsed */
  collapsedAccessibilityText?: Maybe<Scalars['String']['output']>;
  collapsedLabel: Scalars['String']['output'];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars['Boolean']['output'];
  /** Button accessibility when the expando is expanded */
  expandedAccessibilityText?: Maybe<Scalars['String']['output']>;
  expandedLabel: Scalars['String']['output'];
  /**
   *
   *       How many items (list, radio group etc) to show in the visible part of expando peek.
   *       Note: The composition of any item(s) can only be a maximum of one level deep i.e. parent > children or <>children
   *
   */
  items?: Maybe<Scalars['Int']['output']>;
  /** How many lines of text to show in the visible part of expando peek */
  lines?: Maybe<Scalars['Int']['output']>;
  /**
   * The height of the expando peek in its collapsed state
   * @deprecated Removed after EGDS graduated of Expando. Replace with `lines` or `items`.
   */
  minimalHeight?: Maybe<Scalars['Int']['output']>;
  /**
   * Height of the content must be greater than for the Expando Peek to become active
   * @deprecated Removed after EGDS graduated of Expando.
   */
  threshold?: Maybe<Scalars['Int']['output']>;
}

/**
 *
 *     Action dialogs are containers that content can be rendered in. Clients should orchestrate how content
 *     will render within the dialog. Full screen dialogs can be used for more complex or dynamic content.
 *     They include a toolbar with a close icon and dialog title.
 *
 *     https://www.figma.com/file/XOdo8BowxO8VACXdesonOZ/Full-screen-dialogs?node-id=0%3A1
 *
 */
export interface EgdsFullScreenDialog extends EgdsDialog {
  __typename: 'EGDSFullScreenDialog';
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  toolbar: EgdsDialogToolbar;
}

/** Represents a EGDS graphic menu */
export interface EgdsGraphicMenu extends EgdsMenu {
  __typename: 'EGDSGraphicMenu';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  /** Represents the graphics used in the menu */
  graphic: UiGraphic;
  subMenus: Array<EgdsSubMenu>;
  title: Scalars['String']['output'];
}

/** Represents a text a graphic element, icon or mark or ilustration */
export interface EgdsGraphicText extends EgdsText {
  __typename: 'EGDSGraphicText';
  /** Use this field when accessibility text needs to be different from text */
  accessibility?: Maybe<Scalars['String']['output']>;
  graphic: UiGraphic;
  text: Scalars['String']['output'];
  /** Represents a list of trailing graphics to be rendered after the text */
  trailingGraphics?: Maybe<Array<UiGraphic>>;
}

/**
 *
 *     Most use cases should return `String` instead of `EGDSHeading` for a heading field,
 *     because without following deprecated fields, it pretty much same as return a string.
 *
 *     https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography
 *
 */
export interface EgdsHeading extends EgdsElement, EgdsText {
  __typename: 'EGDSHeading';
  /** Use this field when accessibility text needs to be different from text */
  accessibility?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /**
   * Advanced feature. Only need for specific uses. See EGDSHeadingStyle description
   * @deprecated DO NOT USE. Client should handle heading tags.
   */
  headingType?: Maybe<EgdsHeadingStyle>;
  text: Scalars['String']['output'];
}

/**
 *
 *     DO NOT USE. Mobile doesn't support these html style. Client should handle heading tags.
 *
 *     https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography
 *
 */
export enum EgdsHeadingStyle {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  H7 = 'H7',
  H8 = 'H8'
}

/** Represents where the icon should be places in a EGDS Standard Link */
export enum EgdsIconPosition {
  LEADING = 'LEADING',
  TRAILING = 'TRAILING'
}

/**
 *
 *    Represents icon and text
 *
 */
export interface EgdsIconText extends EgdsText {
  __typename: 'EGDSIconText';
  icon: Icon;
  text: Scalars['String']['output'];
}

/**
 *
 *      Represents card with an image and text.
 *      figma: https://www.figma.com/file/M3udFrUojCRwrcoAiPnQ3Yaw/Card?node-id=474%3A114
 *
 */
export interface EgdsImageCard {
  __typename: 'EGDSImageCard';
  /** Description to display on the card, e.g. description of a point of interest */
  description?: Maybe<Scalars['String']['output']>;
  /** id used to match a card with a corresponding property/place id of a map feature */
  id?: Maybe<Scalars['String']['output']>;
  /** Image to display on the card */
  image?: Maybe<Image>;
  /** Subtitle to display on the card, e.g. distance from property */
  subTitle?: Maybe<Scalars['String']['output']>;
  /** Title to display on the card, e.g. property name, point of interest */
  title?: Maybe<Scalars['String']['output']>;
}

/** Represents an EGDS Image Link */
export interface EgdsImageLink extends EgdsElement {
  __typename: 'EGDSImageLink';
  /** Represents the action to perform when the image is selected */
  action: UiLinkAction;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Display image that when selected performs an action */
  image: Image;
}

/**
 *
 *      Represents card with Image, text and link.
 *      figma: https://www.figma.com/file/M3udFrUojCRwrcoAiPnQ3Yaw/Card?node-id=474%3A114
 *
 */
export interface EgdsImageLinkCard extends EgdsElement {
  __typename: 'EGDSImageLinkCard';
  description?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  link: EgdsImageLink;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

/** Option for selecting an infant seat on a plane. */
export enum EgdsInfantSeat {
  IN_SEAT = 'IN_SEAT',
  ON_LAP = 'ON_LAP'
}

/** Radio button for selecting an infant seat on a plane. */
export interface EgdsInfantSeatRadioButton extends EgdsElement, EgdsRadioButton {
  __typename: 'EGDSInfantSeatRadioButton';
  description?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelSuffix?: Maybe<Scalars['String']['output']>;
  seatType: EgdsInfantSeat;
  value: Scalars['String']['output'];
}

/**
 *
 *     Try to not return EGDSButton interface itself because a client won't need all of its implementations.
 *     https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
 *
 *     Inline dialog footers provide one or more buttons displayed in an inline orientation for users to respond to the dialog
 *
 */
export interface EgdsInlineDialogFooter extends EgdsDialogFooter {
  __typename: 'EGDSInlineDialogFooter';
  buttons: Array<EgdsButton>;
}

/**
 *
 *    Represents an EGDS Inline Link
 *
 */
export interface EgdsInlineLink extends EgdsText {
  __typename: 'EGDSInlineLink';
  action: UiLinkAction;
  /** Display disabled link state. */
  disabled: Scalars['Boolean']['output'];
  /** Display size of the link. */
  size: EgdsLinkSize;
  text: Scalars['String']['output'];
}

/** Allows users to enter text-based information of various kinds such as destinations, credit cards, passwords, phones, etc */
export interface EgdsInputField {
  /** Text to be displayed as error text when the input field is in an invalid state. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         Used to dynamically update the label text in the client, based on user interaction with the input.
   *
   */
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Field is in a read-only state displaying only text when readOnly is true. */
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  /**
   *
   *         Validations with corresponding error messages to display if the input is invalid.
   *         The order in which the validations appears indicates the order of priority of which error message to display.
   *
   */
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/** Used with input fields to specify validations that are attached to it */
export interface EgdsInputValidation {
  /**
   *
   *         The content of the error message may depend on the input or other factors,
   *         in which case implementations may need to add additional field/s
   *         or the structure the response in a way that allows for the appropriate message,
   *         and this would serve as the default error message.
   *
   */
  errorMessage: Scalars['String']['output'];
}

/** Represents a list of EGDS Standard Links */
export interface EgdsLinkList extends EgdsElement {
  __typename: 'EGDSLinkList';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  listItems: Array<EgdsStandardLink>;
}

/** Represents the size of the text in a EGDS Standard Link */
export enum EgdsLinkSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL'
}

/** DEPRECATED: This interface allows for noncompliance to EGDS. */
export interface EgdsList {
  /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
  items: Array<EgdsText>;
}

/**
 *
 *     Loader dialogs are containers that content can be rendered in. Clients should orchestrate how content
 *     will render within the dialog. Loader dialogs open when content is actively loading and content
 *     needs to communicate some interstitial content.
 *
 *     https://www.figma.com/file/KqOeuNsfHUNAgd4johSxsh/Dialog
 *
 */
export interface EgdsLoaderDialog extends EgdsDialog {
  __typename: 'EGDSLoaderDialog';
  closeAnalytics?: Maybe<ClientSideAnalytics>;
}

/**
 *
 *    Represents a localization template for text.
 *    It is required when client side interpolation has placeholder values: Example: `Room {roomIndex}`
 *    In above example value of roomIndex will replaced by clients.
 *
 */
export type EgdsLocalizedText = EgdsBasicLocalizedText | EgdsCardinalLocalizedText | EgdsCompositeLocalizedText;

/**
 *
 *    Represents a localization template for text.
 *    It is required when client side interpolation has placeholder values: Example: 'Room {roomIndex}'
 *    In above example value of roomIndex will replaced by clients.
 *
 */
export interface EgdsLocalizedTextTemplate extends EgdsElement, EgdsText {
  __typename: 'EGDSLocalizedTextTemplate';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Localized string with tokens interpolated. Example: 'Room {roomIndex}' */
  template: Scalars['String']['output'];
  /** Provide default localized string. Example: 'Room 1' */
  text: Scalars['String']['output'];
  /** Replaceable placeholder. Example:in 'Room {roomIndex}' the 'roomIndex' is token. Clients should know the token in advance. */
  token: Scalars['String']['output'];
}

/**
 *
 *     Represents a loyalty badge
 *
 *     https://www.figma.com/file/aYQZVH6x5kqAGj8LbLzTVWfX/Badges
 *
 */
export interface EgdsLoyaltyBadge extends EgdsBadge, EgdsElement {
  __typename: 'EGDSLoyaltyBadge';
  accessibility?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  graphic?: Maybe<UiGraphic>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  size?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *      Represents card with a Mark and text.
 *      figma: https://www.figma.com/file/M3udFrUojCRwrcoAiPnQ3Yaw/Card?node-id=474%3A114
 *
 */
export interface EgdsMarkCard {
  __typename: 'EGDSMarkCard';
  /** Description to display on the card, e.g. description of a point of interest */
  description?: Maybe<Scalars['String']['output']>;
  /** id used to match a card with a corresponding property/place id of a map feature */
  id?: Maybe<Scalars['String']['output']>;
  /** Image to display on the card */
  mark?: Maybe<Mark>;
  /** Subtitle to display on the card, e.g. distance from property */
  subTitle?: Maybe<Scalars['String']['output']>;
  /** Title to display on the card, e.g. property name, point of interest */
  title?: Maybe<Scalars['String']['output']>;
}

/** Specifies the maximum length that an input needs. For datepicker it can be use to validate maximum number of days. */
export interface EgdsMaxLengthInputValidation extends EgdsInputValidation {
  __typename: 'EGDSMaxLengthInputValidation';
  errorMessage: Scalars['String']['output'];
  maxLength: Scalars['Int']['output'];
}

/**
 *
 *     EGDSCarouselItem implementation to support media carousel cards
 *
 */
export interface EgdsMediaCarouselItem extends EgdsCarouselItem {
  __typename: 'EGDSMediaCarouselItem';
  id?: Maybe<Scalars['String']['output']>;
  media: MediaItem;
}

/**
 *
 *     Representation of a EGDS menu
 *
 *     https://www.figma.com/file/cwNPDX6N1XTWCBQTp2PQ0t/Menus?node-id=746%3A152
 *
 *     UITK component defined at http://uitk.expedia.biz/expedia/en_US/components/menu"
 *
 */
export interface EgdsMenu {
  /** Represents the accessibility text for the menu */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Used to capture client side analytics */
  analytics?: Maybe<ClientSideAnalytics>;
  /** User cannot interact with the menu when the disabled value is true. */
  disabled: Scalars['Boolean']['output'];
  /** Represents the menu container */
  subMenus: Array<EgdsSubMenu>;
  /** Represents the title of the menu */
  title: Scalars['String']['output'];
}

/** Represents a EGDS Menu icon items */
export interface EgdsMenuListIconTextItem extends EgdsMenuListItem {
  __typename: 'EGDSMenuListIconTextItem';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<ClientSideAnalytics>;
  /** Represents the graphics used in the menu list */
  graphic: UiGraphic;
  /** Represents the analytics linked with graphics */
  graphicAnalytics?: Maybe<ClientSideAnalytics>;
  title: Scalars['String']['output'];
}

/** Represents EGDS menu list item */
export interface EgdsMenuListItem {
  /** Represents the accessibility text for menu list item */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Used to capture client side analytics */
  analytics?: Maybe<ClientSideAnalytics>;
  /** Represents the menu list item title */
  title: Scalars['String']['output'];
}

/** Represents an EGDS Menu standard item */
export interface EgdsMenuStandardItem extends EgdsMenuListItem {
  __typename: 'EGDSMenuStandardItem';
  accessibility?: Maybe<Scalars['String']['output']>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title: Scalars['String']['output'];
}

/** Represents a EGDS Menu text items */
export interface EgdsMenuTextItem extends EgdsMenuListItem {
  __typename: 'EGDSMenuTextItem';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<ClientSideAnalytics>;
  title: Scalars['String']['output'];
}

/** Specifies the minimum length that an input needs */
export interface EgdsMinLengthInputValidation extends EgdsInputValidation {
  __typename: 'EGDSMinLengthInputValidation';
  errorMessage: Scalars['String']['output'];
  minLength: Scalars['Int']['output'];
}

/**
 *
 *     A clickable trigger used to provide users with more information about the content.
 *
 *     https://www.figma.com/file/NnkLJxeR09g00BCDZj6JoW/More-info-trigger?type=design&node-id=0-1&mode=design&t=864RRQJb1Ha5Emdb-0
 *
 */
export interface EgdsMoreInfoTrigger {
  __typename: 'EGDSMoreInfoTrigger';
  /** The accessibility text for the info */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** The analytics for the info */
  analytics?: Maybe<ClientSideAnalytics>;
  /** Icon to display next to the text */
  icon: Icon;
  /** The text to display for the info */
  text: Scalars['String']['output'];
  /** Trigger action related to the text property */
  triggerAction: EgdsMoreInfoTriggerAction;
}

/**
 *
 *     Trigger action related to the text property in the more info trigger
 *
 */
export interface EgdsMoreInfoTriggerAction {
  __typename: 'EGDSMoreInfoTriggerAction';
  /** The accessibility text for the action */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** The analytics for the action */
  analytics?: Maybe<ClientSideAnalytics>;
  /** The sheet to show for the action */
  sheet: EgdsSheet;
  /** Text to show inside the sheet */
  text: Scalars['String']['output'];
}

/** EGDS Nested list item with icon level one */
export interface EgdsNestedLevelOneIconListItem extends EgdsNestedLevelOneListItem {
  __typename: 'EGDSNestedLevelOneIconListItem';
  icon: Icon;
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars['String']['output'];
}

/**
 *
 *         EGDS compliant nested list level one https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=11931%3A11857
 *         Right now we are just adding level one as we don't have a use-case for further level, but we want to keep the schema extensible.
 *
 */
export interface EgdsNestedLevelOneList {
  __typename: 'EGDSNestedLevelOneList';
  /** List of item within nested list (level one) */
  items: Array<EgdsNestedLevelOneListItem>;
  /**
   *
   *             Represents the size property of the EGDS List, As per figma the default size is LIST_2
   *             figma: "https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=25%3A0"
   *
   */
  size: EgdsTextListSize;
  /**
   *
   *             Represents the UI variation of the EGDS List
   *             eg. BULLET, NO_BULLET, NUMBERED, ICON
   *             figma: "https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=25%3A0"
   *
   */
  type: EgdsTextListType;
}

/**
 *
 *         Nested list item (level one) with EGDS styles.
 *         figma: https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=11931%3A11857")
 *
 */
export interface EgdsNestedLevelOneListItem {
  /**
   *
   *          Represents the text style of EGDS List Item (Level one)
   *          eg. EMPHASIS, POSITIVE, NEGATIVE etc
   *
   */
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars['String']['output'];
}

/** EGDS Nested list item level one */
export interface EgdsNestedLevelOneStandardListItem extends EgdsNestedLevelOneListItem {
  __typename: 'EGDSNestedLevelOneStandardListItem';
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars['String']['output'];
}

/** Represents a number input element */
export interface EgdsNumberInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSNumberInputField';
  /**
   *
   *     Element unique identifier. Uses include handling diffs for UI updates
   *     and identifying the source of data for user input fields.
   *
   */
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Text to be displayed as error text when the input field is in an invalid state. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Detailed instructions to the user on how to use the input element. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Text for the label element. */
  label?: Maybe<Scalars['String']['output']>;
  /**
   *
   *     Used to dynamically update the label text in the client, based on user interaction with the input.
   *
   */
  labelTemplate?: Maybe<EgdsLocalizedText>;
  /** Icon to be positioned to the left side of the input element. */
  leftIcon?: Maybe<Icon>;
  /** Maximum value that can be entered for the input. */
  max?: Maybe<Scalars['Float']['output']>;
  /** Minimum value that can be entered for the input. */
  min?: Maybe<Scalars['Float']['output']>;
  /** Placeholder text for the input element. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Determines if the input element should be readonly. */
  readOnly: Scalars['Boolean']['output'];
  /**
   *
   *     Informs the user if the field is mandatory. Sets the aria-required label for the field.
   *
   */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** Icon to be positioned to the right side of the input element. */
  rightIcon?: Maybe<Icon>;
  /** Interval number for the number input element. */
  step?: Maybe<Scalars['Float']['output']>;
  /**
   *
   *     Validations with corresponding error messages to display if the input is invalid.
   *     The order in which the validations appears indicates the order of priority of which error message to display.
   *
   */
  validations?: Maybe<Array<EgdsInputValidation>>;
  /** Value for the input element. */
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Action for opening date picker modal.
 *     The will work for native apps but not for UITK DatePicker because UITK couples the date picker with date input fields.
 *     For UITK DatePicker use EGDSCompositeDatePicker.
 *
 */
export interface EgdsOpenDatePickerAction extends UiAction {
  __typename: 'EGDSOpenDatePickerAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
  /** Date picker to open. */
  datePicker: EgdsDatePicker;
}

/**
 *
 *     Action for opening traveler selector.
 *     figma:
 *     https://www.figma.com/file/y59aHUdrzsZBTg5RfbaZ9h/Traveler-selector?node-id=67%3A51
 *
 */
export interface EgdsOpenTravelerSelectorAction extends UiAction {
  __typename: 'EGDSOpenTravelerSelectorAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
  /** Traveler selector configuration. */
  travelerSelector: EgdsTravelerSelector;
}

/**
 *
 *     Used to define an item contained in a html <select> element.
 *
 */
export interface EgdsOption {
  /** The text visible on screen. */
  label: Scalars['String']['output'];
  /** Default selection for value. */
  selected?: Maybe<Scalars['Boolean']['output']>;
  /** It is associated with label. It corresponds to html option tag value attribute. */
  value: Scalars['String']['output'];
}

/** Ordered variant of EGDS typography list */
export interface EgdsOrderedList extends EgdsElement, EgdsList, EgdsTextList {
  __typename: 'EGDSOrderedList';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
  items: Array<EgdsText>;
  listItems: Array<EgdsTextListItem>;
  size?: Maybe<EgdsTextListSize>;
}

/** Use this button to let users take an alternative, supplemental, or less important action for an overlay button style. */
export interface EgdsOverlayButton extends EgdsButton, EgdsElement {
  __typename: 'EGDSOverlayButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** Used to represent a paragraph of text with different EGDS typography styles. */
export interface EgdsParagraph extends EgdsElement, EgdsText {
  __typename: 'EGDSParagraph';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** @deprecated DO NOT USE. Client should handle styling size. */
  size: Scalars['Int']['output'];
  /** Advanced feature. Only need for specific uses. See EGDSParagraphStyle description */
  style: EgdsParagraphStyle;
  text: Scalars['String']['output'];
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use String over EGDSParagraph or EGDSText.
 *
 *     https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography
 *
 */
export enum EgdsParagraphStyle {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3'
}

/** Represents a password input filed element with autofill property */
export interface EgdsPasswordInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSPasswordInputField';
  /** Autofill hint for the input value */
  autofill?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  /** Determines if the input value is visible or obscured */
  passwordVisible?: Maybe<Scalars['Boolean']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/** Possible camera movement for the photosphere image */
export enum EgdsPhotosphereCameraMovement {
  AUTO_PAN = 'AUTO_PAN',
  DISABLED = 'DISABLED',
  FULL_MOVE = 'FULL_MOVE'
}

/**
 *
 *     Representation of a photosphere image (360 image).
 *     A photosphere allows users to view and interact with 360-degree images.
 *
 *     https://www.figma.com/file/Qv3T2R07UaS4NwSLonHpE3/Photosphere-%5BBETA%5D%5BWIP%5D
 *
 */
export interface EgdsPhotosphereImage {
  __typename: 'EGDSPhotosphereImage';
  /** @deprecated Use description instead */
  altText?: Maybe<Scalars['String']['output']>;
  /** Aspect Ratio of the image */
  aspectRatio?: Maybe<AspectRatio>;
  /** Allow movement if is using photosphere (default true) */
  cameraMovement?: Maybe<EgdsPhotosphereCameraMovement>;
  /** The localized text describing the photosphere image */
  description: Scalars['String']['output'];
  /** Thumbnail image click analytics */
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars['URL']['output'];
}


/**
 *
 *     Representation of a photosphere image (360 image).
 *     A photosphere allows users to view and interact with 360-degree images.
 *
 *     https://www.figma.com/file/Qv3T2R07UaS4NwSLonHpE3/Photosphere-%5BBETA%5D%5BWIP%5D
 *
 */
export interface EgdsPhotosphereImageUrlArgs {
  dimensions?: InputMaybe<DimensionsInput>;
}

/**
 *
 *     Use to toggle on one or more categories (e.g. Beach, Family),
 *     quick-select an option (e.g. quick response in Chat virtual assistant),
 *     or trigger a selection feature (e.g. Datepicker).
 *
 */
export interface EgdsPill {
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Denotes whether pill is in disabled state */
  disabled: Scalars['Boolean']['output'];
  /** Used to denote informative icon */
  icon?: Maybe<UiGraphic>;
  /** Used to uniquely identify a pill */
  id?: Maybe<Scalars['String']['output']>;
  /** Used to display text on pill */
  primary?: Maybe<Scalars['String']['output']>;
  /** Denotes whether pill is in selected state */
  selected: Scalars['Boolean']['output'];
}

/**
 *
 *    Represents Simple plain text
 *
 */
export interface EgdsPlainText extends EgdsText {
  __typename: 'EGDSPlainText';
  /** accessibility text */
  accessibility?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
}

/**
 *
 *     Represents a program badge
 *
 *     https://www.figma.com/file/aYQZVH6x5kqAGj8LbLzTVWfX/Badges
 *
 */
export interface EgdsProgramBadge extends EgdsBadge, EgdsElement {
  __typename: 'EGDSProgramBadge';
  accessibility?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  graphic?: Maybe<UiGraphic>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  size?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents individual radio button. Radio buttons are allow one to be selected out of all of them.
 *
 */
export interface EgdsRadioButton {
  /** Represents the description for the radio button. */
  description?: Maybe<Scalars['String']['output']>;
  /** User cannot interact with the radio when the disabled value is true. */
  disabled: Scalars['Boolean']['output'];
  /** Represents the label description of radio button. Example: 'Label 1', 'Label 2' */
  label: Scalars['String']['output'];
  /** Represents the secondary label of radio button. Example when price associated with radio: '$999' */
  labelSuffix?: Maybe<Scalars['String']['output']>;
  /** Represents the value of the radio button. */
  value: Scalars['String']['output'];
}

/**
 *
 *     Represents collections of radio buttons describing a set of related options.
 *     UITK react:
 *     https://uitk.expedia.biz/expedia/en_US/components/radio-button
 *     Figma:
 *     https://www.figma.com/file/UNXRJ8MDLJjvu7ENZCMhke/Radio-buttons
 *
 */
export interface EgdsRadioGroup {
  /** Represents radio group error message. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Represents the name for the radio group. It will be used as the name for each child radio component. */
  groupName: Scalars['String']['output'];
  /** Represents the heading label of the radio group. */
  label?: Maybe<Scalars['String']['output']>;
  /** Represents options for radio group. */
  options: Array<EgdsRadioButton>;
  /** Represents default selected radio child by passing in its value. */
  selected?: Maybe<Scalars['String']['output']>;
}

/** Specifies that an input has range of input value */
export interface EgdsRangeInputValidation extends EgdsInputValidation {
  __typename: 'EGDSRangeInputValidation';
  errorMessage: Scalars['String']['output'];
  /** Maximum value for the input */
  max: Scalars['Int']['output'];
  /** Minimum value for the input */
  min: Scalars['Int']['output'];
}

/** Specifies a Regex pattern that an input must adhere to */
export interface EgdsRegexInputValidation extends EgdsInputValidation {
  __typename: 'EGDSRegexInputValidation';
  errorMessage: Scalars['String']['output'];
  pattern: Scalars['String']['output'];
}

/** Specifies that an input is required */
export interface EgdsRequiredInputValidation extends EgdsInputValidation {
  __typename: 'EGDSRequiredInputValidation';
  errorMessage: Scalars['String']['output'];
}

/**
 *
 *    Represents implementation of EGDSTravelerSelector which will be used for lodging and packages when selecting room configurations.
 *
 */
export interface EgdsRoomsTravelerSelector extends EgdsElement, EgdsTravelerSelector {
  __typename: 'EGDSRoomsTravelerSelector';
  /** Button to add another room to select travelers for that room. */
  addAnotherRoomButton: EgdsButton;
  closeButton: EgdsButton;
  /** Indicates the done button on the traveler selector. */
  doneButton: EgdsButton;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /**
   * Represents the infants seat selection module with heading, radio buttons and airline age rule
   * @deprecated Use infantsOnLap and infantsInSeat instead.
   */
  infantSeatSelection?: Maybe<EgdsTravelerInfantSeatSelection>;
  /** Max number of rooms allowed. */
  maxRoomCount?: Maybe<Scalars['Int']['output']>;
  /** Represents list of room configurations for travelers. */
  rooms: Array<EgdsTravelerSelectorRoom>;
  title: Scalars['String']['output'];
  /**
   *
   *         Validations with corresponding error messages to display if the input is invalid.
   *         The order in which the validations appears indicates the order of priority of which error message to display.
   *
   */
  validations?: Maybe<Array<EgdsInputValidation>>;
}

/** Specifies that two inputs can't have the same value */
export interface EgdsSameValueInputValidation extends EgdsInputValidation {
  __typename: 'EGDSSameValueInputValidation';
  errorMessage: Scalars['String']['output'];
}

/**
 *
 *  Action in response to user interaction on search form or search form widget.
 *  Examples include opening up a date picker, launching Typeahead for destination search, and opening Traveler Selector
 *
 */
export type EgdsSearchFormAction = EgdsOpenDatePickerAction | EgdsOpenTravelerSelectorAction | EgdsSearchFormSelectAction | EgdsShowSearchFormAction | EgdsSubmitSearchFormAction | OpenDurationSpanAction | OpenTypeaheadAction;

/** Button found in search form that has a search form specific action once clicked */
export interface EgdsSearchFormButton extends EgdsButton, EgdsElement {
  __typename: 'EGDSSearchFormButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  /** Action to perform when clicked such as submitting the search form. */
  onClick?: Maybe<EgdsSearchFormAction>;
  primary?: Maybe<Scalars['String']['output']>;
  style?: Maybe<EgdsButtonStyle>;
}

/**
 *
 *         Dates field on search form or search form widget for entering a start and end dates
 *
 *         Figma: https://www.figma.com/file/oYtPus1JugVGjib58w8Jkj/E2E-search?node-id=6434%3A270153
 *
 */
export interface EgdsSearchFormDatePickerField extends EgdsElement, EgdsInputField, EgdsSearchFormField {
  __typename: 'EGDSSearchFormDatePickerField';
  action?: Maybe<EgdsSearchFormAction>;
  egdsElementId: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *         UI Element used to create and structure a search form
 *         Examples include input fields for selecting date, destination, or travelers, pill groups for selecting LOBs, and containers for organizing the search form into sections
 *
 */
export interface EgdsSearchFormField {
  /** Informs behavior in response to user click */
  action?: Maybe<EgdsSearchFormAction>;
}

/**
 *
 *         Input field on search form or search form widget for entering information such as date, destination, or travelers.
 *
 */
export interface EgdsSearchFormInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSSearchFormInputField';
  /** Informs behavior in response to user click. */
  action?: Maybe<EgdsSearchFormAction>;
  egdsElementId: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *         Location field on search form or search form widget for entering an origin or destination.
 *
 *         Figma: https://www.figma.com/file/oYtPus1JugVGjib58w8Jkj/E2E-search?node-id=3769%3A204213
 *
 */
export interface EgdsSearchFormLocationField extends EgdsElement, EgdsInputField, EgdsSearchFormField {
  __typename: 'EGDSSearchFormLocationField';
  /** Informs behavior in response to user click on open action */
  action?: Maybe<EgdsSearchFormAction>;
  /** accessibility string in response to user change action */
  changeAccessibility?: Maybe<Scalars['String']['output']>;
  /** Informs behavior in response to user change action */
  changeAnalytics?: Maybe<ClientSideAnalytics>;
  /** accessibility string in response to user click on close action */
  closeAccessibility?: Maybe<Scalars['String']['output']>;
  /** Informs behavior in response to user click on close action */
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  /** Debounce rate in milliseconds, it will debounce the typeahead api calls when greater than 0 */
  debounceRate?: Maybe<Scalars['Int']['output']>;
  egdsElementId: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  /** @deprecated Use multiLocations */
  locations?: Maybe<Array<Scalars['String']['output']>>;
  /** Multiple locations in case multi-selection field is enabled (typeahead). Commonly used for multiple destinations. */
  multiLocations?: Maybe<Array<EgdsSearchFormSelectedLocation>>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  /** Used to tell what regionId is being used for the location */
  regionId?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/** Represents two location fields and allows user to swap values between them by clicking on a swapper button */
export interface EgdsSearchFormLocationsField extends EgdsElement {
  __typename: 'EGDSSearchFormLocationsField';
  /** Destination location field for a search */
  destination: EgdsSearchFormLocationField;
  egdsElementId: Scalars['String']['output'];
  /** Button element for swapping values of origin and destination fields. This button is for Medium and Large viewport where the fields are in line */
  horizontalSwapper?: Maybe<EgdsButton>;
  /** Origin location field for a search */
  origin: EgdsSearchFormLocationField;
  /** Array that contains validations for locations component */
  validations?: Maybe<Array<EgdsInputValidation>>;
  /** Button element for swapping values of origin and destination fields. This button is for Small viewport where origin and destination are stacked */
  verticalSwapper?: Maybe<EgdsButton>;
}

/** Represent a list of elements and allow user to select a single one */
export interface EgdsSearchFormSelectAction {
  __typename: 'EGDSSearchFormSelectAction';
  /** Used to provide accessibility that has to be read on completion of action. */
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
  /** Field to confirm a user selection */
  apply?: Maybe<EgdsButton>;
  /** Used to provide label value for the form. */
  label?: Maybe<Scalars['String']['output']>;
  /** List of elements available for user selection */
  options: Array<EgdsOption>;
}

export interface EgdsSearchFormSelectedLocation {
  __typename: 'EGDSSearchFormSelectedLocation';
  /**
   *
   *     Informs about the preselected values
   *     Example: \`Hilton\`, \`Hyatt\`, \`Ramada\`
   *
   */
  text: Scalars['String']['output'];
  /**
   *
   *     Optional value or a code associated with the text which can be used to uniquely identify the selection
   *     Example: miami-florida, seattle-washington
   *
   */
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *         Travelers field on search form or search form widget for entering travelers types and count.
 *
 *         Figma: https://www.figma.com/file/oYtPus1JugVGjib58w8Jkj/E2E-search?node-id=3769%3A204213
 *
 */
export interface EgdsSearchFormTravelersField extends EgdsElement, EgdsInputField, EgdsSearchFormField {
  __typename: 'EGDSSearchFormTravelersField';
  action?: Maybe<EgdsSearchFormAction>;
  egdsElementId: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/** Inputs for search queries such as property search, flights search, etc */
export interface EgdsSearchInputs {
  shoppingSearchCriteria?: Maybe<ShoppingSearchCriteria>;
}

/**
 *
 * Used to structure content.
 * For example: listing all the upcoming trips and then past trips can be achieved with 2 sections.
 *
 */
export interface EgdsSectionContainer extends EgdsElement, EgdsElements {
  __typename: 'EGDSSectionContainer';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  elements: Array<EgdsElement>;
  /** @deprecated Use sectionHeading instead. It allows allows more formatting options. */
  heading?: Maybe<Scalars['String']['output']>;
  sectionHeading?: Maybe<EgdsSectionHeading>;
  /** @deprecated This leads to ambiguity as section themes are not found in EGDS */
  theme?: Maybe<ContainerTheme>;
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Represents a section where the heading and subheading should be placed if they exist
 *
 */
export interface EgdsSectionHeading {
  heading: EgdsHeading;
  subheading?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Used for select element with a label, optional icon and option values.
 *     More details on UITK:
 *     http://uitk.expedia.biz/expedia/en_US/components/select
 *     Figma:
 *     https://www.figma.com/file/LvLAeuvgjQAhxUdAVP9Hzk/Selects
 *
 *     The egdsElementId of EGDSElement can be used for 'id' which is further sets 'for' attribute in <label> for accessibility purposes.
 *
 */
export interface EgdsSelect {
  /** User cannot intract with the select when the disabled value is true */
  disabled: Scalars['Boolean']['output'];
  /** Text to be displayed as error text when the select field is in an invalid state. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Icon to render within the select */
  icon?: Maybe<Icon>;
  /** Text for the label element */
  label: Scalars['String']['output'];
  /** Options for select element */
  options: Array<EgdsOption>;
  /** The selected value is displayed in a read only state when readOnly is true */
  readOnly: Scalars['Boolean']['output'];
}

/**
 *
 *     Sheets are containers anchored to an edge of the screen that content can be rendered in.
 *     Clients should orchestrate how content will render within the dialog as well as the
 *     closure components closeButton or toolbar.
 *
 *     https://www.figma.com/file/wZZwajWKN1dP9UKIdQVd8P/Sheets
 *
 */
export interface EgdsSheet {
  __typename: 'EGDSSheet';
  /** accessibility string in response to user click on close action */
  closeAccessibility?: Maybe<Scalars['String']['output']>;
  closeAnalytics: ClientSideAnalytics;
  closeText?: Maybe<Scalars['String']['output']>;
  /** Icon to display next to the text */
  icon?: Maybe<Icon>;
  /**
   * Type of Sheet
   * @deprecated Use sheetTypes instead
   */
  sheetType?: Maybe<EgdsSheetType>;
  /** Type of Sheet to be used for different view sizes */
  sheetTypes?: Maybe<Array<EgdsSheetTypePerViewSize>>;
}

/** Type of EGDSSheet */
export enum EgdsSheetType {
  BOTTOM = 'BOTTOM',
  CENTERED = 'CENTERED',
  FULLSCREEN = 'FULLSCREEN',
  POPOVER = 'POPOVER',
  SIDE = 'SIDE'
}

/** Specifies an EGDSSheetType to be used for a specific EGDSViewSize */
export interface EgdsSheetTypePerViewSize {
  __typename: 'EGDSSheetTypePerViewSize';
  /** Type of Sheet */
  sheetType?: Maybe<EgdsSheetType>;
  /** View size of the travelers application / window */
  viewSize?: Maybe<EgdsSheetTypeViewSize>;
}

/** ONLY USE IN EGDSSheetTypePerViewSize, represents the size of the travelers application / window */
export enum EgdsSheetTypeViewSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL'
}

/** Action for showing the search form. */
export interface EgdsShowSearchFormAction {
  __typename: 'EGDSShowSearchFormAction';
  accessibility: Scalars['String']['output'];
  analytics: ClientSideAnalytics;
  icon: Icon;
  /** URI used as a fallback link when website javascript hasn't hydrated. */
  resource: Uri;
}

/**
 *
 *     Date picker for selecting a single date.
 *     Use Case Example: Selecting date for one-way flight.
 *
 */
export interface EgdsSingleDatePicker extends EgdsDatePicker {
  __typename: 'EGDSSingleDatePicker';
  buttonText: Scalars['String']['output'];
  clearButtonText?: Maybe<Scalars['String']['output']>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars['String']['output'];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars['String']['output']>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars['Boolean']['output']>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars['String']['output'];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
}

/**
 *
 *     Spannable dialogs are containers that content can be rendered in. Clients should orchestrate how content
 *     will render within the dialog. Spannable dialogs can be used for more complex or dynamic content.
 *     They include a spannable toolbar with a close icon and dialog title.
 *     https://www.figma.com/file/XOdo8BowxO8VACXdesonOZ/Full-screen-dialogs?node-id=0%3A1
 *
 */
export interface EgdsSpannableDialog extends EgdsDialog {
  __typename: 'EGDSSpannableDialog';
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  toolbar: EgdsDialogSpannableToolbar;
}

/**
 *
 *    This can be used for representing text with inline elements such as stylized text (bold, colored, etc) and/or links.
 *
 */
export interface EgdsSpannableText extends EgdsElement, EgdsText {
  __typename: 'EGDSSpannableText';
  /**
   *
   *     A list of inline EGDSText implementations that comprise the text content.
   *     Example Response: `listOf(EGDSStylizedText("Bold",TextWeight.BOLD), EGDSPlainText(" is for emphasis."))`
   *
   * @deprecated Please use inlineContent
   */
  contents: Array<EgdsText>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         A list of inline EGDSText implementations that comprise the text content.
   *         Example Response: `listOf(EGDSStylizedText("Bold",TextWeight.BOLD), EGDSPlainText(" is for emphasis."))`
   *
   */
  inlineContent: Array<EgdsSpannableTextItem>;
  /**
   *
   *     The text in its entirety. NO html tags or any other other elements that indicate style should be present.
   *
   */
  text: Scalars['String']['output'];
}

/** List of possible EGDSSpannableText inline implementations */
export type EgdsSpannableTextItem = EgdsGraphicText | EgdsHeading | EgdsInlineLink | EgdsPlainText | EgdsStandardLink | EgdsStylizedText;

/**
 *
 *     Try to not return EGDSButton interface itself because a client won't need all of its implementations.
 *     https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
 *
 *     Stacked dialog footers provide one or more buttons displayed in a stacked orientation for users to respond to the dialog
 *
 */
export interface EgdsStackedDialogFooter extends EgdsDialogFooter {
  __typename: 'EGDSStackedDialogFooter';
  buttons: Array<EgdsButton>;
}

/**
 *
 *     Represents a standard badge
 *
 *     https://www.figma.com/file/aYQZVH6x5kqAGj8LbLzTVWfX/Badges
 *
 */
export interface EgdsStandardBadge extends EgdsBadge, EgdsElement {
  __typename: 'EGDSStandardBadge';
  accessibility?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  graphic?: Maybe<UiGraphic>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  size?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *    Represents an EGDS Standard Link
 *    Figma: https://www.figma.com/file/z0DKERbirtEuz6zVMF0HkW/Links?node-id=3206%3A51
 *
 */
export interface EgdsStandardLink extends EgdsElement, EgdsText {
  __typename: 'EGDSStandardLink';
  action: UiLinkAction;
  /** Display disabled link state. */
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Represents an icon next to the link. */
  icon?: Maybe<Icon>;
  /** Display position of the icon next to the link. */
  iconPosition?: Maybe<EgdsIconPosition>;
  /** Display size of the link. */
  size: EgdsLinkSize;
  text: Scalars['String']['output'];
}

/**
 *
 *     Representation of a EGDS message card
 *
 *     https://www.figma.com/file/SRnOSnyVYE5dYh7wEQNIfC/Messaging-card-patterns?node-id=1551%3A1450
 *
 *     UITK component defined at http://uitk.expedia.biz/expedia/en_US/components/menu"
 *
 */
export interface EgdsStandardMessagingCard extends EgdsElement {
  __typename: 'EGDSStandardMessagingCard';
  /** background color for the card */
  background?: Maybe<Scalars['String']['output']>;
  /** list of card buttons */
  buttons?: Maybe<Array<EgdsButton>>;
  /** dismiss action for messaging card */
  dismiss?: Maybe<EgdsDismissAction>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** card graphical item displayed along with body message */
  graphic?: Maybe<UiGraphic>;
  /** card title */
  heading?: Maybe<Scalars['String']['output']>;
  /** impression tracking for messaging card */
  impressionTracking?: Maybe<ClientSideAnalytics>;
  /** list of card links */
  links?: Maybe<Array<EgdsStandardLink>>;
  /** card body text */
  message: Scalars['String']['output'];
  /** right icon for the card */
  rightIcon?: Maybe<Icon>;
  /** UITK theme provider option for the card */
  themeProvider?: Maybe<EgdsThemeProvider>;
  /** represents the card themes such as PADDED, BORDERED */
  themes?: Maybe<Array<EgdsCardThemes>>;
}

/** Represents an EGDS standard submenu */
export interface EgdsStandardSubMenu extends EgdsSubMenu {
  __typename: 'EGDSStandardSubMenu';
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Represents a standard Switch
 *
 *     https://www.figma.com/file/69SEMRa1KCBXU6T8uRcdzM/Switches?node-id=20%3A83
 *
 */
export interface EgdsStandardSwitch extends UiToggle {
  __typename: 'EGDSStandardSwitch';
  checked: Scalars['Boolean']['output'];
  checkedAccessibilityLabel?: Maybe<Scalars['String']['output']>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars['String']['output']>;
  checkedLabel: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use checked or unchecked label */
  label: Scalars['String']['output'];
  uncheckedAccessibilityLabel?: Maybe<Scalars['String']['output']>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars['String']['output']>;
  uncheckedLabel: Scalars['String']['output'];
}

/**
 *
 *     Allows users to increment and decrement a value using controls"
 *     More details on UITK step-input:
 *     http://uitk.expedia.biz/expedia/en_US/components/step-input
 *
 *     Figma:
 *     https://www.figma.com/file/L4BMgfIGUiqx0zM5yBVBxv/Step-inputs
 *
 */
export interface EgdsStepInput {
  /** Screenreader-specific text for the decrease button */
  decreaseText?: Maybe<Scalars['String']['output']>;
  /** Screenreader-specific text for the increase button */
  increaseText?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the entity which this step input represents. For example, key for "Child" could be "children" */
  key?: Maybe<Scalars['String']['output']>;
  /** Label for the step input */
  label: Scalars['String']['output'];
  /** Maximum value the user can input */
  max?: Maybe<Scalars['Int']['output']>;
  /** Minimum value the user can input */
  min?: Maybe<Scalars['Int']['output']>;
  /** How much the value changes by, per step */
  step: Scalars['Int']['output'];
  /** A small string of subtext underneath the title */
  subLabel?: Maybe<Scalars['String']['output']>;
  /** Initial value for step input. */
  value: Scalars['Int']['output'];
}

/**
 *
 *    Represents stylized text with optional weight, theme and size
 *    https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=7554%3A6533
 *
 */
export interface EgdsStylizedText extends EgdsText {
  __typename: 'EGDSStylizedText';
  /** Use this field when accessibility text needs to be different from text */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Represents a decorative for the text */
  decorative?: Maybe<EgdsDecorativeText>;
  /** The egds size of this text. http://egds.expedia.biz/latest/tokens/default#foundation-type-size */
  size?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
}

/** Represents a EGDS submenu */
export interface EgdsSubMenu {
  /** Represents the array of menu list items */
  items: Array<EgdsMenuListItem>;
  /** Represents the menu title */
  title?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Action for submitting search form. This action includes fixed inputs for the search query that are not dependent on what the user entered in the search form.
 *     This action does not include some of the data needed to perform the search query
 *     because the user may have entered that data in other parts of the form after the response that included this action had been received.
 *
 */
export interface EgdsSubmitSearchFormAction extends UiAction {
  __typename: 'EGDSSubmitSearchFormAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
  /** Query input data that is not dependent on what user entered in the form. */
  fixedInputs?: Maybe<EgdsSearchInputs>;
}

/** Represent a Tab. */
export interface EgdsTab {
  accessibility?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  tabId: Scalars['String']['output'];
}

/** Helps the user easily navigate between related content groups or views. */
export interface EgdsTabs {
  selectedTabId?: Maybe<Scalars['String']['output']>;
  tabs: Array<EgdsTab>;
}

/** Represents the container for the list of template models */
export interface EgdsTemplateModels {
  models: Array<TemplateModel>;
}

/**
 *
 * Avoid returning EGDSText interface directly since it's bloated.
 * Instead, return one of the concrete types, or return a union of required types to your client.
 *
 * Implementations may include links, stylized text, or simple text without additional attributes.
 *
 */
export interface EgdsText {
  text: Scalars['String']['output'];
}

/** Represents a large text format input with multiple rows */
export interface EgdsTextAreaInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSTextAreaInputField';
  /** Determines if the textarea should be disabled */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  /** Sets the max row number the textarea will grow to before scrolling as a user types or pastes in content */
  maxRows?: Maybe<Scalars['Int']['output']>;
  /** Sets the initial number of rows the textarea will accommodate */
  minRows?: Maybe<Scalars['Int']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Groups text content such as paragraphs.
 *     The first of item of this list may require different spacing than the spacing between items in the list")
 *
 */
export interface EgdsTextContainer {
  contents: Array<EgdsText>;
}

/** EGDS typography list item with link and icon */
export interface EgdsTextIconLinkListItem extends EgdsElement, EgdsText, EgdsTextListItem {
  __typename: 'EGDSTextIconLinkListItem';
  action: UiLinkAction;
  /** Display disabled link state. */
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon: Icon;
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  /** Display size of the link. */
  size?: Maybe<EgdsLinkSize>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
}

/** Icon variant of EGDS typography list */
export interface EgdsTextIconList extends EgdsElement, EgdsTextList {
  __typename: 'EGDSTextIconList';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  listItems: Array<EgdsTextIconListItem>;
  size?: Maybe<EgdsTextListSize>;
}

/** EGDS typography list item with icon */
export interface EgdsTextIconListItem extends EgdsElement, EgdsText, EgdsTextListItem {
  __typename: 'EGDSTextIconListItem';
  /** Hidden text for screen reader accessibility where text and subtext are read on one line */
  accessibilityLabel?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon: Icon;
  /** Information for capturing client side impression analytics event */
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** Represents the first level of nesting within EGDS List */
  nestedLevelOneList?: Maybe<EgdsNestedLevelOneList>;
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
}

/** Represents a text input filed element with autofill property */
export interface EgdsTextInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSTextInputField';
  /** Autofill hint for the input value */
  autofill?: Maybe<Scalars['String']['output']>;
  /** Determines if the text field should be disabled */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/** EGDS compliant typography list https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography */
export interface EgdsTextList {
  listItems: Array<EgdsTextListItem>;
  size?: Maybe<EgdsTextListSize>;
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Contains a text list (i.e. ordered, unordered, bulleted, etc).
 *     Can be used to create a composite text object such as a section containing a heading, list, and footer.
 *
 */
export interface EgdsTextListContainer {
  list: EgdsTextList;
}

/** Typography list item with EGDS styles https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography */
export interface EgdsTextListItem {
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  /** Represents the subHeading/subTitle/description of a List Item/Element. */
  subText?: Maybe<Scalars['String']['output']>;
  /** Represents the heading/title of a List Item/Element. */
  text: Scalars['String']['output'];
}

export enum EgdsTextListItemStyle {
  DEFAULT = 'DEFAULT',
  EMPHASIS = 'EMPHASIS',
  LOYALTY = 'LOYALTY',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE'
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Groups a section of text containing a list
 *
 */
export interface EgdsTextListSection extends EgdsElement, EgdsTextListContainer {
  __typename: 'EGDSTextListSection';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<EgdsSectionHeading>;
  list: EgdsTextList;
}

/** https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography */
export enum EgdsTextListSize {
  LIST_1 = 'LIST_1',
  LIST_2 = 'LIST_2',
  LIST_3 = 'LIST_3'
}

/**
 *  This represents UI variations of EGDS List
 *         figma: https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=11931%3A11857
 *
 */
export enum EgdsTextListType {
  BULLET = 'BULLET',
  ICON = 'ICON',
  NO_BULLET = 'NO_BULLET',
  NUMBER = 'NUMBER'
}

/** Represents a EGDS Text menu */
export interface EgdsTextMenu extends EgdsMenu {
  __typename: 'EGDSTextMenu';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  subMenus: Array<EgdsSubMenu>;
  title: Scalars['String']['output'];
}

/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use Strings.
 *
 *     Groups text content and is composed of sections for the heading and body
 *
 */
export interface EgdsTextSection extends EgdsElement, EgdsTextContainer {
  __typename: 'EGDSTextSection';
  contents: Array<EgdsText>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<EgdsSectionHeading>;
}

/** EGDS typography list item */
export interface EgdsTextStandardListItem extends EgdsElement, EgdsText, EgdsTextListItem {
  __typename: 'EGDSTextStandardListItem';
  /** Hidden text for screen reader accessibility where text and subtext are read on one line */
  accessibilityLabel?: Maybe<Scalars['String']['output']>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Represents the first level of nesting within EGDS List */
  nestedLevelOneList?: Maybe<EgdsNestedLevelOneList>;
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
}

/** EGDS typography list item with Mark */
export interface EgdsTextWithMarkListItem extends EgdsElement, EgdsText, EgdsTextListItem {
  __typename: 'EGDSTextWithMarkListItem';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** EGDS visual assets that do not follow standard sizing to be made icons. */
  mark?: Maybe<Mark>;
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
}

/**
 *
 * UITK themeProvider config.
 * https://uitk.expedia.biz/en_US/components/utils#theme_provider
 * https://uitk.expedia.biz/en_US/components/cards#global_loyalty_card_patterns
 *
 */
export interface EgdsThemeProvider {
  __typename: 'EGDSThemeProvider';
  /** config for dark mode */
  darkMode?: Maybe<EgdsThemeProviderConfig>;
  isColorThemeContainer: Scalars['Boolean']['output'];
  /** config for light mode */
  lightMode?: Maybe<EgdsThemeProviderConfig>;
}

/**
 *
 * represents the possible UI theme colors.
 * https://uitk.expedia.biz/en_US/components/cards#global_loyalty_card_patterns
 * https://www.figma.com/file/x9PecZwsESUjIsB1s4D676/Visual-Identity-Refinement?type=design&node-id=6873-216790&mode=design&t=zsn1QbwSKBHJ7OD5-0
 *
 */
export enum EgdsThemeProviderColor {
  BRIGHT_1 = 'BRIGHT_1',
  DARK_1 = 'DARK_1',
  GLOBAL_LOYALTY_EXTRA_HIGH = 'GLOBAL_LOYALTY_EXTRA_HIGH',
  GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT = 'GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT',
  GLOBAL_LOYALTY_HIGH = 'GLOBAL_LOYALTY_HIGH',
  GLOBAL_LOYALTY_HIGH_BRIGHT = 'GLOBAL_LOYALTY_HIGH_BRIGHT',
  GLOBAL_LOYALTY_LOW = 'GLOBAL_LOYALTY_LOW',
  GLOBAL_LOYALTY_LOW_BRIGHT = 'GLOBAL_LOYALTY_LOW_BRIGHT',
  GLOBAL_LOYALTY_MID = 'GLOBAL_LOYALTY_MID',
  GLOBAL_LOYALTY_MID_BRIGHT = 'GLOBAL_LOYALTY_MID_BRIGHT',
  GLOBAL_LOYALTY_STANDARD = 'GLOBAL_LOYALTY_STANDARD',
  LIGHT_1 = 'LIGHT_1',
  MARKETING_1 = 'MARKETING_1',
  MARKETING_2 = 'MARKETING_2',
  NONE = 'NONE',
  STANDARD = 'STANDARD'
}

/** configuration of a UI theme for a single mode */
export interface EgdsThemeProviderConfig {
  __typename: 'EGDSThemeProviderConfig';
  /** use gradient if available for the theme color */
  isGradient?: Maybe<Scalars['Boolean']['output']>;
  /** whether to use the theme color as base for the component */
  isThemeBase?: Maybe<Scalars['Boolean']['output']>;
  /** the color of the theme */
  themeProviderColor?: Maybe<EgdsThemeProviderColor>;
}

/** Toast notifications are unobstructive window elements used to display quick, auto-expiring messages to users on an action they've taken */
export interface EgdsToast extends EgdsElement {
  __typename: 'EGDSToast';
  /** Action on click of CTA */
  action?: Maybe<UiLinkAction>;
  /** Text for CTA */
  actionText?: Maybe<Scalars['String']['output']>;
  button?: Maybe<UiPrimaryButton>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
}

/** Basic Toggle Button element */
export interface EgdsToggleButton {
  __typename: 'EGDSToggleButton';
  /** Accessibility label for the button. */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Represents whether the button is in disabled state */
  disabled: Scalars['Boolean']['output'];
  /** Element identifier */
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Represents icon to be displayed along with label */
  icon?: Maybe<UiGraphic>;
  /** Used as a reference to idenfity on form submit */
  name?: Maybe<Scalars['String']['output']>;
  /** Label to be displayed on the button */
  primary?: Maybe<Scalars['String']['output']>;
  /** Represents the state of the button, if it is selected/deselected */
  selected: Scalars['Boolean']['output'];
  /** analytics event to fire when button is selected */
  selectedAnalytics?: Maybe<ClientSideAnalytics>;
  /** Represents subtext below the label if any */
  subText?: Maybe<Scalars['String']['output']>;
  /** analytics event to fire when button is not selected */
  unselectedAnalytics?: Maybe<ClientSideAnalytics>;
}

/**
 *
 *     Represents collections button toggles to select/deselect one or more options grouped under a single category. They can be used as a visual alternative to checkboxes.
 *
 *     UITK react:
 *     https://uitk.expedia.biz/expedia/en_US/components/button-toggle
 *     Figma:
 *     https://www.figma.com/file/ZLAsPeQr2g6Ze5tTkKrcGk/Button-toggle
 *
 */
export interface EgdsToggleButtonGroup {
  __typename: 'EGDSToggleButtonGroup';
  /** Represents options for toggle button group. */
  options: Array<EgdsToggleButton>;
}

/**
 *
 *     Toolbar action item provide a title, icon for toolbars whose icon and texts are dynamic
 *
 */
export interface EgdsToolbarActionItem {
  __typename: 'EGDSToolbarActionItem';
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Prefer using `clickAnalytics` field with `AnalyticsPayload` type */
  analytics?: Maybe<ClientSideAnalytics>;
  clickAnalytics?: Maybe<Scalars['AnalyticsPayload']['output']>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars['String']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  navigationType?: Maybe<NavigationType>;
  title?: Maybe<EgdsSpannableText>;
}

/**
 *
 *    Represents travelers used for traveler selector.
 *
 */
export interface EgdsTraveler {
  /** Represents possible list of ages of traveler. Example: '', '1', '2'....'17'  */
  ages?: Maybe<Array<EgdsSelect>>;
  /** Represents traveler configuration. */
  count: EgdsStepInput;
}

/**
 *
 *    Represents child age selection for traveler selector.
 *
 */
export interface EgdsTravelerChildAgeSelect extends EgdsElement, EgdsSelect {
  __typename: 'EGDSTravelerChildAgeSelect';
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  label: Scalars['String']['output'];
  /** Localized label with template for child age. Example: 'Child 1 age', 'Child 2 age' */
  labelTemplate: EgdsLocalizedText;
  options: Array<EgdsOption>;
  readOnly: Scalars['Boolean']['output'];
  /** Age value for child. */
  value?: Maybe<Scalars['Int']['output']>;
}

/**
 *
 *    Represents implementation of children for traveler selector.
 *
 */
export interface EgdsTravelerChildren extends EgdsTraveler {
  __typename: 'EGDSTravelerChildren';
  /** Default age selection configuration for child. This template will be used to create new child in the room. */
  ageTemplate: EgdsSelect;
  ages: Array<EgdsSelect>;
  count: EgdsStepInput;
  /**
   * Traveler note to give some info when child is added.
   * @deprecated Please use the one defined in EGDSTravelerSelector or EGDSTravelerSelectorRoom instead.
   */
  travelerNote?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *    Represents implementation of infants for traveler selector.
 *
 */
export interface EgdsTravelerInfant extends EgdsTraveler {
  __typename: 'EGDSTravelerInfant';
  /** Default age selection configuration for infant. This template will be used to create new infant in the room. */
  ageTemplate: EgdsSelect;
  ages: Array<EgdsSelect>;
  count: EgdsStepInput;
  /**
   * Traveler note to give some info when infant is added.
   * @deprecated Please use the one defined in EGDSTravelerSelector or EGDSTravelerSelectorRoom instead.
   */
  travelerNote?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *    Represents infants seat selection for traveler selector.
 *
 */
export interface EgdsTravelerInfantSeatSelection extends EgdsElement, EgdsRadioGroup {
  __typename: 'EGDSTravelerInfantSeatSelection';
  /** Link represent airline rules for infant age selection. */
  airlineAgeRule: EgdsStandardLink;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  /** Label for seat selection options. Example: 'Where will the infant sit?' */
  label: Scalars['String']['output'];
  labelTemplate: EgdsLocalizedText;
  /** Possible options for infants seat. Example: 'In seat', ' On lap'  */
  options: Array<EgdsRadioButton>;
  selected: Scalars['String']['output'];
}

/**
 *
 *    Representation of traveler selector.
 *    The implementation can include travelers or rooms configuration which will be used to select the number of travelers for lodging, flights, packages, cruises and activities.
 *    figma:
 *     https://www.figma.com/file/y59aHUdrzsZBTg5RfbaZ9h/Traveler-selector?node-id=67%3A51
 *
 */
export interface EgdsTravelerSelector {
  /** Close button on the traveler selector. */
  closeButton: EgdsButton;
  /** Done button on the traveler selector. */
  doneButton: EgdsButton;
  /** Title of the traveler selector. Example: Travelers, Travelers in the cabin. */
  title: Scalars['String']['output'];
}

/**
 *
 *    Represents a room in traveler selector. The room will be used to represent hotel room in for lodging and package.
 *
 */
export interface EgdsTravelerSelectorRoom {
  __typename: 'EGDSTravelerSelectorRoom';
  /** Adults configuration for the room. */
  adults: EgdsStepInput;
  /** Children configuration for the room. */
  children: EgdsTraveler;
  /**
   * Infants configuration for the room.
   * @deprecated Use infantsOnLap and infantsInSeat instead.
   */
  infants?: Maybe<EgdsTraveler>;
  /** Represents infant travelers under certain age that require a seat reservation. */
  infantsInSeat?: Maybe<EgdsTraveler>;
  /** Represents infant travelers under certain age that don't require a seat selection. */
  infantsOnLap?: Maybe<EgdsTraveler>;
  /** Label associated with the room. Example: 'Room 1' */
  label: EgdsLocalizedText;
  /** Accessibility string in response to user click on removeRoomButton action */
  removeRoomAnnouncement?: Maybe<Scalars['String']['output']>;
  /** Represent remove room button. Example: 'Remove room' */
  removeRoomButton?: Maybe<EgdsButton>;
  /** Represent extra information to be displayed to the user. Example: 'Letting us know the exact age of your child. */
  travelerNote?: Maybe<EgdsLocalizedText>;
}

/**
 *
 *    Represents implementation of StepInput which will be used for adults, children, infant, seniors count.
 *
 */
export interface EgdsTravelerStepInput extends EgdsElement, EgdsStepInput {
  __typename: 'EGDSTravelerStepInput';
  /**
   * Informs behavior in response to user click.
   * @deprecated Need specific tracking for increase and decrease count event. Hence, deprecating the action and adding new analytics fields for increase and decrease count.
   */
  action?: Maybe<EgdsSearchFormAction>;
  /** Informs behavior in response when user click to decrease travelers count. */
  decreaseAnalytics: ClientSideAnalytics;
  decreaseText?: Maybe<Scalars['String']['output']>;
  /** Localized screen-reader specific text for announcing a decrement in traveler count. Example: 'Decrease the number of adults in room 1' */
  decreaseTextTemplate?: Maybe<EgdsLocalizedText>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** Informs behavior in response when user click to increase travelers count. */
  increaseAnalytics: ClientSideAnalytics;
  increaseText?: Maybe<Scalars['String']['output']>;
  /** Localized screen-reader specific text for announcing an increment in traveler count. Example: 'Increase the number of adults in room 1' */
  increaseTextTemplate?: Maybe<EgdsLocalizedText>;
  key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  step: Scalars['Int']['output'];
  subLabel?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
}

/** Travelers types used on travelers selector */
export enum EgdsTravelerType {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  INFANT = 'INFANT',
  SENIOR = 'SENIOR',
  YOUTH = 'YOUTH'
}

/** Represents all category of travelers used in travelers selector. */
export interface EgdsTravelers {
  __typename: 'EGDSTravelers';
  /** Represents adult travelers. */
  adults: EgdsStepInput;
  /** Represents child travelers. */
  children?: Maybe<EgdsTraveler>;
  /** Represents infant travelers under certain age that require a seat reservation. */
  infantsInSeat?: Maybe<EgdsTraveler>;
  /** Represents infant travelers under certain age that don't require a seat selection. */
  infantsOnLap?: Maybe<EgdsTraveler>;
  /** Represents pets travelers. */
  pets?: Maybe<EgdsCheckBox>;
  /** Represents senior travelers. */
  seniors?: Maybe<EgdsStepInput>;
  /** Represent extra information to be displayed to the user. Example: 'Letting us know the exact age of your child. */
  travelerNote?: Maybe<EgdsLocalizedText>;
}

/**
 *
 *    Used with traveler selector field to specify validations on travelers.
 *
 */
export interface EgdsTravelersInputValidation extends EgdsInputValidation {
  __typename: 'EGDSTravelersInputValidation';
  errorMessage: Scalars['String']['output'];
  /** Component that shows an specific error when travelers are saved */
  errorSummary?: Maybe<EgdsErrorSummary>;
  /** The maximum number of infants in seat allowed for each adult traveler */
  infantsInSeatPerAdult?: Maybe<Scalars['Int']['output']>;
  /** Specifies the maximum count this validation needs. */
  maxCount?: Maybe<Scalars['Int']['output']>;
  /** The minimum age of travelers to carry infant. */
  minAgeToHoldInfant?: Maybe<Scalars['Int']['output']>;
  /** Specifies the type of validation. */
  type: EgdsTravelersValidationType;
}

/** Represents different type of travelers validation. */
export enum EgdsTravelersValidationType {
  /** Used to validate child age required. */
  CHILD_AGE_REQUIRED = 'CHILD_AGE_REQUIRED',
  /** Used to validate maximum number of allowed infants in seat. */
  MANY_INFANT_IN_SEAT = 'MANY_INFANT_IN_SEAT',
  /** Used to validate maximum number of allowed infants on lap. */
  MANY_INFANT_ON_LAP = 'MANY_INFANT_ON_LAP',
  /** Used to validate maximum allowed travelers for search. */
  MAX_TRAVELERS = 'MAX_TRAVELERS'
}

/**
 *
 *         Input field which opens a list of autocomplete selections from the Typeahead API.
 *
 *         More details on UITK:
 *         http://uitk.expedia.biz/expedia/en_US/components/typeahead
 *         Figma:
 *         https://www.figma.com/file/oCe7wGPkh8SU8As0KJ3u2C/Typeahead?node-id=3381%3A0
 *
 */
export interface EgdsTypeaheadInputField extends EgdsElement, EgdsInputField {
  __typename: 'EGDSTypeaheadInputField';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  renderType?: Maybe<EgdsTypeaheadRenderType>;
  required?: Maybe<Scalars['Boolean']['output']>;
  rightIcon?: Maybe<Icon>;
  /** Placeholder text for the typeahead input when no results are found for the query field */
  typeaheadEmptyResultPlaceholder?: Maybe<Scalars['String']['output']>;
  /** Parameters to pass to underlying Typeahead API on client */
  typeaheadInfo: TypeaheadInfo;
  /** Placeholder text for the typeahead input when presented */
  typeaheadPlaceholder?: Maybe<Scalars['String']['output']>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars['String']['output']>;
}

/** Multi select typeahead properties */
export interface EgdsTypeaheadMultiSelect {
  __typename: 'EGDSTypeaheadMultiSelect';
  /** Text for clear all items button text */
  clearAllText?: Maybe<Scalars['String']['output']>;
  /** Specifies is multiple locations selection feature is enable */
  isMultiLocation?: Maybe<Scalars['Boolean']['output']>;
  /** Text to display selected item heading */
  selectedItemHeading?: Maybe<Scalars['String']['output']>;
  /** Flag to show pills for the selected results */
  showPills: Scalars['Boolean']['output'];
}

/** Defines how typeahead results should be displayed on client. */
export enum EgdsTypeaheadRenderType {
  ADAPTIVE = 'ADAPTIVE',
  DIALOG = 'DIALOG',
  MENU = 'MENU'
}

/** Unordered variant of EGDS typography list */
export interface EgdsUnorderedList extends EgdsElement, EgdsList, EgdsTextList {
  __typename: 'EGDSUnorderedList';
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
  items: Array<EgdsText>;
  listItems: Array<EgdsTextListItem>;
  size?: Maybe<EgdsTextListSize>;
}

/**
 *
 * Used to specify event type for an interaction.
 * See https://github.expedia.biz/Brand-Expedia/uis-prime-schemas/blob/master/schemas/micro/platform/referrer.json#L26
 *
 */
export enum EgReferrerEventType {
  /** Used to track a click on a UI component. */
  CLICK = 'CLICK',
  /** Used to track an impression of a UI component. */
  IMPRESSION = 'IMPRESSION'
}

/** Used for analytics micro messages, e.g. deals, sponsored */
export interface EgUisMicroMessage {
  __typename: 'EGUisMicroMessage';
  /** Represents message content for the uis prime message as a json. */
  messageContent: Scalars['String']['output'];
  /** Represents name of the uis prime schema. e.g. allHotelProducts, pageIdentity */
  schemaName: Scalars['String']['output'];
}

/** Expando subtitle options */
export interface ExpandoSubtitles {
  __typename: 'ExpandoSubtitles';
  /** All selected subtitle */
  allSelected: Scalars['String']['output'];
  /** Non selected subtitle */
  noneSelected: Scalars['String']['output'];
  /** Some selected subtitle */
  someSelected: Scalars['String']['output'];
}

/** Specifies the position of the trigger icon  */
export enum ExpandoTriggerPosition {
  LEADING = 'LEADING',
  TRAILING = 'TRAILING'
}

/** An exposure to an abacus experiment */
export interface ExposureInput {
  /** The experiment bucket of the exposure */
  bucket: Scalars['Int']['input'];
  /** The numeric Abacus ID for an experiment */
  id: Scalars['String']['input'];
}

/** Dialog to extend campaign */
export interface ExtendCampaignDialog {
  __typename: 'ExtendCampaignDialog';
  /** Dialog content */
  content: Scalars['String']['output'];
  /** Dialog button to extend campaign */
  editCampaign: UiSecondaryButton;
  /** Dialog button to exit dialog */
  exit: UiSecondaryButton;
  /** Dialog heading */
  heading: Scalars['String']['output'];
}

/** FSL creative input */
export interface FslCreativeInput {
  /** Ad creative description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Ad creative headline */
  headline?: InputMaybe<Scalars['String']['input']>;
  /** Ad creative image URL */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
}

/** Featured campaigns section */
export interface FeaturedCustomerCampaigns {
  __typename: 'FeaturedCustomerCampaigns';
  /** Carousel */
  carousel: EgdsCarousel;
  /** Section Title */
  title: Scalars['String']['output'];
}

/** Featured campaigns Carousel Card */
export interface FeaturedCustomerCard extends EgdsCarouselItem {
  __typename: 'FeaturedCustomerCard';
  /** Article Image */
  featuredImage: Image;
  /** Article Summary */
  featuredSummary: Scalars['String']['output'];
  /** Article Title */
  featuredTitle: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  /** Read more button text */
  readMoreText: Scalars['String']['output'];
  /** Article URL */
  readMoreUrl: Scalars['String']['output'];
}

/** Flight Creative Card */
export interface FlightCreativeCard {
  __typename: 'FlightCreativeCard';
  /** Flight Creative Card Ad copy language selector */
  selector: EgdsSelect;
}

/** Flight creative input */
export interface FlightCreativeInput {
  /** Ad copy description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Ad copy headline */
  headline?: InputMaybe<Scalars['String']['input']>;
  /** Ad copy image url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
}

export enum FormInput {
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN = 'CAMPAIGN'
}

/** Support for `geo` based URI constructed with latitude, longitude and uncertainty */
export interface GeoUri extends Uri {
  __typename: 'GeoURI';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  uncertainty?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
}

/** Go to Travelads section */
export interface GoToTravelAds {
  __typename: 'GoToTravelAds';
  /** Travelads section description */
  description: Scalars['String']['output'];
  /** Travelads section header */
  header: Scalars['String']['output'];
  /** Travelads logo */
  headerImage?: Maybe<Image>;
  /** Travelads preview ad text */
  sidepanelCampaignAd?: Maybe<Scalars['String']['output']>;
  /** Travelads preview campaign description */
  sidepanelCampaignDescription?: Maybe<Scalars['String']['output']>;
  /** Travelads preview campaign header */
  sidepanelCampaignHeader?: Maybe<Scalars['String']['output']>;
  /** Travelads preview campaign image */
  sidepanelCampaignImage?: Maybe<Image>;
  /** Travelads preview campaign name */
  sidepanelCampaignName?: Maybe<Scalars['String']['output']>;
  /** Travelads preview Expedia image */
  sidepanelExpediaImage?: Maybe<Image>;
  /** Travelads preview footnote */
  sidepanelFootnote?: Maybe<Scalars['String']['output']>;
  /** Travelads background preview image */
  sidepanelPreview?: Maybe<Image>;
  /** Travelads preview search placeholder */
  sidepanelSearchPlaceholder?: Maybe<Scalars['String']['output']>;
  /** Travelads preview search prompt */
  sidepanelSearchPrompt?: Maybe<Scalars['String']['output']>;
  /** Travelads benefits bullet points */
  traveladsBullets: Array<TraveladsBullet>;
  /** Ad Portal redirect button */
  traveladsButton: UiPrimaryButton;
  /** Travelads redirect button url */
  traveladsRedirectURL: Scalars['URL']['output'];
  /** Ad Portal secondary redirect button */
  traveladsSecondaryButton?: Maybe<UiSecondaryButton>;
  /** Travelads secondary redirect button url */
  traveladsSecondaryURL?: Maybe<Scalars['URL']['output']>;
}

/** Support for `http` or `https` based URI constructed based on a valid `java.net.URL` object. */
export interface HttpUri extends Uri {
  __typename: 'HttpURI';
  relativePath: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/**
 *
 *     Representation of an Icon and Icon SpotLight
 *     https://www.figma.com/file/Cq6XtgvVIEriWmWmVIMPzNOE/Icons?node-id=6402%3A115
 *
 */
export interface Icon {
  __typename: 'Icon';
  /** The localized text describing the icon */
  description: Scalars['String']['output'];
  /** The egds value used for loading this icon */
  id: Scalars['String']['output'];
  /** The size of the icon */
  size?: Maybe<IconSize>;
  /** The spotlight theme of the icon */
  spotLight?: Maybe<IconSpotLight>;
  /** The theme of the icon */
  theme?: Maybe<IconTheme>;
  /** The localized text describing the title for the icon, mainly used as label in accessibility, such as tooltip */
  title?: Maybe<Scalars['String']['output']>;
  /** The egds id used for loading this icon */
  token: Scalars['String']['output'];
  /** Parameter to show icon with background ellipse or not */
  withBackground?: Maybe<Scalars['Boolean']['output']>;
}

/** supported sizes of icons */
export enum IconSize {
  EXTRA_SMALL = 'EXTRA_SMALL',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  /** @deprecated LARGE icon size changed from 48px to 32px. Use LARGE instead of MEDIUM_LARGE */
  MEDIUM_LARGE = 'MEDIUM_LARGE',
  SMALL = 'SMALL',
  XLARGE = 'XLARGE'
}

/** Supported themes of spotlights. See the prop definitions here: https://www.figma.com/file/Cq6XtgvVIEriWmWmVIMPzNOE/Icons?node-id=6402%3A115 */
export enum IconSpotLight {
  INFO = 'INFO',
  POSITIVE = 'POSITIVE',
  STANDARD = 'STANDARD',
  WARNING = 'WARNING'
}

/** Supported themes of icons. See the prop definitions here: http://uitk-react.tools.expedia.com/expedia/en_US/components/icons */
export enum IconTheme {
  DEFAULT = 'default',
  EMPHASIS = 'emphasis',
  INVERSE = 'inverse',
  NEGATIVE = 'negative',
  POSITIVE = 'positive'
}

/** Identity information for a specific user or device */
export interface IdentityInput {
  /** The Expedia user authenticated state */
  authState?: InputMaybe<AuthenticationState>;
  /** The device ID (DUAID) */
  duaid: Scalars['UUID']['input'];
  /** The Expedia User ID */
  expUserId?: InputMaybe<Scalars['String']['input']>;
  /** The user TUID */
  tuid?: InputMaybe<Scalars['String']['input']>;
}

/**
 *
 * A pictorial representation that communicates and illuminates a concept, idea, or process to a user with delight, interest, and beauty.
 * https://www.figma.com/file/PhrzWgeLfR11IxzTZ80ypI/BEX-Illustration
 *
 */
export interface Illustration extends EgdsElement {
  __typename: 'Illustration';
  /** The localized text describing the illustration */
  description: Scalars['String']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  /** The token name used for loading this illustration */
  id: Scalars['String']['output'];
  /** The location of the illustration pointing to CDN */
  url: Scalars['URL']['output'];
}

export interface Image {
  __typename: 'Image';
  /**
   * Alternate text to be used for accessibility features
   * @deprecated Use description instead
   */
  altText?: Maybe<Scalars['String']['output']>;
  /** Aspect Ratio of the image */
  aspectRatio?: Maybe<AspectRatio>;
  /** The localized text describing the image */
  description: Scalars['String']['output'];
  /** thumbnail image click analytics */
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars['URL']['output'];
}


export interface ImageUrlArgs {
  dimensions?: InputMaybe<DimensionsInput>;
}

export interface ImageAutoSelectPrompt {
  __typename: 'ImageAutoSelectPrompt';
  /** Image auto select heading */
  heading: Scalars['String']['output'];
  /** Image auto select icon */
  icon: Icon;
  /** TEST */
  learnMoreLink: EgdsInlineLink;
  /** Image auto select subheading */
  recommendedBadge: EgdsStandardBadge;
  /** Image auto select text */
  text: EgdsSpannableText;
  /** Switch */
  toggleSwitch: EgdsStandardSwitch;
}

/** Image Picker */
export interface ImagePicker {
  __typename: 'ImagePicker';
  /** Image picker available images text */
  availableImagesText: Scalars['String']['output'];
  /** Image picker image list */
  imageList: Array<EgdsImageCard>;
  /** Image picker currently selected image */
  selectedImage: EgdsImageCard;
  /** Image picker currently selected image text */
  selectedImageText: Scalars['String']['output'];
  /** Side sheet for image picker */
  sheet: EgdsSheet;
  /** Buttons for sheet footer */
  sheetButtons: Array<PortalButton>;
}

/** Represents a list of inline items that are text */
export interface InlinePriceLineText {
  __typename: 'InlinePriceLineText';
  inlineItems: Array<PriceLineText>;
}

/** Ad preview content */
export interface LslAdPreviewContent {
  __typename: 'LSLAdPreviewContent';
  /** Creative Picture Card Ad preview text */
  adCopy: Scalars['String']['output'];
  /** Ad preview badge */
  adIndicatorBadge: EgdsStandardBadge;
  /** Creative Picture Card Ad preview text */
  headline: Scalars['String']['output'];
  /** Ad preview location */
  locationName: Scalars['String']['output'];
  /** Ad preview money */
  moneyText: Scalars['String']['output'];
  /** Ad preview picture */
  picture: EgdsImageCard;
  /** Ad preview property name */
  propertyName: Scalars['String']['output'];
}

/** LSL creative input */
export interface LslCreativeInput {
  /** Ad creative description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Ad creative headline */
  headline?: InputMaybe<Scalars['String']['input']>;
  /** Ad creative image ID */
  imageId?: InputMaybe<Scalars['String']['input']>;
  /** Ad creative image URL */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
}

/** Learn more link */
export interface LearnMoreLink {
  __typename: 'LearnMoreLink';
  /** Text for learn more and helper links */
  text: Scalars['String']['output'];
}

/** Ad group location expando menus */
export interface LocationExpando {
  __typename: 'LocationExpando';
  /** Ad group location expando item */
  expando: EgdsExpandoListItem;
  /** Ad group expando extra content */
  expandoContent: LocationExpandoContent;
  /** Expando subtitles */
  expandoSubTitle: ExpandoSubtitles;
}

/** Ad Group Location extra content */
export interface LocationExpandoContent {
  __typename: 'LocationExpandoContent';
  /** Location targeting checkboxes */
  checkBoxGroup: EgdsCheckBoxGroup;
  /** Clear all location selections button */
  clearButton: UiTertiaryButton;
  /** Select all locations button */
  selectAllButton: UiTertiaryButton;
  /** Title of the expando content */
  title: Scalars['String']['output'];
}

/** Lodging Card Content */
export interface LodgingCreativeCard {
  __typename: 'LodgingCreativeCard';
  /** Creative Card preview ad button */
  adPreviewButton: UiSecondaryButton;
  /** Creative Card Dialog */
  creativeDialog: CreativeDialog;
  /** Image picker */
  imagePicker: ImagePicker;
  /** Creative Card Ad copy language selector */
  languageSelector: EgdsSelect;
  /** Creative Card content picture card */
  pictureCard: CreativePictureCard;
}

/** Ad Copy Request */
export interface LodgingCreativeInput {
  /** Can use ML to override ad image */
  canOverrideImage: Scalars['Boolean']['input'];
  /** Ad copy description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Ad copy headline */
  headline?: InputMaybe<Scalars['String']['input']>;
  /** Ad copy image id */
  imageId?: InputMaybe<Scalars['String']['input']>;
  /** Ad copy url */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
}

/** Support for `mailto` based URI constructed based on the email address. */
export interface MailToUri extends Uri {
  __typename: 'MailToURI';
  emailAddress: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** EGDS visual assets that do not follow standard sizing to be made icons */
export interface Mark {
  __typename: 'Mark';
  /** The localized text describing the mark */
  description: Scalars['String']['output'];
  /** The egds value used for loading this mark */
  id: Scalars['String']['output'];
  /** The egds orientation of this mark */
  orientation?: Maybe<Orientation>;
  /** The egds size of this mark */
  size?: Maybe<Scalars['String']['output']>;
  /** The egds id used for loading this mark */
  token: Scalars['String']['output'];
  /** The image source URL for loading this mark */
  url?: Maybe<HttpUri>;
}

export enum MarketGoal {
  LEADER = 'LEADER',
  PLAYER = 'PLAYER',
  TRACKER = 'TRACKER',
  UNKNOWN = 'UNKNOWN'
}

/** Market Goal */
export interface MarketGoalCard {
  __typename: 'MarketGoalCard';
  goalHeader: Scalars['String']['output'];
  goalSelect: EgdsSelect;
  goalSubText: Scalars['String']['output'];
  goalTypes: Array<MarketGoalType>;
}

/** Market Goal Data */
export interface MarketGoalData {
  __typename: 'MarketGoalData';
  /** Market goal data description */
  goalDescription: Scalars['String']['output'];
  /** Market goal data title */
  goalName: Scalars['String']['output'];
}

export interface MarketGoalType {
  __typename: 'MarketGoalType';
  description: EgdsSpannableText;
  name: Scalars['String']['output'];
  pluralName: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** A media element. eg. a Video or an Image */
export type Media = EgdsPhotosphereImage | Image | Video;

/** A media item that contains media. eg. a Video or an Image */
export interface MediaItem {
  media: Media;
}

/** Metric format */
export interface MetricFormat {
  __typename: 'MetricFormat';
  /** amount int */
  amount?: Maybe<Scalars['Float']['output']>;
  /** formatting for the number */
  format?: Maybe<Scalars['String']['output']>;
}

/** Validation for minimum value input */
export interface MinValueInputValidation extends EgdsInputValidation {
  __typename: 'MinValueInputValidation';
  /** Error message to display if input is less than minimum value */
  errorMessage: Scalars['String']['output'];
  /** Minimum value allowed */
  minValue: Scalars['Float']['output'];
}

export interface Mutation {
  __typename: 'Mutation';
  /** Mutation to apply an opportunity */
  applyOpportunity: ApplyOpportunityResponse;
  /** Mutation to dismiss an opportunity */
  dismissOpportunity: DismissOpportunityResponse;
  /** Mutation to update or create a campaign */
  publishCampaign: CampaignPublishResponse;
  /** Mutation to update an existing campaign, ad group, or ad */
  updateCampaignStatus: UpdateStatusResponse;
}


export interface MutationApplyOpportunityArgs {
  applyOpportunityReq: ApplyOpportunityInput;
}


export interface MutationDismissOpportunityArgs {
  dismissOpportunityReq: DismissOpportunityInput;
}


export interface MutationPublishCampaignArgs {
  campaignRequestInput: CampaignRequestInput;
  productContext: ProductContext;
}


export interface MutationUpdateCampaignStatusArgs {
  adGroupId?: InputMaybe<Scalars['String']['input']>;
  adGroupIsPaused?: InputMaybe<Scalars['Boolean']['input']>;
  adGroupName?: InputMaybe<Scalars['String']['input']>;
  adId?: InputMaybe<Scalars['String']['input']>;
  adIsPaused?: InputMaybe<Scalars['Boolean']['input']>;
  adName?: InputMaybe<Scalars['String']['input']>;
  campaignId: Scalars['String']['input'];
  campaignIsPaused?: InputMaybe<Scalars['Boolean']['input']>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
}

/** Name Card Content */
export interface NameCard {
  __typename: 'NameCard';
  /** name text input */
  nameTextInput?: Maybe<EgdsInputField>;
}

/**
 *
 *     NavigationType provides collapse/expanded state of toolbar, specific to bottom sheet
 *
 */
export enum NavigationType {
  COLLAPSE = 'COLLAPSE',
  EXPAND = 'EXPAND'
}

/** Type used during a product search to specify a given numeric value like the number of stars for a hotel. */
export interface NumberValue {
  __typename: 'NumberValue';
  id: Scalars['String']['output'];
  value: Scalars['Int']['output'];
}

export enum OnboardStep {
  PARTNER = 'PARTNER',
  PAYMENT = 'PAYMENT',
  REVIEW = 'REVIEW'
}

/** Onboarding card options */
export interface OnboardingCard {
  __typename: 'OnboardingCard';
  cardContent?: Maybe<OnboardingCardContent>;
  /** Onboarding Card heading */
  cardHeading?: Maybe<Scalars['String']['output']>;
  /** Onboarding Card subtext */
  cardSubHeading?: Maybe<Scalars['String']['output']>;
}

/** Different card types for onboarding page */
export type OnboardingCardContent = AdvertiserPortalSharedUiCard | OnboardingLandingCard | OnboardingReviewCard;

/** Input for onboarding a partner */
export interface OnboardingInput {
  /** Current step */
  currentStep?: InputMaybe<Scalars['Int']['input']>;
  /** New partner flag */
  newPartner: Scalars['Boolean']['input'];
  /** Partner account id */
  partnerAccountId: Scalars['String']['input'];
  /** Payment account id */
  paymentAccountId: Scalars['String']['input'];
  /** Payment profile id */
  paymentProfileId: Scalars['String']['input'];
  /** Product ids to onboard */
  productIdsToOnboard: Array<Scalars['String']['input']>;
}

/** Onboarding Landing Card */
export interface OnboardingLandingCard {
  __typename: 'OnboardingLandingCard';
  /** Landing Card footer text */
  cardFooterText: Scalars['String']['output'];
  /** Landing Card list of step items */
  cardStepItems: Array<Scalars['String']['output']>;
  /** Landing Card sub title */
  cardSubtitle: EgdsSpannableText;
  /** Landing Card Terms and Conditions link */
  cardTermsAndConditionsLink: EgdsInlineLink;
  /** Landing Card title */
  cardTitle: Scalars['String']['output'];
  /** Landing Card get started button */
  getStartedButton: UiPrimaryButton;
}

/** Onboarding Review Card */
export interface OnboardingReviewCard {
  __typename: 'OnboardingReviewCard';
  /** Review Card link to edit reviewing entity */
  editLink?: Maybe<Scalars['String']['output']>;
  /** Review Card sections */
  sections: Array<OnboardingReviewSection>;
  /** Review Card sub title */
  subTitle: Scalars['String']['output'];
  /** Review Card title */
  title: Scalars['String']['output'];
}

/** Onboarding Review Section */
export interface OnboardingReviewSection {
  __typename: 'OnboardingReviewSection';
  /** Review Section link to edit reviewing entity */
  editLink?: Maybe<Scalars['String']['output']>;
  /** Review Section items */
  items: Array<ReviewLineItem>;
  /** Review Section title */
  title: Scalars['String']['output'];
}

/** Onboarding step */
export interface OnboardingSection {
  __typename: 'OnboardingSection';
  /** Onboarding step icon */
  icon: Icon;
  /** Onboarding step title */
  label: Scalars['String']['output'];
}

/**
 *
 *     Action for opening the duration span element.
 *     figma: https://www.figma.com/file/eiXu9ARV67TsK58GPfKdku/Cruise-search-(CSR)?node-id=2905%3A161931
 *
 */
export interface OpenDurationSpanAction {
  __typename: 'OpenDurationSpanAction';
  /** Message for accessibility purposes on duration span action completion. */
  accessibility?: Maybe<Scalars['String']['output']>;
  /** Captures client side analytics events for the duration span element. */
  analytics: ClientSideAnalytics;
  /** Configuration for the duration span element. */
  durationSpan: DurationSpan;
}

/**
 *
 * Open a typeahead dialog
 * https://www.figma.com/file/oCe7wGPkh8SU8As0KJ3u2C/Typeahead
 *
 */
export interface OpenTypeaheadAction extends UiAction {
  __typename: 'OpenTypeaheadAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * Enables exact search term matching
   * @deprecated Use info.allowExactMatch
   */
  allowExactMatch: Scalars['Boolean']['output'];
  analytics: ClientSideAnalytics;
  /**
   * Flag used to categorize the typeahead results
   * @deprecated Use info.categorize
   */
  categorize: Scalars['Boolean']['output'];
  /**
   * Text for clear all items button text
   * @deprecated Use info.typeaheadMultiSelect.clearAllText
   */
  clearAllText?: Maybe<Scalars['String']['output']>;
  /**
   * Text for dismiss button text
   * @deprecated Use info.dismissButtonText
   */
  dismissButtonText?: Maybe<Scalars['String']['output']>;
  /**
   * Placeholder for using when no results have been gather, commonly displayed when user hasn't performed a search before
   * @deprecated Use info.emptyResultsPlaceholder
   */
  emptyResultsPlaceholder?: Maybe<Scalars['String']['output']>;
  info: TypeaheadInfo;
  /**
   * When this flag is set, the heading for the categorized results is shown
   * @deprecated Use info.listTitle
   */
  listTitle: Scalars['Boolean']['output'];
  /**
   * Text to display selected item heading
   * @deprecated Use info.typeaheadMultiSelect.selectedItemHeading
   */
  selectedItemHeading?: Maybe<Scalars['String']['output']>;
  /**
   * Flag to show pills for the selected results
   * @deprecated Use info.typeaheadMultiSelect.showPills
   */
  showPills: Scalars['Boolean']['output'];
}

export type Opportunity = BudgetOpportunity | OptimizedBiddingOpportunity;

/** Opportunity dismissal dialog */
export interface OpportunityDismissalDialog {
  __typename: 'OpportunityDismissalDialog';
  /** Opportunity dismissal cancel button */
  cancelButton: UiSecondaryButton;
  /** Opportunity dismissal dialog body text */
  descriptionTexts: Array<Scalars['String']['output']>;
  /** Opportunity dismissal reason pills */
  dismissalReasonPills: Array<EgdsBasicPill>;
  /** Opportunity dismissal submit button */
  submitButton: UiPrimaryButton;
  /** Opportunity dismissal dialog title */
  title: Scalars['String']['output'];
}

/** Optimized Bidding Opportunity */
export interface OptimizedBiddingOpportunity {
  __typename: 'OptimizedBiddingOpportunity';
  /** Optimized bidding opportunity campaign name */
  campaignDisplayName: Scalars['String']['output'];
  /** Optimized bidding opportunity description */
  description: Scalars['String']['output'];
  /** Optimized bidding opportunity dialog */
  dialog: OptimizedBiddingOpportunityDialog;
  /** Optimized bidding opportunity dismissal button */
  dismissalButton: UiTertiaryButton;
  /** Opportunity dismissal dialog */
  dismissalDialog: OpportunityDismissalDialog;
  /** Optimized bidding opportunity heading */
  heading: Scalars['String']['output'];
  /** Optimized bidding opportunity icon */
  icon: Icon;
  /** Optimized bidding opportunity dialog button */
  openDialogButton: UiSecondaryButton;
}

/** Optimized bidding opportunity dialog */
export interface OptimizedBiddingOpportunityDialog {
  __typename: 'OptimizedBiddingOpportunityDialog';
  /** Optimized bidding dialog content body text */
  bodyText: Scalars['String']['output'];
  /** Optimized bidding dialog toolbar title */
  campaignOptimizedBiddingRecommendationCards: Array<CampaignOptimizedBiddingRecommendationCard>;
  /** Optimized bidding dialog cancel button */
  cancelButton: UiSecondaryButton;
  /** Optimized bidding dialog content heading */
  heading: Scalars['String']['output'];
  /** Optimized bidding dialog submit button */
  submitButton: UiPrimaryButton;
  /** Optimized bidding dialog toolbar title */
  toolbarTitle: Scalars['String']['output'];
}

/** Possible values for UITK mark's orientation prop */
export enum Orientation {
  /** Landscape orientation */
  LANDSCAPE = 'LANDSCAPE',
  /** Portrait orientation */
  PORTRAIT = 'PORTRAIT'
}

/** Column headers for the campaign overview table */
export interface OverviewTableColumn {
  __typename: 'OverviewTableColumn';
  /** The alignment of the cell content */
  align: Scalars['String']['output'];
  /** border for cells */
  border?: Maybe<CampaignOverviewBorder>;
  /** The unique identifier for the column in the table. */
  fieldName: Scalars['String']['output'];
  /** Frozen column */
  frozenColumn?: Maybe<Scalars['String']['output']>;
  /** The display name for the column in the table header. */
  headerName: Scalars['String']['output'];
  /** Sorting icon */
  sortIcon?: Maybe<Icon>;
  /** A flag indicating whether the column can be sorted. */
  sortable: Scalars['Boolean']['output'];
}

export interface PerformanceDateRangeInput {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
}

/** Performance overview campaign selection */
export interface PerformanceOverviewCampaignSelectionResponse {
  __typename: 'PerformanceOverviewCampaignSelectionResponse';
  /** Campaign option */
  campaignSelector: EgdsBasicSelect;
}

/** Performance overview carousel card */
export interface PerformanceOverviewCarouselCard {
  __typename: 'PerformanceOverviewCarouselCard';
  /** Performance overview carousel card currency */
  badge?: Maybe<EgdsStandardBadge>;
  /** Performance overview carousel card metric tool tip */
  metricToolTip: EgdsMoreInfoTrigger;
  /** Performance overview carousel card metric value */
  metricValue: PerformanceOverviewMetric;
}

/** Performance overview carousel */
export interface PerformanceOverviewCarouselResponse {
  __typename: 'PerformanceOverviewCarouselResponse';
  /** Performance overview carousel card */
  carouselCards: Array<PerformanceOverviewCarouselCard>;
  /** Performance overview carousel no data placeholder */
  noDataPlaceholder: Scalars['String']['output'];
}

/** Performance overview chart */
export interface PerformanceOverviewChartResponse {
  __typename: 'PerformanceOverviewChartResponse';
  /** Performance overview chart data */
  dataPoints?: Maybe<Array<DataPoint>>;
  /** Performance overview date selector */
  dateSelector: EgdsBasicSelect;
  /** Performance overview month names */
  monthNames: Array<Scalars['String']['output']>;
  /** Performance overview chart no data icon */
  noDataIcon: Icon;
  /** Performance overview chart no data placeholder */
  noDataPlaceholder: Scalars['String']['output'];
  /** Performance overview chart subtitle */
  subtitle: Scalars['String']['output'];
  /** Performance overview chart title */
  title: Scalars['String']['output'];
}

/** Performance overview metric */
export interface PerformanceOverviewMetric {
  __typename: 'PerformanceOverviewMetric';
  /** amount int */
  amount?: Maybe<Scalars['Float']['output']>;
  /** formatting for the number */
  format?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Languages vary in how they handle plurals of nouns or unit expressions ("hour" vs "hours", and so on).
 *     Some languages have two forms, like English; some languages have only a single form; and some languages have multiple forms. CLDR uses short, mnemonic tags for these plural categories.
 *     http://cldr.unicode.org/index/cldr-spec/plural-rules
 *
 */
export enum PluralCategory {
  FEW = 'FEW',
  MANY = 'MANY',
  ONE = 'ONE',
  OTHER = 'OTHER',
  TWO = 'TWO',
  ZERO = 'ZERO'
}

/** Banner */
export interface PortalBanner {
  __typename: 'PortalBanner';
  /** Banner headline */
  bannerHeadline: Scalars['String']['output'];
  /** Banner tag */
  bannerTag: BannerTag;
  /** Banner text */
  bannerText: Scalars['String']['output'];
  /** Banner type */
  bannerType: BannerType;
}

/** Portal Button */
export interface PortalButton {
  __typename: 'PortalButton';
  button?: Maybe<PortalButtonType>;
}

export type PortalButtonType = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

/** Represents a badge that indicates a saving or branded deal */
export interface PriceLineBadge {
  __typename: 'PriceLineBadge';
  badge?: Maybe<EgdsStandardBadge>;
  programBadge?: Maybe<EgdsProgramBadge>;
}

/** Represents a line item that is header */
export interface PriceLineHeading {
  __typename: 'PriceLineHeading';
  /** @deprecated Use `additionalInformation` instead */
  additionalInfo?: Maybe<AdditionalInformationPopover>;
  additionalInformation?: Maybe<PricePresentationAdditionalInformation>;
  icon?: Maybe<Icon>;
  primary: Scalars['String']['output'];
  size: Scalars['String']['output'];
  tag: Scalars['String']['output'];
}

/** Represents a line item that is text */
export interface PriceLineText {
  __typename: 'PriceLineText';
  /** @deprecated Use `additionalInformation` instead */
  additionalInfo?: Maybe<AdditionalInformationPopover>;
  additionalInformation?: Maybe<PricePresentationAdditionalInformation>;
  /** Represents visual assets Icons, Illustrations, and Marks which can be used interchangeably */
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use `graphic` instead. */
  icon?: Maybe<Icon>;
  primary: Scalars['String']['output'];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
}

/** Interface for displaying additional information containers */
export interface PricePresentationAdditionalInformation {
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
}

/** The dialog used to display additional information */
export interface PricePresentationAdditionalInformationDialog extends PricePresentationAdditionalInformation {
  __typename: 'PricePresentationAdditionalInformationDialog';
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  footer?: Maybe<EgdsDialogFooter>;
  icon?: Maybe<Icon>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
}

/** The popover (tooltip) used to display additional information */
export interface PricePresentationAdditionalInformationPopover extends PricePresentationAdditionalInformation {
  __typename: 'PricePresentationAdditionalInformationPopover';
  analytics?: Maybe<ClientSideAnalytics>;
  closeLabel?: Maybe<Scalars['String']['output']>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** An individual line item consisting of a name and value representing something like a fee's name and amount */
export interface PricePresentationLineItem {
  __typename: 'PricePresentationLineItem';
  /** Hidden text for screen reader accessibility where name and enrichedValue are shown in the same line */
  accessibilityLabel?: Maybe<Scalars['String']['output']>;
  enrichedValue?: Maybe<PricePresentationLineItemEntry>;
  name: PricePresentationLineItemEntry;
  /** @deprecated Use enrichedValue of type PricePresentationLineItemEntry */
  value?: Maybe<PriceLineText>;
}

/** The entry of a line item. This is typically used to describe what the line item is such as the fee name */
export interface PricePresentationLineItemEntry {
  __typename: 'PricePresentationLineItemEntry';
  primaryMessage: PricePresentationLineItemMessage;
  secondaryMessages: Array<PricePresentationLineItemMessage>;
}

/** interface to represent the various line item messages. There can be of various types such as text or a badge */
export type PricePresentationLineItemMessage = InlinePriceLineText | PriceLineBadge | PriceLineHeading | PriceLineText;

/** A subsection of the price summary. This is for smaller groupings within a section */
export interface PricePresentationSubSection {
  __typename: 'PricePresentationSubSection';
  header?: Maybe<PricePresentationLineItem>;
  items: Array<PricePresentationLineItem>;
}

/** Pricing Response */
export interface PricingResponse {
  __typename: 'PricingResponse';
  /** Base bid amount */
  baseBidAmount?: Maybe<BidAmountResponse>;
  /** Bidding strategy */
  biddingStrategy?: Maybe<Scalars['String']['output']>;
  /** Max bid amount */
  maxBidAmount?: Maybe<BidAmountResponse>;
}

/** Privacy tracking user preference. We can retrieve info on if the site allows tracking from Site Info and null can also be used to represent a selection or not */
export enum PrivacyTrackingState {
  CAN_NOT_TRACK = 'CAN_NOT_TRACK',
  CAN_TRACK = 'CAN_TRACK'
}

export enum ProductContext {
  BRAND_RESULT_LISTING = 'BRAND_RESULT_LISTING',
  FLIGHT_SPONSORED_LISTING = 'FLIGHT_SPONSORED_LISTING',
  LODGING_SPONSORED_LISTING = 'LODGING_SPONSORED_LISTING'
}

/** Property Image data for content apis */
export interface PropertyImageContent {
  __typename: 'PropertyImageContent';
  /** Property image id */
  id: Scalars['String']['output'];
  /** Property image details */
  imageInfo: Image;
}

/** Go to Travelads section */
export interface PropertyImagesGraphQlResponse {
  __typename: 'PropertyImagesGraphQLResponse';
  /** Images for a property */
  propertyImages: Array<PropertyImageContent>;
}

export interface Query {
  __typename: 'Query';
  _service: _Service;
  adGroupTypeaheadForm?: Maybe<AdsPortalAdGroupFocusTypeahead>;
  adGroupsTable?: Maybe<AdsPortalAdGroupTableResponse>;
  adTable?: Maybe<AdsPortalAdTableResponse>;
  adsPortalTable?: Maybe<CampaignOverviewTableResponse>;
  adsPortalTypeaheadForm?: Maybe<CampaignOverviewFocusTypeahead>;
  advertiserOpportunities?: Maybe<AdvertiserOpportunities>;
  /** Query to return onboarding wizard */
  advertiserPortalOnboarding: AdvertiserPortalOnboarding;
  /** Retrieve Product by logged in user Id or partner account Id */
  advertiserPortalProductSelection: AdvertiserPortalProductSelectionCard;
  /** Experience to power the Advertiser Reporting Criteria Options */
  advertiserReportingForm: AdvertiserReportingForm;
  advertiserReportingTable: AdvertiserReportingTableResponse;
  campaignTables?: Maybe<AdsPortalCampaignTableResponse>;
  campaignTypeaheadForm?: Maybe<AdsPortalCampaignFocusTypeahead>;
  /** Campaign wizard */
  campaignWizard: TopLevelCampaignWizard;
  /** Query to return list of campaigns */
  getAllCampaigns: Array<Campaign>;
  getMarketGoalLabel: Scalars['String']['output'];
  /** Experience to grab property images from EPC */
  getPropertyImages: PropertyImagesGraphQlResponse;
  /** Basic query that returns values from graphQLContext and passed in context */
  helloWithGraphQLContextexperienceapiadvertiser: Scalars['String']['output'];
  /** Basic query that returns string */
  helloexperienceapiadvertiser: Scalars['String']['output'];
  performanceOverviewCampaignSelection: PerformanceOverviewCampaignSelectionResponse;
  performanceOverviewCarousel: PerformanceOverviewCarouselResponse;
  performanceOverviewChart: PerformanceOverviewChartResponse;
  /** Experience to power the EPC to Travelads portal section */
  traveladsPageBody: TraveladsBody;
  /** Experience to power the benefits of using Travelads section */
  traveladsPageHeader: TraveladsHeader;
}


export interface QueryAdGroupTypeaheadFormArgs {
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  productContext: ProductContext;
}


export interface QueryAdGroupsTableArgs {
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
}


export interface QueryAdTableArgs {
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
}


export interface QueryAdsPortalTableArgs {
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
  tableInput: TableInput;
}


export interface QueryAdsPortalTypeaheadFormArgs {
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  formInput: FormInput;
  productContext: ProductContext;
}


export interface QueryAdvertiserOpportunitiesArgs {
  pageId: Scalars['String']['input'];
}


export interface QueryAdvertiserPortalProductSelectionArgs {
  partnerAccountId?: InputMaybe<Scalars['String']['input']>;
  productContext: ProductContext;
}


export interface QueryAdvertiserReportingFormArgs {
  reportingContext: AdvertiserReportingContext;
}


export interface QueryAdvertiserReportingTableArgs {
  reportingContext: AdvertiserReportingContext;
  reportingOptions: AdvertiserReportingOptionsInput;
}


export interface QueryCampaignTablesArgs {
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
}


export interface QueryCampaignTypeaheadFormArgs {
  productContext: ProductContext;
}


export interface QueryGetMarketGoalLabelArgs {
  value: Scalars['String']['input'];
}


export interface QueryGetPropertyImagesArgs {
  context: ContextInput;
  propertyId: Scalars['Int']['input'];
}


export interface QueryHelloWithGraphQlContextexperienceapiadvertiserArgs {
  context: ContextInput;
}


export interface QueryPerformanceOverviewCarouselArgs {
  campaignId: Scalars['String']['input'];
  performanceDateRange: PerformanceDateRangeInput;
}


export interface QueryPerformanceOverviewChartArgs {
  campaignId: Scalars['String']['input'];
  metric: Scalars['String']['input'];
  performanceDateRange: PerformanceDateRangeInput;
}


export interface QueryTraveladsPageBodyArgs {
  context: ContextInput;
  propertyId: Scalars['Int']['input'];
}


export interface QueryTraveladsPageHeaderArgs {
  context: ContextInput;
  propertyId: Scalars['Int']['input'];
}

/** Type used during a product search to specify a given numeric range like the price of a hotel should be between 100 and 200 (USD). */
export interface RangeValue {
  __typename: 'RangeValue';
  id: Scalars['String']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
}

/** Reporting criteria menu with typeahead search */
export interface ReportFilterMenu {
  __typename: 'ReportFilterMenu';
  /** Icon to close menu */
  closeIcon: Icon;
  /** Pill default suffix */
  defaultText?: Maybe<Scalars['String']['output']>;
  /** Button to close menu */
  doneButton: UiPrimaryButton;
  /** Menu trigger pill */
  pill: EgdsBasicPill;
  /** Criteria search typeahead */
  searchTypeahead: EgdsTypeaheadMultiSelect;
  /**
   * Data to populate typeahead
   * @deprecated This field is no longer used, and typeAheadSelectionOptions should be used instead
   */
  typeAheadData: Array<Scalars['String']['output']>;
  /** Data to populate typeahead */
  typeAheadSelectionOptions: Array<ReportSelectionOptions>;
}

/** Menu with Pill trigger */
export interface ReportPillMenu {
  __typename: 'ReportPillMenu';
  /** Pill default suffix */
  defaultText?: Maybe<Scalars['String']['output']>;
  /** Menu with criteria options */
  menu: EgdsTextMenu;
  /** Pill to trigger menu */
  pill: EgdsBasicPill;
}

/** Menu with Select trigger */
export interface ReportSelectMenu {
  __typename: 'ReportSelectMenu';
  /** Select default placeholder */
  defaultText?: Maybe<Scalars['String']['output']>;
  /** id for menu */
  id: Scalars['String']['output'];
  /** Select to trigger menu */
  select: EgdsBasicSelect;
}

/** Report select options */
export interface ReportSelectionOptions {
  __typename: 'ReportSelectionOptions';
  /** ad group id for selection */
  adgroupId?: Maybe<Scalars['String']['output']>;
  /** ad group id for selection */
  adgroupName?: Maybe<Scalars['String']['output']>;
  /** campaign id for selection */
  campaignId?: Maybe<Scalars['String']['output']>;
  /** campaign id for selection */
  campaignName?: Maybe<Scalars['String']['output']>;
  /** main id for selection */
  id?: Maybe<Scalars['String']['output']>;
  /** name of selection */
  name?: Maybe<Scalars['String']['output']>;
}

/** List of reporting criteria filters */
export type ReportingFilters = ReportFilterMenu | ReportPillMenu | ReportSelectMenu;

/** Section for various reporting criteria filters */
export interface ReportingOptionFilters {
  __typename: 'ReportingOptionFilters';
  /** Clear all button */
  clearAllButton: EgdsBasicPill;
  /** Filter text */
  filterText?: Maybe<Scalars['String']['output']>;
  filters: Array<ReportingFilters>;
}

export type ReviewCardItem = ReviewCardListItem | ReviewCardTextItem;

/** Review card line item */
export interface ReviewCardLineItem {
  __typename: 'ReviewCardLineItem';
  title: Scalars['String']['output'];
  value?: Maybe<ReviewCardItem>;
}

/** Review Card List item */
export interface ReviewCardListItem {
  __typename: 'ReviewCardListItem';
  /** List item values */
  values: Array<Scalars['String']['output']>;
}

/** Review card text content */
export interface ReviewCardTextItem {
  __typename: 'ReviewCardTextItem';
  /** Text content value */
  value: Scalars['String']['output'];
}

/** Review item line item containing heading and value */
export interface ReviewLineItem {
  __typename: 'ReviewLineItem';
  /** Review item heading */
  heading: Scalars['String']['output'];
  /** Review item value */
  value: Scalars['String']['output'];
}

export interface Route extends AdvertiserPortalProduct {
  __typename: 'Route';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

/** Support for `sms` based URI constructed with the body of the message to be send. */
export interface SmsToUri extends Uri {
  __typename: 'SMSToURI';
  body: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** Used to show error message when a DatePicker input is not valid */
export interface ScheduledDateRangeValidation extends EgdsInputValidation {
  __typename: 'ScheduledDateRangeValidation';
  errorMessage: Scalars['String']['output'];
}

/**
 *
 *     Range field on search form or search form widget for entering an event duration.
 *
 *     Figma: https://www.figma.com/file/eiXu9ARV67TsK58GPfKdku/Cruise-search-(CSR)?node-id=2486%3A154892
 *
 */
export interface SearchFormDurationSpanField extends EgdsElement, EgdsInputField, EgdsSearchFormField {
  __typename: 'SearchFormDurationSpanField';
  /**
   *
   *         Used for screen readers to inform about the current value.
   *         Example: based on a display value "3 - 9 nights", read "3 to 9 nights".
   *
   */
  accessibilityTemplate?: Maybe<EgdsLocalizedText>;
  /** Informs behavior in response to user click. */
  action?: Maybe<EgdsSearchFormAction>;
  /**
   *
   *         Element unique identifier. Uses include handling diffs for UI updates
   *         and identifying the source of data for user input fields.
   *
   */
  egdsElementId: Scalars['String']['output'];
  /** Text to be displayed as error text when the input field is in an invalid state. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Provides detailed instructions to the user on how to use the input element. */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Tag description for the input element. */
  label?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         Used to dynamically update the label text in the client, based on user interaction with the input.
   *
   */
  labelTemplate?: Maybe<EgdsLocalizedText>;
  /** Icon positioned to the left side of the input element. */
  leftIcon?: Maybe<Icon>;
  /** Description of the expected value for the input element. */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Field is in a read-only state displaying only text when readOnly is true. */
  readOnly: Scalars['Boolean']['output'];
  /** Informs the user if the field is mandatory. */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** Icon positioned to the right side of the input element. */
  rightIcon?: Maybe<Icon>;
  /**
   *
   *         Validations with corresponding error messages to display if the input is invalid.
   *         The order in which the validations appears indicates the order of priority of which error message to display.
   *
   */
  validations?: Maybe<Array<EgdsInputValidation>>;
  /** Default value for the input element. */
  value?: Maybe<Scalars['String']['output']>;
}

/** Type used during a product search to specify a given selection like the airline the customer would like to fly with. */
export interface SelectedValue {
  __typename: 'SelectedValue';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** Support for creating self referenced links by prefixing fragments with (#) */
export interface SelfReferencedUri extends Uri {
  __typename: 'SelfReferencedURI';
  fragment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
}

export enum SharedUiCardMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  READ = 'READ'
}

export enum SharedUiCardType {
  PARTNER_ACCOUNT = 'PARTNER_ACCOUNT',
  PARTNER_SELECT = 'PARTNER_SELECT',
  PAYMENT_PROFILE = 'PAYMENT_PROFILE'
}

/** Key/Value elements that describes the search criteria often displayed to the customer as filters */
export interface ShoppingSearchCriteria {
  __typename: 'ShoppingSearchCriteria';
  booleans?: Maybe<Array<BooleanValue>>;
  counts?: Maybe<Array<NumberValue>>;
  dates?: Maybe<Array<DateValue>>;
  ranges?: Maybe<Array<RangeValue>>;
  selections?: Maybe<Array<SelectedValue>>;
}

/** Used to describe an action on Sort and Filter Modal. */
export interface ShoppingSortAndFilterAction extends UiAction {
  __typename: 'ShoppingSortAndFilterAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  actionType: ShoppingSortAndFilterActionType;
  analytics: ClientSideAnalytics;
}

/** Valid actions on Sort and Filter Modal */
export enum ShoppingSortAndFilterActionType {
  /** Stay on the Sort & Filter Modal but set to default state. Eg used for clear button */
  CLEAR_DATA = 'CLEAR_DATA',
  /** Close Sort & Filter Modal and apply user selected filters. Eg used for Apply/Done buttons */
  CLOSE_AND_APPLY = 'CLOSE_AND_APPLY',
  /** Close Sort & Filter Modal. Eg used for Back/Cross buttons */
  CLOSE_AND_DO_NOT_APPLY = 'CLOSE_AND_DO_NOT_APPLY',
  /**
   *
   *     Open the modal window associated with the component on which action has been performed.
   *     Example: on click of Sort & Filter reveal button it should open Sort & Filter Modal and
   *            on click of Input Field it should open Typeahead Modal
   *
   */
  OPEN_MODAL = 'OPEN_MODAL'
}

/** Campaign Wizard Sidebar */
export interface SideBarActionArea {
  __typename: 'SideBarActionArea';
  /** Action area icon */
  icon: Icon;
  /** Action area option items */
  optionItems?: Maybe<Array<UiSecondaryButton>>;
  /** Action option buttons */
  optionsButton?: Maybe<UiSecondaryButton>;
  /** This action area step */
  step: WizardStep;
  /** Action area title */
  title?: Maybe<Scalars['String']['output']>;
}

/** Side Bar initial ad structure */
export interface SideBarAd {
  __typename: 'SideBarAd';
  /** Can override ad image */
  canOverrideImage: Scalars['Boolean']['output'];
  /** Content image can be overridden */
  content: SideBarContent;
  /** Ad id */
  id: Scalars['String']['output'];
  /** Ad status */
  isPaused: Scalars['Boolean']['output'];
  /** Ad name */
  name: Scalars['String']['output'];
}

/** Side Bar initial ad group structure */
export interface SideBarAdGroup {
  __typename: 'SideBarAdGroup';
  /** Ad group ads */
  ads: Array<SideBarAd>;
  /** Ad group id */
  id: Scalars['String']['output'];
  /** Ad group status */
  isPaused: Scalars['Boolean']['output'];
  /** Ad group locations to target */
  locations: Array<Scalars['String']['output']>;
  /** Ad group name */
  name: Scalars['String']['output'];
}

/** Side Bar initial campaign structure */
export interface SideBarCampaign {
  __typename: 'SideBarCampaign';
  /** Campaign adGroups */
  adGroups: Array<SideBarAdGroup>;
  /** Campaign budget */
  budgetAmount: Scalars['String']['output'];
  /** Campaign end date */
  endDate?: Maybe<Scalars['String']['output']>;
  /** Campaign goal */
  goalId?: Maybe<MarketGoal>;
  /** Campaign id */
  id?: Maybe<Scalars['String']['output']>;
  /** Campaign status */
  isPaused: Scalars['Boolean']['output'];
  /** Campaign name */
  name?: Maybe<Scalars['String']['output']>;
  /** Product id */
  productId: Scalars['String']['output'];
  /** Product name */
  productName: Scalars['String']['output'];
  /** Campaign start date */
  startDate: Scalars['String']['output'];
}

export interface SideBarContent {
  __typename: 'SideBarContent';
  adType: ProductContext;
  creative?: Maybe<SideBarCreative>;
  locale: Scalars['String']['output'];
}

/** The thing */
export type SideBarCreative = SideBarFlightCreative | SideBarLodgingCreative;

/** Side Bar flight creative */
export interface SideBarFlightCreative {
  __typename: 'SideBarFlightCreative';
  /** Creative description */
  description: Scalars['String']['output'];
  /** Creative headline */
  headline: Scalars['String']['output'];
  /** Creative image URL */
  imageUrl?: Maybe<Scalars['String']['output']>;
}

/** Side Bar lodging creative */
export interface SideBarLodgingCreative {
  __typename: 'SideBarLodgingCreative';
  /** Creative description */
  description: Scalars['String']['output'];
  /** Creative headline */
  headline: Scalars['String']['output'];
  /** Creative image ID */
  imageId?: Maybe<Scalars['String']['output']>;
  /** Creative image URL */
  imageUrl?: Maybe<Scalars['String']['output']>;
}

export enum TableInput {
  AD = 'AD',
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN = 'CAMPAIGN'
}

/** Targeting Inputs */
export interface TargetingInput {
  /** Targeting id */
  targetingId?: InputMaybe<Scalars['UUID']['input']>;
  /** Targeting value */
  targetingValue: Scalars['String']['input'];
}

/** Support for `tel` based URI constructed based on the phone number. */
export interface TelUri extends Uri {
  __typename: 'TelURI';
  phoneNumber: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/**
 *
 *     Represents the dynamic part of the localized text.  The `key` value indicates where to insert the variable data.
 *     The `source` is an ID for locating the actual data that will be inserted.
 *
 */
export interface TemplateModel {
  __typename: 'TemplateModel';
  key: Scalars['String']['output'];
  source: Scalars['String']['output'];
}

/** Used to highlight or convey information. For example, positive messaging like free cancellation may be green. */
export enum TextTheme {
  EMPHASIS = 'EMPHASIS',
  INVERSE = 'INVERSE',
  LOYALTY = 'LOYALTY',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

/** Used to highlight or convey information. For example, Payable amount in the price summary module of the checkout page. */
export enum TextWeight {
  BOLD = 'BOLD',
  MEDIUM = 'MEDIUM',
  REGULAR = 'REGULAR'
}

export interface TopLevelCampaignWizard {
  __typename: 'TopLevelCampaignWizard';
  /** Wizard navigation actions and buttons */
  wizardNavigator: CampaignWizardNavigation;
  /** Wizard side nav bar with campaign data */
  wizardSideBar: CampaignWizardSideBar;
  /** Wizard step which can include Campaign, Ad Group, Ad, Review */
  wizardStep: CampaignWizardStep;
}


export interface TopLevelCampaignWizardWizardSideBarArgs {
  campaignInput?: InputMaybe<CampaignInput>;
  productContext: ProductContext;
  productId?: InputMaybe<Scalars['String']['input']>;
}


export interface TopLevelCampaignWizardWizardStepArgs {
  campaignInput?: InputMaybe<CampaignInput>;
  mode?: InputMaybe<WizardMode>;
  productContext: ProductContext;
  selectedProductId?: InputMaybe<Scalars['String']['input']>;
  wizardStep: WizardStep;
}

/** Benefits of Travelads section */
export interface TraveladsBenefits {
  __typename: 'TraveladsBenefits';
  /** Booking metrics card */
  bookingMetric: TraveladsMetric;
  /** Benefits description */
  cardBody: Scalars['String']['output'];
  /** Benefits header */
  cardTitle: Scalars['String']['output'];
  /** Visibility metrics card */
  visibilityMetric: TraveladsMetric;
}

/** Main Experience containing all potential sections */
export interface TraveladsBody {
  __typename: 'TraveladsBody';
  /** Benefits Section */
  benefitsSection?: Maybe<TraveladsBenefits>;
  /** Featured Campaigns Section */
  featuredCampaignsSection?: Maybe<FeaturedCustomerCampaigns>;
  /** Benefits Footnote */
  footnoteText?: Maybe<Scalars['String']['output']>;
  /** Travelads Redirect Section */
  traveladsSection: GoToTravelAds;
}

/** Go to Travelads bullet item */
export interface TraveladsBullet {
  __typename: 'TraveladsBullet';
  /** Icon */
  icon?: Maybe<Icon>;
  /** Travelads bullet text */
  text: EgdsSpannableText;
}

/** Header Experience */
export interface TraveladsHeader {
  __typename: 'TraveladsHeader';
  /** Header body */
  body: Scalars['String']['output'];
  /** Current page */
  currentPageText: Scalars['String']['output'];
  /** Previous page */
  previousPageText: Scalars['String']['output'];
  /** Previous page URL */
  previousPageUrl: Scalars['URL']['output'];
  /** header title */
  title: Scalars['String']['output'];
}

/** Travelads Metric data */
export interface TraveladsMetric {
  __typename: 'TraveladsMetric';
  /** Metric Percentage */
  percentage: Scalars['String']['output'];
  /** Metric Text */
  text: Scalars['String']['output'];
}

/** Type with icon */
export interface TypeWithIcon {
  __typename: 'TypeWithIcon';
  icon: Icon;
  type: Scalars['String']['output'];
}

/**
 *
 *     Used to setup UITK Typeahead component (which internally uses Expedia Suggest Service API)
 *     enables search resolution functionalities
 *
 *     More details on UITK Typeahead:
 *     https://uitk.expedia.biz/expedia/en_US/components/typeahead
 *
 */
export interface TypeaheadInfo {
  __typename: 'TypeaheadInfo';
  /** Enables exact search term matching */
  allowExactMatch: Scalars['Boolean']['output'];
  /** Flag used to categorize the typeahead results */
  categorize: Scalars['Boolean']['output'];
  /** An agreed upon name between Expedia Suggest Service and its partners */
  client?: Maybe<Scalars['String']['output']>;
  /** Text for dismiss button text */
  dismissButtonText?: Maybe<Scalars['String']['output']>;
  /** Specify the domain to use for the requests, ex `www.expedia.com` */
  domain?: Maybe<Scalars['String']['output']>;
  /** When set to true, nearby results will be presented when the user focuses the destination input without typing anything */
  drivable: Scalars['Boolean']['output'];
  /** Placeholder for using when no results have been gather, commonly displayed when user hasn't performed a search before */
  emptyResultsPlaceholder?: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for a user. This is required by the Typeahead API in order to retrieve the recent searches for a given user
   * @deprecated This will be removed when typeahead service will start using principal token to pull expUserId
   */
  expUserId?: Maybe<Scalars['String']['output']>;
  /**
   *
   *         Specifies whether query term is a destination (as opposed to an origin).
   *         ESS needs this information for Flights/Packages/Cars.
   *
   */
  isDestination: Scalars['Boolean']['output'];
  /**
   * Specifies is multiple locations selection feature is enable
   * @deprecated Use typeaheadMultiSelect.isMultiLocation
   */
  isMultiLocation?: Maybe<Scalars['Boolean']['output']>;
  /** One of the Expedia Group line of business: Hotels, Flights, Cars, Packages... */
  lineOfBusiness?: Maybe<Scalars['String']['output']>;
  /** When this flag is set, the heading for the categorized results is shown */
  listTitle: Scalars['Boolean']['output'];
  /** Specifies the maximum number of results to be returned in ESS response */
  maxNumberOfResults?: Maybe<Scalars['Int']['output']>;
  /** Package type, this parameter accompanies Packages line of business */
  packageType?: Maybe<Scalars['String']['output']>;
  /** When set to true, user history is considered while compiling results */
  personalize: Scalars['Boolean']['output'];
  /** Used to fetch results from within the specified region id */
  regionId?: Maybe<Scalars['String']['output']>;
  /** Region category, eg: Airport, City, Multi-city... */
  regionType?: Maybe<Scalars['Int']['output']>;
  /** When set to true, the current location option will be displayed when Typeahead is open */
  showCurrentLocation?: Maybe<Scalars['Boolean']['output']>;
  /** Sub line of business within EG line of business, ex: roundtrip, oneway & multicity for flights lob */
  subLob?: Maybe<Scalars['String']['output']>;
  /** When set to true, trending results will be presented when the user focuses the destination input without typing anything */
  trending: Scalars['Boolean']['output'];
  /** Features enabled, such as ta_hierarchy enables that typeahead shows the hierarchy */
  typeaheadFeatures?: Maybe<Scalars['String']['output']>;
  /** Property to use for multi select option */
  typeaheadMultiSelect?: Maybe<EgdsTypeaheadMultiSelect>;
}

/** Typeahead trigger menu */
export interface TypeaheadTriggerMenu {
  __typename: 'TypeaheadTriggerMenu';
  /** Add icon */
  addIcon: Icon;
  /** Typeahead trigger menu */
  title: Scalars['String']['output'];
  types: Array<TypeWithIcon>;
}

/**
 *
 * Do not use this interface
 * Check the documentation for more information about why you should not use this.
 * https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/interface-and-union#best-practices-for-interface-and-union
 *
 * An action is a generic container to information the client side application what can be done with a given card.
 * Sometimes the action will be could be associated with a deep link, opening a specific view or leveraging some protocols like e-mails or phones.
 * Every action must be trackable for analytics purpose based on the `analytics` information.
 *
 */
export interface UiAction {
  /**
   *
   *       Used to provide accessibility that has to be read on completion of action.
   *
   */
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
}

/** Banners are used to notify users of an urgent or important condition that may affect their trip or booking experience. */
export interface UiBanner extends EgdsElement {
  __typename: 'UIBanner';
  actions: Array<UiLinkAction>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  fullWidth?: Maybe<Scalars['Boolean']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  message: Scalars['String']['output'];
  theme?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Empty state elements are provided as separate pieces that combine to form different variations and can be assembled in a variety of different ways depending on need.
 *     Empty states can be used between modules on a page or be placed alone in a full screen view.
 *     Figma: https://www.figma.com/file/0OuX8BMSFmFdWYXfdCEjrj/Empty-state
 *
 */
export interface UiEmptyState extends EgdsElement {
  __typename: 'UIEmptyState';
  body: Scalars['String']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  primaryButton?: Maybe<UiPrimaryButton>;
  tertiaryButton?: Maybe<UiTertiaryButton>;
}

/** Use this button for a floating icon style. */
export interface UiFloatingActionButton {
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  /** Used to capture client side analytics on button click. */
  analytics?: Maybe<ClientSideAnalytics>;
  /** Use badge to notify a count on button. */
  badge?: Maybe<Scalars['Int']['output']>;
  disabled: Scalars['Boolean']['output'];
  icon?: Maybe<Icon>;
  /** Used to capture client side impression analytics. */
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** Use primary to show text beside icon or badge. */
  primary?: Maybe<Scalars['String']['output']>;
}

/**
 *
 * In many cases, UX designs support Icons, Illustrations, and Marks interchangeably.
 * Their fields are slightly different and their UI implementations are also different.
 * Graphic is intended to help group those possible types together without having to add nullable fields for them individually.
 *
 */
export type UiGraphic = Icon | Illustration | Mark;

/**
 *
 * Used to describe a URI based link. A URI can expose several protocols like `geo` for geographical information, `tel` to let the customer's phone application taking over or even `mailto`.
 *
 */
export interface UiLinkAction extends UiAction {
  __typename: 'UILinkAction';
  accessibility?: Maybe<Scalars['String']['output']>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: UiLinkTarget;
  /** Flag to identify if relative path to be rendered or whole url */
  useRelativePath: Scalars['Boolean']['output'];
}

/** Specifies whether to open the link in the same user interface context or in a different context */
export enum UiLinkTarget {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL'
}

/**
 *
 *     A specific type of card used for product messaging or other contextual information highlighted to the user.
 *     This is often used for things like product availability, user sign-in status, earning brand points, or other promotional/informative messages.
 *     Figma: https://www.figma.com/file/V9juOKAE4bgugBqxRiPBjJ/Messaging-cards
 *
 */
export interface UiMessagingCard extends EgdsElement {
  __typename: 'UIMessagingCard';
  actions?: Maybe<UiMessagingCardActions>;
  egdsElementId?: Maybe<Scalars['String']['output']>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars['String']['output']>;
  secondaries?: Maybe<Array<Scalars['String']['output']>>;
}

/**
 *
 *     There are times when a Messaging Card can provide some actionable options to the user.
 *     For visual consistency, the targets/triggers for those actions should be limited to Button types.
 *     Common examples of Messaging Card Actions could be "Sign-in", "Show me FooBar properties", "Learn more".
 *
 */
export interface UiMessagingCardActions {
  __typename: 'UIMessagingCardActions';
  primary?: Maybe<UiTertiaryButton>;
  secondaries?: Maybe<Array<UiTertiaryButton>>;
}

/** Use this button to make the primary call to action. Do not use it for different calls to action in the same view. */
export interface UiPrimaryButton extends EgdsButton, EgdsElement {
  __typename: 'UIPrimaryButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** Use this button to make the primary call to action and for a floating button style. */
export interface UiPrimaryFloatingActionButton extends EgdsElement, UiFloatingActionButton {
  __typename: 'UIPrimaryFloatingActionButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  badge?: Maybe<Scalars['Int']['output']>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** Use this button to let users take an alternative, supplemental, or less important action. */
export interface UiSecondaryButton extends EgdsButton, EgdsElement {
  __typename: 'UISecondaryButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** Use this button to let users take an alternative, supplemental, or less important action and for a floating icon style. */
export interface UiSecondaryFloatingActionButton extends EgdsElement, UiFloatingActionButton {
  __typename: 'UISecondaryFloatingActionButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  badge?: Maybe<Scalars['Int']['output']>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  primary?: Maybe<Scalars['String']['output']>;
}

/** Use this button for the least amount of prominence. */
export interface UiTertiaryButton extends EgdsButton, EgdsElement {
  __typename: 'UITertiaryButton';
  accessibility?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars['Boolean']['output'];
  egdsElementId?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars['String']['output']>;
}

/**
 *
 *     Use this component to let users switch/toggle between states
 *     Figma: https://www.figma.com/file/69SEMRa1KCBXU6T8uRcdzM/Switches?node-id=20%3A83
 *
 */
export interface UiToggle {
  checked: Scalars['Boolean']['output'];
  checkedAccessibilityLabel?: Maybe<Scalars['String']['output']>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars['String']['output']>;
  checkedLabel: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  /** @deprecated Use checked or unchecked label */
  label: Scalars['String']['output'];
  uncheckedAccessibilityLabel?: Maybe<Scalars['String']['output']>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars['String']['output']>;
  uncheckedLabel: Scalars['String']['output'];
}

/**
 * Generic representation of a Uniform Resource Indicator see [RFC](https://tools.ietf.org/html/rfc3986) for more information.
 * Example of use case:
 * - non http protocol: being able to expose a link like `tel` to let the mobile native integration taking over to call the hotelier.
 * - http protocol: being able to retrieve relative URL (path + query + fragment)
 *
 */
export interface Uri {
  value: Scalars['String']['output'];
}

export interface UpdateStatusResponse {
  __typename: 'UpdateStatusResponse';
  ad?: Maybe<AdCreationResponse>;
  adGroup?: Maybe<AdGroupCreationResponse>;
  campaign?: Maybe<CampaignCreationResponse>;
}

export interface Video {
  __typename: 'Video';
  /** The localized text describing the video */
  description: Scalars['String']['output'];
  /** Play or Pause analytics */
  playPauseAnalytics?: Maybe<ClientSideAnalytics>;
  /** Thumbnail of the video */
  thumbnail?: Maybe<Uri>;
  /** thumbnail video click analytics */
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Uri;
}

export enum WizardMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export enum WizardStep {
  AD = 'AD',
  AD_GROUP = 'AD_GROUP',
  CAMPAIGN = 'CAMPAIGN',
  REVIEW = 'REVIEW'
}

export interface _Service {
  __typename: '_Service';
  sdl: Scalars['String']['output'];
}

export type AdPreviewContent = LslAdPreviewContent;
