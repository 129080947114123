//@typescript-eslint/no-explicit-any
import React from 'react';

import { UitkButtonSize, UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkLink } from 'uitk-react-link';
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from 'uitk-react-sheet';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { UitkHeading, UitkSubheading, UitkText } from 'uitk-react-text';

interface UserPopoverProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visible: boolean;
  toggleVisibility: () => void;
  openDialog: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerRef: React.RefObject<any>;
}

export const UserPopoverSheet: React.FC<UserPopoverProps> = ({
  user,
  visible,
  toggleVisibility,
  openDialog,
  triggerRef,
}) => {
  return (
    <UitkLayoutFlex space="four">
      <UitkLayoutFlexItem className="top-nav-section">
        <UitkLayoutFlex justifyContent={'end'}>
          <UitkLayoutPosition type="relative">
            <UitkTertiaryButton inverse size={UitkButtonSize.MEDIUM} onClick={toggleVisibility} domRef={triggerRef}>
              <UitkIcon name="account_circle" className="top-nav-section" />
              {`${user?.name}`}
            </UitkTertiaryButton>
            <UitkSheetTransition isVisible={visible}>
              <UitkSheet
                position={{ inline: 'trailing' }}
                width={280}
                isVisible={visible}
                type="popover"
                triggerRef={triggerRef}
                onDismiss={toggleVisibility}
              >
                <UitkSheetContent>
                  <UitkText spacing="two" weight={'bold'} size={400} className="initials-style">
                    {`${user?.initials}`}
                  </UitkText>
                  <UitkText spacing="two" weight={'medium'} size={400} align={'center'}>
                    {`Hi, ${user?.name}!`}
                  </UitkText>
                  <UitkText spacing="two" align={'center'}>
                    {`${user?.primaryEmail}`}
                  </UitkText>
                  <UitkSecondaryButton
                    size={UitkButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '20px' }}
                  >
                    Manage your user profile
                  </UitkSecondaryButton>
                  <UitkSpacingHr />
                  <UitkSubheading>Partner account</UitkSubheading>
                  <UitkLayoutFlex space="two" className={'partner-account-style'}>
                    <UitkLayoutFlexItem alignSelf={'center'}>
                      <UitkIcon name="account_circle" />
                    </UitkLayoutFlexItem>
                    <UitkLayoutFlexItem>
                      <UitkLayoutFlex style={{ display: 'block' }}>
                        <UitkLayoutFlexItem>
                          <UitkHeading tag="h6" size={7}>
                            {`${user?.partnerName}`}
                          </UitkHeading>
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem>
                          <UitkLink>
                            <a href="/view-and-manage" style={{ textDecoration: 'none' }}>
                              View and manage
                            </a>
                          </UitkLink>
                        </UitkLayoutFlexItem>
                      </UitkLayoutFlex>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                  <UitkSecondaryButton
                    size={UitkButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '2rem' }}
                    onClick={openDialog}
                    name="visibility"
                    domRef={triggerRef}
                  >
                    Switch Partner Account
                  </UitkSecondaryButton>
                  <UitkTertiaryButton
                    size={UitkButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '20px' }}
                  >
                    <a href="/" style={{ textDecoration: 'none' }}>
                      Sign out
                    </a>
                  </UitkTertiaryButton>
                </UitkSheetContent>
              </UitkSheet>
            </UitkSheetTransition>
          </UitkLayoutPosition>
        </UitkLayoutFlex>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
};
