import React, { useEffect } from 'react';

import useClickOutside from '@hooks/use-click-outside';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from 'uitk-react-sheet';

interface ISidePanelProps {
  collapse: boolean;
  content: React.ReactNode;
  marginTop?: string;
}

export const SidePanel: React.FC<ISidePanelProps> = (props) => {
  const { collapse, content, marginTop } = props;

  const [showSidePanel, setShowSidePanel] = React.useState(false);
  const sheetTriggerRef = React.useRef();
  const clickRef = React.useRef();

  useClickOutside(clickRef, () => {
    if (showSidePanel) {
      setShowSidePanel(false);
    }
  });

  useEffect(() => {
    if (!collapse) {
      setShowSidePanel(false);
    }
  }, [collapse]);

  return (
    <>
      {collapse && (
        <div ref={clickRef}>
          <UitkLayoutPosition type={'absolute'} style={{ bottom: 100, right: 25 }}>
            <div ref={sheetTriggerRef} className="pointer-cursor" onClick={() => setShowSidePanel((c) => !c)}>
              <UitkIcon name="error_outline" spotlight={'info'} id="insight-toggle-icon" />
            </div>
            <UitkSheetTransition isVisible={showSidePanel}>
              <UitkSheet
                position={{ inline: 'trailing', block: 'leading' }}
                isVisible={showSidePanel}
                liveAnnounceString="Popover Sheet has opened"
                type="popover"
                triggerRef={sheetTriggerRef}
                intersectionObserverRoot="insight-toggle-icon"
                applyBlockStartMargin={true}
                width={360}
              >
                <UitkSheetContent>{content}</UitkSheetContent>
              </UitkSheet>
            </UitkSheetTransition>
          </UitkLayoutPosition>
        </div>
      )}
      {!collapse && (
        <UitkLayoutGridItem style={{ maxWidth: 400, minWidth: 300 }}>
          <div style={{ padding: '1.5rem', paddingLeft: '0.25rem', marginTop: marginTop }}>{content}</div>
        </UitkLayoutGridItem>
      )}
    </>
  );
};
